<template>
  <div>
    <!-- 
      if total = 100, means there is no ticket yet

    -->
    <vc-donut
      background="hsla(0, 0%, 90%, 0.9)"
      foreground="grey"
      :size="150"
      unit="px"
      :thickness="30"
      :sections="sections"
      :total="total == 100 || total == 0 ? 1 : total"
      :start-angle="0"
      :auto-adjust-text-size="true"
      @section-click="handleSectionClick"
    >
      <p class="lead">
        {{ total == 100 ? 0 : total }}<br /><small>{{
          total == 100 || total <= 1 ? "Ticket" : "Tickets"
        }}</small>
      </p>
    </vc-donut>
    <br />
  </div>
</template>

<script>
export default {
  name: "SprintSection",

  data() {
    return {
      sections: [],
      total: 0,
    };
  },
  props: {
    sprintId: String,
  },
  methods: {
    handleSectionClick(section, event) {
      console.log(`${section.label} clicked.`);
    },
    async getSections() {
      const res = await this.$store.dispatch("sprints/getTicket", {
        sprintId: this.sprintId,
      });

      let _total = await res.reduce(
        (initial, current) => initial + current.value,
        0
      );

      this.total = _total <= 0 ? 100 : _total;

      return await res;
    },
  },
  async created() {
    this.sections = await this.getSections();
  },
};
</script>

<style>
</style>