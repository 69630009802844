<template>
  <div
    class="
      bg-light
      vh-100
      w-100
      d-flex
      flex-column
      align-items-center
      justify-content-center
    "
  >
    <h1>Oops!</h1>
    <h4>Page Not Found</h4>
    <p>
      It seem's that the page you are looking for is not found or you dont have
      enough permission to access it.
    </p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style></style>
