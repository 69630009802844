import { createApp, Vue } from "vue";
import App from './App.vue'
import router from './router'
import store from './store';
import Donut from 'vue-css-donut-chart';
import moment from "moment";
import 'vue-css-donut-chart/dist/vcdonut.css';
import Swal from "sweetalert2";
import { ProcessGuard } from "./helpers/ProcessGuard";



const taskboard = createApp(App);

taskboard.mixin({
    methods: {
        format_date: function (dateTime, format) {
            if (dateTime) {
                let _format = format ? format : "hh:mm A, MM/DD/YY";
                return moment(new Date(dateTime)).format(_format);
            }
        },
        create_board() {
            const defaultBoardName = this.$store.state.auth.currentUser.get("firstname") + "'s Board";

            const currentUser = this.$store.state.auth.currentUser;
            Swal.fire({
                backdrop: true,
                title: "Board Name",
                text: "Create a custom board and invite members later.",
                input: "text",
                inputValue:
                    currentUser != null
                        ? this.$store.state.board.boards.map(el => el.get('name')).indexOf(defaultBoardName) > -1 ? '' : defaultBoardName
                        : "My Board",
                inputAttributes: {
                    autocapitalize: "off",
                },
                showCancelButton: true,
                confirmButtonText: "Create",
                showLoaderOnConfirm: true,
                preConfirm: (board) => {
                    return this.$store.dispatch("board/addBoard", {
                        objectId: currentUser.id,
                        name: board,
                    });
                },
                allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire('Board Created!', '', 'success');

                }
            });
        },
    },
});

router.afterEach((to, from) => {
    // to and from are both route objects.

    setTimeout(() => {
        ProcessGuard();
    }, 300);
})

taskboard.use(store).use(Donut).use(router).mount('#app');
