<template>
  <div
    id="addMember"
    class="
      bg-light
      rounded-circle
      d-flex
      align-items-center
      justify-content-center
      adapt__theme_bg_dark
      text-light
    "
    type="button"
    data-bs-toggle="dropdown"
  >
    <span
      >+<small v-if="$store.state.board.members.length > 6">{{
        $store.state.board.members.length - 6
      }}</small></span
    >
  </div>

  <ul class="dropdown-menu p-0 m-0">
    <div class="card">
      <div class="card-header">
        <p class="fw-bold">Invite new member:</p>
        <input
          type="text"
          placeholder="Employee ID to invite"
          class="form-control"
          v-on:keyup.enter="invite"
          v-model="employeeId"
        />
      </div>
      <div class="card-body d-flex flex-column align-items-start p-1">
        <small class="p-2 fw-bold">Board members:</small>
        <div
          class="d-flex align-items-center dropdown-item ps-0"
          v-for="member in $store.state.board.members"
          :key="member.id"
        >
          <div class="member_avatar">
            <BoardMembersAvatar
              :userId="member.get('username')"
              :name="member.get('fullname')"
              :avatar="member.get('avatar')"
            />
          </div>
          <p class="ps-2 member_name">{{ member.get("fullname") }}</p>
        </div>
      </div>
    </div>
  </ul>
</template>

<script>
import BoardMembersAvatar from "./BoardMembersAvatar.vue";
import Swal from "sweetalert2";
export default {
  components: { BoardMembersAvatar },
  data() {
    return {
      employeeId: "",
    };
  },

  methods: {
    async invite() {
      if (
        this.$store.state.board.members
          .map((obj) => obj.get("username"))
          .indexOf(this.employeeId) > -1
      ) {
        Swal.fire("User already exists.", "", "warning");
        return;
      }

      Swal.fire({
        title: "Invite User",
        text: `You are about to invite employee id ${this.employeeId}. Continue?`,
        showCancelButton: true,
        confirmButtonText: "Invite",
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          return this.$store
            .dispatch("board/invite", {
              empId: this.employeeId,
              boardId: this.$store.state.board.active,
            })
            .then((response) => {
              if (response !== "success") {
                Swal.showValidationMessage(`Invite failed: ${response}`);
                return false;
              } else {
                Swal.fire("Invited!!!", "", "success");
              }
              return true;
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Invited!", "", "success");
        }
      });

      // Swal.fire({
      //   title: "Invite User",
      //   text: `You are about to invite employee id ${this.employeeId}. Continue?`,
      //   icon: "question",
      //   showCancelButton: true,
      //   confirmButtonText: "Yes, invite!",
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     this.$store.dispatch("board/invite", {
      //       empId: this.employeeId,
      //       boardId: this.$store.state.board.active,
      //     }).then((message)=>{
      //       if(message == "success"){
      //         Swal.fire("Invited!", "", "success");
      //       }else{
      //         Swal.fire("Failed!", message, "error");
      //       }
      //     });

      //     this.employeeId = "";
      //   }
      // });
    },
  },
};
</script>

<style scoped>
#addMember {
  width: 40px;
  height: 40px;
  margin-left: -10px;
  cursor: pointer;
}
.member_avatar {
  width: 33px;
  margin: 2px;
}
.member_name {
  font-size: 13px;
}
</style>