<template>
  <div class="box">
    <div class="row">
      <div class="col-lg-8 text-start">
        <h1 class="display-4">Taskboard helps teams move work forward.</h1>
        <p>
          Collaborate, manage projects, and reach new productivity peaks. From
          high rises to the home office, the way your team works is unique
          &mdash; accomplish it all with Taskboard.
        </p>
        <img src="theme/img/humaans-chars3.png" alt="" />
      </div>
      <div class="col-lg-4">
        <div class="card rounded bg-white shadow-lg border-0 p-5">
          <h3 class="fw-bold text-start pb-4">User Login</h3>
          <div class="alert alert-danger fw-bold" v-if="errorMessage != ''">
            {{ errorMessage }}
          </div>
          <form @submit="logIn">
            <div class="form-floating mb-3">
              <input
                type="number"
                class="form-control"
                id="floatingInput1"
                placeholder=" "
                v-model="id"
              />
              <label for="floatingInput1">Employee Number</label>
            </div>

            <div class="form-floating mb-3">
              <input
                class="form-control"
                id="floatingInput2"
                placeholder=" "
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
              />
              <label for="floatingInput2">Password</label>
            </div>

            <div class="form-check text-start pb-2">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                @change="showPassword = !showPassword"
              />
              <label class="form-check-label" for="flexCheckDefault">
                <small>Show Password</small>
              </label>
            </div>

            <button
              type="submit"
              style="width: 100%"
              class="btn btn-primary repadding fw-bold p-3"
            >
              <span v-if="isLoading"
                ><i class="fa fa-spin fa-spinner"></i
              ></span>
              <span v-else>LOGIN</span>
            </button>
          </form>
          <p class="text-muted text-start pt-3">
            Don't have an account?
            <router-link
              v-if="$route.params.id != null"
              :to="{ name: 'RegisterParam', params: { id: $route.params.id } }"
              >Create here!</router-link
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      id: this.$route.params.id
        ? Buffer.from(this.$route.params.id, "base64")
        : "",
      password: "",
      errorMessage: "",
      isLoading: false,
      showPassword: false,
    };
  },
  methods: {
    async logIn(e) {
      e.preventDefault();
      if (!this.id) {
        this.errorMessage = "Employee ID is required.";

        return;
      }
      if (!this.password) {
        this.errorMessage = "Password is required.";
        return;
      }
      this.isLoading = true;
      await this.$store.dispatch("auth/signIn", {
        userid: this.id.toString(),
        password: this.password,
      });

      this.errorMessage = this.$store.getters["auth/loginError"];

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
}
p a {
  text-decoration: none !important;
}
.left-content h1 {
  width: 100%;
}
.box div.row {
  width: 70vw;
  min-height: 100px;
  max-height: auto;
}
/** Custom Background */

div.__appTheme {
  background-color: #f5f4f8 !important;
}
</style>
