import router from '../router'
import store from '../store/'

export function AuthGuard() {
    // user is logged in
    if (store.getters['auth/isLoggedIn']) {
        // check if user already has an active board
        const activeBoard = store.state.auth.currentUser.get('activeBoard');
        console.log(`[Board: ${activeBoard}]`)

        store.commit('board/setActive', { boardId: activeBoard });

        if (["none", "", null].indexOf(activeBoard) > -1) {
            // user has no active board
            // let them choose
            console.log('no active board');
            router.push({ path: '/boards' })
            return false;
        }

        return true;
    }

    // user is not logged in
    router.push({ path: '/login' })
    return false;
}
