<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            <i class="bx bx-sm align-middle bx-message-edit"></i> {{ title }}
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <span class="p-3">{{ description }}</span>
        <div class="alert alert-danger" v-if="message.length > 0">
          {{ message }}
        </div>
        <div class="modal-body">
          <textarea
            name="story"
            v-model="story"
            cols="30"
            rows="10"
            :class="
              story.length >= 90
                ? 'form-control is-valid'
                : 'form-control is-invalid'
            "
            placeholder="Example: As an accounting clerk, I need a report for the processed daily collection receipts, so that I can easily monitor every collections that are coming in."
          ></textarea>
          <small class="text-muted" v-if="story.length <= 90">{{
            story.length + "/90"
          }}</small>
          <div class="row mt-2">
            <div class="col-lg-6">
              <label>Department <span class="text-danger"> *</span></label>
              <select v-model="selectedDepartment" class="form-select" required>
                <option
                  v-bind:value="department"
                  v-for="(department, index) in $store.state.story.departments"
                  :key="index"
                >
                  {{ department.toLowerCase().toUpperCase() }}
                </option>
              </select>
            </div>
            <div class="col">
              <label :for="selectedApprover"
                >Approver <span class="text-danger"> *</span></label
              >
              <select v-model="selectedApprover" class="form-select" required>
                <option
                  v-bind:value="approver"
                  v-for="approver in approvers"
                  :key="approver.EmpID"
                >
                  {{
                    approver.Fname.toLowerCase().toUpperCase() +
                    " " +
                    approver.Lname.toLowerCase().toUpperCase()
                  }}
                </option>
              </select>
            </div>
            <div class="row pt-1">
              <div class="col pt-2 text-end">
                <input
                  type="file"
                  @change="attachmentChange"
                  id="attachment"
                  class="btn btn-light d-none"
                />
                <button
                  class="btn btn-light"
                  onclick="javascript:document.querySelector('#attachment').click()"
                >
                  <i class="bx bx-link-alt"></i> Attachments ({{
                    attachments.length
                  }})
                </button>
              </div>
            </div>
          </div>
          <div class="row" v-if="attachments.length > 0">
            <div
              v-for="(attachment, index) in attachments"
              :key="attachment.name"
              :style="{
                'background-image':
                  ['image/jpeg', 'image/png'].indexOf(attachment.type) < 0
                    ? 'url(theme/img/file-icon.png)'
                    : 'url(' + attachment.tempURL + ')',
              }"
              class="previewAttachment"
            >
              <button
                @click="deleteImg(index)"
                class="btn btn-sm btn-danger mt-2 mr-2 rounded-circle"
              >
                <i class="bx bx-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            id="closeModal"
          >
            Cancel
          </button>
          <button
            @click="submitStory"
            type="button"
            :disabled="isLoading"
            class="btn text-light btn-primary"
          >
            <span v-if="isLoading">
              <i class="bx bx-loader-alt bx-spin"></i>
            </span>
            <span v-else> Submit Story </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END MODAL -->
</template>

<script>
export default {
  name: "NewStory",
  props: {
    title: String,
    description: String,
    currentEmpId: String,
  },
  data() {
    return {
      story: "",
      attachments: [],
      approvers: [],
      departments: [],
      uploadedAttachments: [],
      selectedApprover: null,
      selectedDepartment: null,
      isLoading: false,
      message: "",
    };
  },
  async created() {
    await this.getApprover();
    await this.getDepartments();

    console.log("dept: ", this.departments);
  },
  methods: {
    async getApprover() {
      this.isLoading = true;
      await this.$store.dispatch("story/userApprovers", {currentEmpId:this.$store.state.auth.currentUser.get('username')});
      this.approvers = this.$store.getters["story/userApprovers"];
      this.message = this.$store.getters["story/errorMessage"];
      this.isLoading = false;
    },

    async getDepartments() {
      this.isLoading = true;
      await this.$store.dispatch("story/getDepartments");

      this.message = this.$store.getters["story/errorMessage"];
      this.isLoading = false;
    },

    deleteImg(index) {
      this.attachments.splice(index, 1);
    },
    attachmentChange(e) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      file.tempURL = url;
      this.attachments.push(file);
      console.log(this.attachments);
    },
    async submitStory() {
      if (
        this.selectedApprover != null &&
        this.selectedDepartment != null &&
        this.story.length >= 90
      ) {
        this.message = "";
        // check if we have files to be uploaded
        if (this.attachments.length > 0) {
          this.isLoading = true;
          // process each file to upload
          for (let i = 0; i < this.attachments.length; i++) {
            const file = this.attachments[i];
            const name = file.name;

            const fileUrl = await this.$store.dispatch(
              "story/uploadAttachment",
              {
                name: name,
                file: file,
              }
            );

            // if successfuly uploaded, then push the url to `uploadedAttachments`
            if (fileUrl != "") {
              this.uploadedAttachments.push(fileUrl);
            }
            // Only save story when uploaded attachments == selected attachments
            if (this.uploadedAttachments.length == this.attachments.length) {
              this.saveStory();
            }
          }
        } else {
          this.saveStory();
        }
      } else {
        this.message = "All fields mark with * is required.";
      }
    },
    async saveStory() {
      this.isLoading = true;
      await this.$store.dispatch("story/createStory", {
        story: this.story,
        selectedApprover: this.selectedApprover,
        selectedDepartment: this.selectedDepartment,
        uploadedAttachments: this.uploadedAttachments,
      });
      this.isLoading = false;
      this.$store.dispatch("story/userStories");

      // reset story form
      this.story = "";
      this.uploadedAttachments = [];
      this.attachments = [];
      this.selectedApprover = null;
      // close modal
      document.querySelector("#closeModal").click();
    },
  },
};
</script>

<style>
div.previewAttachment {
  margin: 10px;
  width: 100px !important;
  height: 100px;
  background-position: center;
  background-size: cover;
}
</style>