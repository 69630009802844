import Parse, { ACL } from 'parse';
import auth from './auth';
import { TicketStatusLabel } from '../../helpers/TicketStatusLabel';

const story = {
    namespaced: true,
    state: {
        errorMessage: "",
        developers: [],
        allStories: [],
        userStories: [],
        userApprovers: [],
        storyTickets: [],
        departments: [],
        selectedStoryId: "",
        ticketToDrop: "",
    },
    mutations: {
        developers(state, payload) {
            state.developers = payload.devs;
        },
        allStories(state, payload) {
            state.allStories = payload.stories;
        },
        departments(state, payload) {
            state.departments = payload.departments;
        },
        userStories(state, payload) {
            state.userStories = payload.stories;
        },
        userApprovers(state, payload) {
            state.userApprovers = payload.approvers;
        },
        errorMessage(state, payload) {
            state.errorMessage = payload.message;
        },
        storyTickets(state, payload) {
            state.storyTickets = payload.data;
        },
        selectedStoryId(state, payload) {
            state.selectedStoryId = payload.storyId;
        },
        ticketToDrop(state, payload) {
            state.ticketToDrop = payload.value;
        }
    },
    actions: {
        delete: async ({ }, payload) => {
            const Story = Parse.Object.extend("Stories");
            const query = new Parse.Query(Story);

            return query.get(payload.id).then((result) => {
                return result.destroy()

            });
        },
        getDepartments: async ({ commit, state, rootState }) => {
            // clear erorrMessage
            commit('errorMessage', { message: "" });
            if (state.departments.length > 0) {
                return;
            }
            try {

                console.log('fetching depts');
                const Departments = Parse.Object.extend('Departments');
                const query = new Parse.Query(Departments);

                const result = await query.find();


                let data = [];


                if (result.length > 0) {
                    for (let i = 0; i < result.length; i++) {
                        data.push(result[i].get('name'));
                    }
                    commit('departments', { departments: data });
                }




            } catch (error) {
                commit('errorMessage', { message: error });
            } finally {
                if (state.departments.length <= 0) {
                    commit('errorMessage', { message: "An error occured while retrieving departments. Please refresh again." });

                }
            }
        },
        // Save Acceptance Criteria
        saveCriteria: async ({ commit }, payload) => {
            const Story = Parse.Object.extend("Stories")
            const query = new Parse.Query(Story)

            query.equalTo('objectId', payload.storyId)

            const result = await query.find();
            for (let i = 0; i < result.length; i++) {
                let criteria = [];

                if (result[i].get('criteria')) {
                    criteria = result[i].get('criteria')
                }
                criteria.push(payload.newCriteria)
                result[i].set('criteria', criteria)
                return await result[i].save()
            }
        },
        notifyOwner: async ({ dispatch, state, rootState }, payload) => {
            // Get story details

            await dispatch("allStories");
            const story = state.allStories.filter(
                (element) => element.id == payload.storyId
            );

            const creatorId = story[0].get("creator").get("username");

            // send fcm notification to story owner
            dispatch("notifications/fcm", {
                userId: creatorId,
                title: `Ticket moved to ${TicketStatusLabel(
                    payload.status
                )}`,
                url: `${rootState.global.endpoint}/taskboard/`,
                body: payload.title,
            }, { root: true });
        },
        // Remove criteria
        removeCriteria: async ({ commit }, payload) => {
            const Story = Parse.Object.extend("Stories")
            const query = new Parse.Query(Story)

            query.equalTo('objectId', payload.storyId)

            const result = await query.find();
            for (let i = 0; i < result.length; i++) {

                result[i].remove('criteria', payload.criteria)
                return await result[i].save()
            }
        },

        // Drag and Drop Ticket saving
        dropTicket: async ({ commit, state, dispatch }, payload) => {

            let status = payload.status;
            console.log('ticket to drop: ', state.ticketToDrop);

            if (status.includes('-') && state.ticketToDrop !== '') {
                let nStatus = status.split('-')[1];
                nStatus = parseInt(nStatus);


                console.log('paylaod => ', payload);
                console.log('target status => ', nStatus);
                const acl = new Parse.ACL();
                if (nStatus == 3) {
                    // update acl to ticket owner
                    const Story = Parse.Object.extend('Stories');
                    const storyQuery = new Parse.Query(Story);
                    storyQuery.include('creator');

                    const _story = await storyQuery.get(payload.storyId).then((data) => data);
                    const storyCreator = _story.get('creator');

                    console.log('creator => ', storyCreator)

                    acl.setPublicReadAccess(true);
                    acl.setReadAccess(storyCreator.id, true);
                    acl.setWriteAccess(storyCreator.id, true);
                    acl.setRoleReadAccess('developer', true);
                    acl.setRoleWriteAccess('developer', true);
                    acl.setRoleWriteAccess('master', true);
                    acl.setRoleWriteAccess('master', true);


                }



                const Ticket = Parse.Object.extend('Tickets');
                const query = new Parse.Query(Ticket);
                query.equalTo('objectId', state.ticketToDrop);

                const find = await query.find();

                if (find) {
                    // empty ticketToDrop
                    commit('ticketToDrop', { value: "" });
                    for (let i = 0; i < find.length; i++) {
                        find[i].set('status', nStatus);
                        if (nStatus == 3) {
                            find[i].setACL(acl);
                        }
                        await find[i].save();

                    }
                    // fetch story ticket again
                    await dispatch('storyTickets', { id: payload.storyId });
                }
            }
        },
        storyTickets: async ({ commit }, payload) => {
            try {

                // Story Pointer
                const storyPointer = { __type: 'Pointer', className: 'Stories', objectId: payload.id };
                console.log('story:', storyPointer);
                const Ticket = Parse.Object.extend('Tickets');
                const query = new Parse.Query(Ticket);
                query.equalTo('story', storyPointer);
                query.include('story');
                query.include('developer');
                query.include('sprint');
                query.ascending('updatedAt')

                const response = await query.find();
                commit('storyTickets', { data: response });

            } catch (error) {
                console.log('fetch tickets error', error);
                commit('storyTickets', { data: [] });
            }
        },
        userApprovers: async ({ commit, state, rootState },payload) => {
            // clear erorrMessage
            commit('errorMessage', { message: "" });
            try {
               

                console.log('fetching approver');
                let data = [];
                const Approvers = Parse.Object.extend("Approvers");
                const query = new Parse.Query(Approvers);

                query.containedIn('childrenId',[payload.currentEmpId.toString()]);

                const result = await query.find();

                if(result.length > 0){
                    for(let i = 0; i<result.length;i++){
                        const approver = {
                            Fname: result[i].get('fname'),
                        Lname: result[i].get('lname'),
                        EmpID: result[i].get('approverId'),
                        Email: result[i].get('email'),
                        Positn: result[i].get('position')
                        }
                        data.push(approver);
                    }

                    commit('userApprovers', { approvers: data });
                }

              
            } catch (error) {
                commit('errorMessage', { message: error });
            } finally {
                if (state.userApprovers.length <= 0) {
                    commit('errorMessage', { message: "An error occured while retrieving approvers. Please refresh again." });

                }
            }

        },
        userStories: async ({ commit }) => {
            const Stories = Parse.Object.extend("Stories");
            const query = new Parse.Query(Stories);
            query.equalTo("creator", Parse.User.current());
            query.include("creator");
            return await query.find().then(
                (data) => {
                    commit('userStories', { stories: data });

                },
                (error) => console.log("error: ", error)
            );
        },
        // All Stories
        allStories: async ({ commit, _, rootState }) => {
            const Stories = Parse.Object.extend("Stories");
            const query = new Parse.Query(Stories);

            query.equalTo('boardId', Parse.User.current().get('activeBoard'))
            query.include("creator");
            query.descending('createdAt');

            return await query.find().then(
                (data) => {
                    commit('allStories', { stories: data });
                },
                (error) => console.log("error: ", error)
            );
        },
        selectedStoryId: async ({ commit }, payload) => {
            commit('selectedStoryId', { storyId: payload.storyId });

        },
        // Get Devs in single story
        getStoryDevs: async ({ commit }, payload) => {
            Parse.masterKey = "abc123456";
            // Story Pointer
            const storyPointer = { __type: 'Pointer', className: 'Stories', objectId: payload.storyId };

            const Tickets = Parse.Object.extend('Tickets');
            const query = new Parse.Query(Tickets);
            query.include('developer');
            query.equalTo('story', storyPointer);
            let devs = [];
            let unique = [];

            await query.find().then((data) => {

                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        var user = data[i].get('developer');
                        if (user !== undefined && user !== null) {
                            var _user = { name: user.get('firstname'), id: user.get('username') ,avatar: user.get('avatar')};
                            if (unique.indexOf(user.get('username')) < 0) {
                                unique.push(user.get('username'));
                                devs.push(_user);
                            }
                        }
                    }
                }

            });

            return devs;
        },
        // Get All Devs
        getDevs: async ({ commit }) => {

            const query = new Parse.Query(Parse.Role);
            query.equalTo('name', 'developer');
            query.include('users');
            const response = await query.find();

            const relation = response[0].get('users');

            const relationQuery = relation.query();
            const results = await relationQuery.find();
            commit('developers', { devs: results });


        },
        createStory: async ({ commit }, payload) => {
            try {
                // Set ACL
                const _currentUser = Parse.User.current();
                const storyACL = new Parse.ACL();
                storyACL.setReadAccess(_currentUser, true);
                storyACL.setWriteAccess(_currentUser, true);

                // Set Role
                storyACL.setRoleReadAccess('developer', true);
                storyACL.setRoleWriteAccess('developer', true);

                const Story = Parse.Object.extend("Stories");
                const story = new Story();
                story.set("story", payload.story);
                story.set("approver", payload.selectedApprover);
                story.set("department", payload.selectedDepartment);
                story.set("creator", _currentUser);
                story.set("attachments", payload.uploadedAttachments);
                story.set("boardId", Parse.User.current().get('activeBoard'));
                story.setACL(storyACL);

                return await story.save();
            } catch (error) {
                console.log('save story error', error);
                alert('save story error. see console');
            }

        },
        // Create Ticket for Single Story
        createTicket: async ({ dispatch }, payload) => {

            // create Story Pointer
            const storyPointer = { __type: 'Pointer', className: 'Stories', objectId: payload.storyId };

            // create ACL
            const acl = new Parse.ACL();
            // Create Ticket Object
            const Ticket = Parse.Object.extend('Tickets');
            const ticket = new Ticket();



            acl.setPublicReadAccess(true);

            acl.setRoleReadAccess('developer', true);
            acl.setRoleWriteAccess('developer', true);





            ticket.set('dataStructure', payload.dataStructure);
            ticket.set('fileStructure', payload.fileStructure);
            ticket.set('recommendation', payload.recommendation);
            ticket.set('remarks', payload.remarks);
            ticket.set('score', parseInt(payload.score));
            ticket.set('story', storyPointer);
            ticket.set('status', 0);
            ticket.set("boardId", Parse.User.current().get('activeBoard'));

            ticket.setACL(acl);

            const result = await ticket.save();
            if (result) {
                console.log('create ticket result: ', result);

                // set story status to 1
                const Story = Parse.Object.extend('Stories');
                const storyQuery = new Parse.Query(Story);

                storyQuery.equalTo('objectId', payload.storyId);

                const update = await storyQuery.find();
                if (update) {
                    for (let i = 0; i < update.length; i++) {
                        update[i].set('status', 1);
                        update[i].save();
                    }
                }

                // fetch all story again
                await dispatch('allStories');
                // fetch all dev story again
                await dispatch('getStoryDevs', { storyId: payload.storyId });
                // fetch story ticket again
                await dispatch('storyTickets', { id: payload.storyId });



            }
        },
        deleteStory: async ({ commit, state }, payload) => {
            const Story = Parse.Object.extend("Stories");
            const query = new Parse.Query(Story);
            query.equalTo("objectId", payload.storyId);
            query.equalTo("creator", Parse.User.current());
            const result = await query.find();

            for (var i = 0; i < result.length; i++) {
                if (result[i].destroy()) {
                    state.userStories = state.userStories.filter((story) => story.id !== payload.storyId);
                }


            }
            return;
        },
        ownTicket: async ({ dispatch }, payload) => {

            // create user Pointer
            const developer = { __type: 'Pointer', className: '_User', objectId: payload.devId };

            // create ACL
            const acl = new Parse.ACL();

            acl.setPublicReadAccess(true); // public can see
            acl.setRoleReadAccess('developer', true); // other devs can see
            acl.setRoleWriteAccess('developer', true); // other devs/tester dev can update
            acl.setRoleReadAccess('master', true); // master dev can see
            acl.setRoleWriteAccess('master', true); // master dev can edit

            if (payload.devId !== null && payload.devId !== "") {
                // create user Pointer
                const developer = { __type: 'Pointer', className: '_User', objectId: payload.devId };

                acl.setReadAccess(payload.devId, true); // owner can see
                acl.setWriteAccess(payload.devId, true); // owner can edit

            }

            const Ticket = Parse.Object.extend('Tickets');
            const query = new Parse.Query(Ticket);
            query.equalTo('objectId', payload.ticketId);


            const find = await query.find();
            if (find) {
                for (let i = 0; i < find.length; i++) {
                    find[i].set('developer', developer);
                    find[i].set('status', 1);
                    find[i].setACL(acl);
                    await find[i].save();

                }
                // fetch story ticket again
                await dispatch('storyTickets', { id: payload.storyId });
                // fetch all ticket again to reflect in home page
                await dispatch('alltickets/getAllTickets', null, { root: true })
                return true;
            } else {
                return false;
            }
        },
        setSprint: async ({ rootState, dispatch }, payload) => {

            // create Sprint Pointer
            const sprintPointer = { __type: 'Pointer', className: 'Sprints', objectId: payload.sprintId };
            // create User Pointer
            const developerPointer = { __type: 'Pointer', className: '_User', objectId: payload.devId };
            // create User Pointer
            const ticketPointer = { __type: 'Pointer', className: 'Tickets', objectId: payload.ticketId };


            const Ticket = Parse.Object.extend('Tickets');
            const query = new Parse.Query(Ticket);
            query.equalTo('objectId', payload.ticketId);

            const moveSprint = payload.moveSprint;


            let fromSprint;

            const find = await query.find();
            if (find) {
                for (let i = 0; i < find.length; i++) {
                    fromSprint = find[i].get('sprint');

                    if (moveSprint) {
                        // find[i].decrement('score');

                        // Clone current ticket
                        const cloned = find[i].clone();
                        // set new score & sprint for the cloned ticket
                        cloned.set('score', -1);
                        cloned.set('sprint', sprintPointer);
                        // save cloned ticket as new record
                        await cloned.save();


                    } else {
                        find[i].set('sprint', sprintPointer);
                    }


                    let response = await find[i].save();

                    if (response && moveSprint) {

                        // log moving of sprint
                        const MovedSprint = Parse.Object.extend('MovedSprints');
                        const movedSprint = new MovedSprint();

                        movedSprint.set('developer', developerPointer);
                        movedSprint.set('ticket', ticketPointer);
                        movedSprint.set('from', fromSprint);
                        movedSprint.set('to', sprintPointer);
                        movedSprint.set('reason', payload.reason);
                        await movedSprint.save();


                    }

                    if (moveSprint) {
                        // Delete the original ticket
                        const Ticket = Parse.Object.extend('Tickets');
                        const query = new Parse.Query(Ticket);
                        query.equalTo('objectId', payload.ticketId);
                        query.find().then((response) => {
                            response[0].destroy();

                        })
                    }

                    // fetch story ticket again
                    await dispatch('storyTickets', { id: payload.storyId });
                    // fetch all ticket again to reflect in home page
                    await dispatch('alltickets/getAllTickets', null, { root: true })



                    if (moveSprint) {
                        // fetch specific sprint ticket again
                        console.log('fromSprint', fromSprint);
                        await dispatch('sprints/getTicket', { sprintId: fromSprint.id }, { root: true });
                    }

                    // own also this ticket
                    console.log('ownticket: ', payload.ownTicket);
                    if (payload.ownTicket) {
                        await dispatch('ownTicket', {
                            devId: rootState.auth.currentUser.id, // objectid of the logged in user
                            ticketId: payload.ticketId,
                            storyId: payload.storyId
                        });
                    }



                }

                return true;
            } else {
                return false;
            }
        },
        scoreTicket: async ({ dispatch }, payload) => {



            const Ticket = Parse.Object.extend('Tickets');
            const query = new Parse.Query(Ticket);
            query.include('sprint')
            query.equalTo('objectId', payload.ticketId);


            const find = await query.find();
            if (find) {
                for (let i = 0; i < find.length; i++) {
                    find[i].set('score', parseInt(payload.score));
                    let res = await find[i].save();
                    // fetch story ticket again
                    await dispatch('storyTickets', { id: payload.storyId });
                    // fetch specific sprint ticket again
                    await dispatch('sprints/getTicket', { sprintId: res.get('sprint').id }, { root: true });
                    // fetch all ticket again to reflect in home page
                    await dispatch('alltickets/getAllTickets', null, { root: true })
                }

                return true;
            } else {
                return false;
            }
        },
        deleteTicket: async ({ commit, state }, payload) => {
            const Ticket = Parse.Object.extend("Tickets");
            const query = new Parse.Query(Ticket);
            query.equalTo("objectId", payload.ticketId);

            const result = await query.find();

            for (var i = 0; i < result.length; i++) {
                if (result[i].destroy()) {
                    state.storyTickets = state.storyTickets.filter((ticket) => ticket.id !== payload.ticketId);
                }


            }
            return;
        },
        uploadAttachment: async ({ commit }, payload) => {
            const parseFile = new Parse.File(payload.name, payload.file);
            return await parseFile.save().then((data) => data ? data._url : '');
        }
    },
    getters: {
        developers: (state) => state.developers,
        userStories: (state) => state.userStories,
        userApprovers: (state) => state.userApprovers,
        errorMessage: (state) => state.errorMessage,
        storyTickets: (state) => state.storyTickets,
        selectedStoryId: (state) => state.selectedStoryId,
    },
};

export default story;