<template>
  <div class="card mb-4 shadow-sm">
    <div class="card-body">
      <div class="row">
        <div class="col col-md-2 d-none d-lg-block">
          <img
            :src="defaultAvatar"
           
            alt="avatar"
            width="43"
            class="img rounded-pill border border-2"
          />
        </div>
        <div
          class="
            col col-lg-10
            d-flex
            align-items-center
            justify-content-between
            col-md-9
            p-0
          "
        >
          <h6 class="text-start">
            {{ owner }}<br /><span class="text-muted fw-normal">{{
              ownerDept
            }}</span>
          </h6>
          <div class="d-flex align-items-center justify-content-end">
            <span
              :class="
                status == 0
                  ? 'all-story-status status-pending'
                  : 'all-story-status status-active'
              "
            >
              <small>{{ status == 0 ? "PENDING" : "ACTIVE" }}</small>
            </span>

            <div class="btn-group dropend">
              <button
                class="btn btn-sm dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bx bx-dots-vertical-rounded"></i>
              </button>
              <ul class="dropdown-menu shadow-lg">
                <li>
                  <span class="dropdown-header">Actions</span>
                  <div class="divider border-bottom"></div>
                </li>
                <li>
                  <button
                    @click="createTicket(storyId)"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    data-bs-whatever="@storyId"
                  >
                    <i class="bx bx-plus"></i> Create Ticket
                  </button>
                </li>
                <li
                  v-if="
                    ownerId == $store.state.auth.currentUser.get('username') ||
                    $store.state.auth.roles.indexOf('master') > -1
                  "
                >
                  <button
                    class="dropdown-item text-danger"
                    @click="archiveStory"
                  >
                    <i class="bx bx-share"></i> Archive
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- CONTENT -->
      <hr />
      <div
        class="row pointer"
        @click="
          $router.push({
            name: 'UserStoryDetail',
            params: { id: $props.storyId },
          })
        "
      >
        <div class="col-lg-12">
          <p>
            {{ story }}
          </p>
        </div>
        <div class="col-lg-12 text-muted">
          <hr />
          <div class="row">
            <div v-if="dev !== null || dev !== undefined" class="d-flex">
              <img
                v-for="(user, index) in dev"
                :title="user['name']"
                :key="user['id']"
                :src="user['avatar']"
                alt="devs"
                :class="
                  index == 0
                    ? 'rounded-pill devAvatar'
                    : 'rounded-pill devAvatar negative-left-margin '
                "
              />

              <div>
                <div
                  class="text-end ms-2"
                  :set="(attachments = $props.attachments)"
                >
                  <small class="text-muted" v-if="attachments.length > 0">
                    <i
                      class="align-middle bx bx-link-alt"
                      :title="
                        attachments.length > 1
                          ? attachments.length + ' files'
                          : attachments.length + ' file'
                      "
                    ></i>
                  </small>
                  <small v-if="attachments.length > 0"> &middot; </small>
                  <small>{{
                    format_date(createdAt, "MM/DD/YY hh:mm A")
                  }}</small>
                </div>
              </div>
              <!-- {{ dev !== null ? dev[0]["id"] : "" }} -->
            </div>
            <div class="col" v-else>Text</div>
          </div>
        </div>
      </div>
      <!-- END CONTENT -->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "AllStoryCard",

  props: {
    story: String,
    storyId: String,
    owner: String,
    ownerId: String,
    ownerDept: String,
    createdAt: Date,
    status: Number,
    attachments: Array,
  },
  data() {
    return {
      defaultAvatar: "",
      devAvatar: "theme/img/devs/d-",
      dev: null,
    };
  },
  methods: {
    createTicket(storyId) {
      this.$store.dispatch("story/selectedStoryId", { storyId: storyId });
    },
    async archiveStory() {
      Swal.fire({
        title: "Archive this story?",
        text: "You won't be able to revert this!",
        icon: "warning",

        showCancelButton: true,
        confirmButtonText: "Yes, archive it!",
        confirmButtonColor: "#248bcd",
      }).then((result) => {
        if (result.isConfirmed) {
          // Check if this story has ticket in it before deleteting!
          this.$store
            .dispatch("story/storyTickets", {
              id: this.storyId,
            })
            .then(() => {
              let _tickets = this.$store.state.story.storyTickets;
              if (_tickets.length > 0) {
                // Dont delete story!
                Swal.fire(
                  "Failed!",
                  `This story can't be deleted because it contains ${
                    _tickets.length
                  } ${_tickets.length > 1 ? " tickets" : " ticket"}!`,
                  "error"
                );
              } else {
                // Delete Story
                this.$store
                  .dispatch("story/delete", {
                    id: this.storyId,
                  })
                  .then(() => {
                    this.$store.dispatch("story/allStories");
                    Swal.fire(
                      "Success!",
                      "Story has been moved to archived.",
                      "success"
                    );
                  });
              }
            });
        }
      });
    },
  },
  async created() {
    this.dev = await this.$store.dispatch("story/getStoryDevs", {
      storyId: this.$props.storyId,
    });

    const avatar = await this.$store.dispatch('global/getAvatar',{empId:this.ownerId});
    this.defaultAvatar = avatar;
  },
};
</script>

<style scoped>
.all-story-status {
  font-size: 12px;
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
  margin-top: -5px;
  margin-right: 10px;
}
.status-active {
  color: var(--bs-green);
  background-color: #d1ead3;
}
.status-pending {
  color: var(--bs-orange);
  background-color: #fff7cc;
}
.negative-left-margin {
  margin-left: -10px !important;
  border: 2px solid #eee !important;
  border-left: 2px solid #fff !important;
}
.devAvatar {
  border: 2px solid #eee;
  width: 30px;
}
.dropdown-toggle::after {
  display: none;
}
.pointer {
  cursor: pointer;
}
.card:hover {
  box-shadow: 0px 5px 5px hsla(0, 0%, 0%, 0.2) !important;
}
</style>