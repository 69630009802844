import Parse from 'parse';
import moment from 'moment';


const sprints = {
    namespaced: true,
    state: {
        sprints: [],
        sections: [],
        currentSprintTickets: [], // chart donut section
    },
    mutations: {
        sprints(state, payload) {
            state.sprints = payload.sprints;
        },
        currentSprintTickets(state, payload) {
            state.currentSprintTickets = payload.tickets;
        },
        sections(state, payload) {
            state.sections = payload.sections;
        },
    },
    getters: {
        sprints: (state) => state.sprints,
        sections: (state) => state.sections,
    },
    actions: {
        delete: async ({ dispatch }, payload) => {
            await payload.sprint.destroy();
            await dispatch('getSprints');
            return true;
        },
        edit: async ({ }, payload) => {

            const Sprint = Parse.Object.extend("Sprints");
            const query = new Parse.Query(Sprint);

            return query.get(payload.sprintId).then((result) => {
                result.set(payload.column, payload.value);
                result.save();
            });
        },
        getTicket: async ({ commit }, payload) => {
            // Sprint Pointer
            const sprintPointer = { __type: 'Pointer', className: 'Sprints', objectId: payload.sprintId };

            const Tickets = Parse.Object.extend("Tickets")
            const query = new Parse.Query(Tickets)
            query.equalTo('sprint', sprintPointer)
            query.include('developer')
            const response = await query.find()

            let _sections = [];
            let blCount = 0; // backlog
            let wipCount = 0; // work in progress
            let qaCount = 0; // quality assurance
            let uatCount = 0; // user acceptance testing
            let relCount = 0; // released

            //  { label: 'Red section' value: 25, color: 'red' },
            if (response) {
                commit('currentSprintTickets', { tickets: response });
                for (let i = 0; i < response.length; i++) {
                    if (response[i].get('status') == 0) {
                        blCount++;
                    }
                    if (response[i].get('status') == 1) {
                        wipCount++;
                    }
                    if (response[i].get('status') == 2) {
                        qaCount++;
                    }
                    if (response[i].get('status') == 3) {
                        uatCount++;
                    }
                    if (response[i].get('status') == 4) {
                        relCount++;
                    }
                }
            }





            _sections = [
                { label: `Backlog (${blCount})`, value: blCount, color: '#adb5bd' },
                { label: `In Progress (${wipCount})`, value: wipCount, color: '#dc3545' },
                { label: `Quality Assurance (${qaCount})`, value: qaCount, color: '#ffc107' },
                { label: `User Acceptance Testing (${uatCount})`, value: uatCount, color: '#0dcaf0' },
                { label: `Release (${relCount})`, value: relCount, color: '#198754' },
            ];

            return _sections;

        },
        getSprints: async ({ commit }, payload) => {

            var d = new Date();
            var start = new moment(d);
            start.startOf('day');



            const Sprints = Parse.Object.extend("Sprints")
            const query = new Parse.Query(Sprints)

            query.equalTo('boardId', Parse.User.current().get('activeBoard'))
            query.descending('createdAt')

            // Only get current and future sprints
            if (payload == undefined || payload.allSprint == undefined) {
                query.greaterThanOrEqualTo('to', start.toDate())
               
            }

            const response = await query.find()
          

            commit('sprints', { sprints: response })

            return response
        },
        saveSprint: async ({ commit, state, dispatch }, payload) => {
            // get all sprints before saving a new one
            await dispatch('getSprints',{allSprint: true});

            const Sprints = Parse.Object.extend("Sprints")
            const sprint = new Sprints()

            // ACL
            const acl = new Parse.ACL();

            acl.setPublicReadAccess(true)
            acl.setPublicWriteAccess(false)
            acl.setRoleReadAccess('developer', true)
            acl.setRoleReadAccess('master', true)
            acl.setRoleWriteAccess('master', true)
            // count total retrieve sprints
            let sNumber = state.sprints.length + 1


            if (state.sprints.length > 0) {
                sNumber = state.sprints[0].get('number') + 1;
            }

            sprint.set('from', new Date(payload.from))
            sprint.set('to', new Date(payload.to))
            sprint.set('goal', payload.goal)
            sprint.set('method', payload.method)
            sprint.set('number', sNumber)
            sprint.set('boardId', Parse.User.current().get('activeBoard'))
            sprint.setACL(acl)

            const response = await sprint.save()
            await dispatch('getSprints')
            await dispatch('alltickets/getAllTickets', null, { root: true })
            return response
        },
        promoteTicket: async ({ dispatch }, payload) => {

            await dispatch("story/dropTicket", {
                status: payload.status,
                storyId: payload.storyId,
            }, { root: true });

            await dispatch("getTicket", {
                sprintId: payload.currentSprintId,
            });

            // fetch all ticket again to reflect in home page
            await dispatch('alltickets/getAllTickets', null, { root: true })
        },

    },
};

export default sprints;