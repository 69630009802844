<template>
  <div class="card shadow-sm border-0 my-4">
    <div class="card-body">
      <h5>
        <i class="bx bxs-check-circle text-success align-middle"></i>
        Acceptance Criteria
        <!-- <hr class="dropdown-divider text-light" /> -->
      </h5>
      <ul v-if="$props.ticket.storyCriteria.length > 0">
        <li
          class="border-bottom py-2"
          v-for="(criteria, index) in $props.ticket.storyCriteria"
          :key="index"
          :class="index % 2 == 0 ? 'bg-light' : ''"
        >
          <p class="ps-2 mb-0">{{ criteria }}</p>
        </li>
      </ul>
      <p class="m-0 pt-3 text-muted" v-else>Not Set</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AcceptanceCriteriaCard",
  props: {
    ticket: Object,
  },
};
</script>

<style>
</style>