<template>
  <div class="row">
    <div class="col">
      <div class="d-none">
        <input
          type="file"
          name="ticket-attachment"
          id="ticket-attachment"
          @change="uploadFile"
          ref="file"
        />
      </div>
      <h6
        class="
          mt-3
          adapt__theme_lighter
          d-flex
          align-items-center
          justify-content-between
        "
      >
        <span><i class="bx bx-link align-middle"></i> Attachments</span>

        <div
          class="dropdown"
          v-if="$props.devId == $store.state.auth.currentUser.get('username')"
        >
          <button
            class="
              btn btn-sm
              adapt__theme_lighter adapt__theme_bg_dark
              dropdown-toggle
            "
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bx bx-plus align-middle"></i> Add
          </button>
          <ul
            class="dropdown-menu shadow-lg"
            style="width: 200px"
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <a
                class="dropdown-item p-2"
                style="cursor: pointer"
                @click="triggerUploadFile()"
              >
                <i class="bx bx-upload align-middle"></i> Attach a file</a
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li><span class="dropdown-header">Add link</span></li>
            <li class="p-2">
              <input
                :class="linkLabel.trim() == '' ? 'is-invalid' : 'is-valid'"
                v-model="linkLabel"
                type="text"
                class="form-control"
                placeholder="Label"
              />
            </li>
            <li class="p-2">
              <input
                :class="
                  linkURL.trim() == '' || !linkURL.includes('http')
                    ? 'is-invalid'
                    : 'is-valid'
                "
                required
                v-model="linkURL"
                type="text"
                class="form-control"
                placeholder="URL"
              />
            </li>
            <li class="p-2 text-end">
              <button
                @click="addLink()"
                class="btn btn-sm text-light btn-primary"
              >
                Submit
              </button>
            </li>
          </ul>
        </div>
      </h6>
      <!-- files -->

      <div
        v-for="attachment in $store.state.alltickets.files"
        :key="attachment.id"
      >
        <div
          :class="
            $props.devId == $store.state.auth.currentUser.get('username')
              ? ''
              : 'mb-3'
          "
          class="card adapt__theme_bg_darker"
        >
          <!-- Image Viewer Modal -->
          <div
            class="modal fade"
            id="imageModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-fullscreen border-0">
              <div class="modal-content" style="background-color: transparent">
                <div class="modal-header border-0">
                  <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    ref="title"
                  ></h5>
                  <button
                    type="button"
                    class="btn-close btn-outline-light"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body" id="modal-body"></div>
                <div class="p-5"></div>
              </div>
            </div>
          </div>
          <a
            :data-bs-toggle="
              ['jpg', 'jpeg', 'png', 'ico', 'gif'].indexOf(
                attachment.get('name').split('.').at(-1).toLowerCase()
              ) > -1
                ? 'modal'
                : ''
            "
            data-bs-target="#imageModal"
            download
            target="_blank"
            :href="
              ['jpg', 'jpeg', 'png', 'ico', 'gif'].indexOf(
                attachment.get('name').split('.').at(-1).toLowerCase()
              ) > -1
                ? null
                : attachment.get('url')
            "
            @click="viewImage(attachment.get('name'), attachment.get('url'))"
          >
            <div class="row p-0 g-0" :set="(name = attachment.get('name'))">
              <div
                class="
                  col-md-4
                  p-0
                  bg-secondary
                  d-flex
                  align-items-center
                  justify-content-center
                  text-white
                "
                :set="(parts = name.split('.'))"
              >
                <!-- <img
                     
                      :src="attachment.get('url')"
                      class="ps-2 pe-2"
                      alt=""
                    /> -->

                <div
                  v-if="
                    ['jpg', 'jpeg', 'png', 'ico', 'gif'].indexOf(
                      parts.at(-1).toLowerCase()
                    ) > -1
                  "
                  :style="
                    'width:100%;height:90px;background-position:center top;background-size:cover;background-repeat:no-repeat;background-image: url(' +
                    attachment.get('url') +
                    ')'
                  "
                ></div>

                <span v-else class="text-uppercase">
                  <span v-if="attachment.get('type') == 'file'">
                    <!-- text file icon -->
                    <i
                      v-if="parts.at(-1).toLowerCase() == 'txt'"
                      class="bx bx-lg bxs-file-txt"
                    ></i>

                    <!-- doc file icon -->
                    <i
                      v-else-if="
                        ['doc', 'docx'].indexOf(parts.at(-1).toLowerCase()) > -1
                      "
                      class="bx bx-lg bxs-file-doc"
                    ></i>

                    <!-- sql file icon -->
                    <i
                      v-else-if="parts.at(-1).toLowerCase() == 'sql'"
                      class="bx bx-lg bxs-data"
                    ></i>

                    <!-- pdf file icon -->
                    <i
                      v-else-if="parts.at(-1).toLowerCase() == 'pdf'"
                      class="bx bx-lg bxs-file-pdf"
                    ></i>

                    <!-- archive file icon -->
                    <i
                      v-else-if="
                        ['zip', 'rar', '7z', 'tar'].indexOf(
                          parts.at(-1).toLowerCase()
                        ) > -1
                      "
                      class="bx bx-lg bxs-file-archive"
                    ></i>

                    <!-- js file icon -->
                    <i
                      v-else-if="parts.at(-1).toLowerCase() == 'js'"
                      class="bx bx-lg bxl-javascript"
                    ></i>

                    <!-- php file icon -->
                    <i
                      v-else-if="parts.at(-1).toLowerCase() == 'php'"
                      class="bx bx-lg bxl-php"
                    ></i>

                    <!-- videos file icon -->
                    <i
                      v-else-if="
                        [
                          'mkv',
                          'mp4',
                          'mov',
                          'wmv',
                          'flv',
                          'avi',
                          'webm',
                        ].indexOf(parts.at(-1).toLowerCase()) > -1
                      "
                      class="bx bx-lg bxs-videos"
                    ></i>

                    <!-- default icon -->
                    <span v-else>
                      {{ parts.at(-1) }}
                    </span>
                  </span>
                  <i v-else class="bx bx-link bx-md"></i>
                </span>
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h6 class="card-title text-truncate">
                    <span
                      class="adapt__theme_darker"
                      v-if="attachment.get('type') == 'file'"
                      >{{ parts.slice(0, parts.length - 1).join(".") }}</span
                    >
                    <span v-else class="adapt__theme_darker">{{
                      attachment.get("name")
                    }}</span>
                  </h6>
                  <!-- <p class="card-text">
                      <small>{{ attachment.get("url") }}</small>
                    </p> -->
                  <p class="card-text">
                    <small class="adapt__theme_darker">{{
                      $props.formatDate(attachment.get("createdAt"))
                    }}</small>
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <button
          v-if="$props.devId == $store.state.auth.currentUser.get('username')"
          @click="removeFile(attachment.id, attachment.get('name'))"
          class="btn btn-sm m-0 adapt__theme_light"
        >
          <small class="adapt__theme_light">Remove</small>
        </button>
      </div>

      <!-- end files -->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "TicketDetailsAttachments",
  props: {
    ticket: Object,
    devId: String,
    getFiles: Function,
    formatDate: Function,
  },
  data() {
    return {
      linkLabel: "",
      linkURL: "",
    };
  },

  methods: {
    viewImage(name, url) {
      const imgExt = ["png", "jpg", "jpeg", "gif", "ico"];
      const ext = name.split(".").at("-1");
      if (imgExt.indexOf(ext.toLowerCase()) > -1) {
        document
          .querySelectorAll("#modal-body")[0]
          .setAttribute(
            "style",
            "background-image: url(" +
              url +
              ");background-position:center;background-repeat:no-repeat;background-size: contain;background-color:transparent !important"
          );
      }
    },
    addLink() {
      if (
        this.linkLabel.trim() !== "" &&
        this.linkURL.trim() !== "" &&
        (this.linkURL.includes("http://") || this.linkURL.includes("https://"))
      ) {
        this.uploadFile(this.linkLabel, this.linkURL, "link");
      }
    },
    triggerUploadFile() {
      this.$refs.file.click();
    },
    async uploadFile(label = "", url = "", type = "file") {
      this.files = type == "link" ? null : this.$refs.file.files[0];
      if (type == "file") {
        const size = this.files.size / 10000;
        if (size >= 2000) {
          Swal.fire("File too large", "You can only upload upto 19mb", "error");
          return;
        }
      }
      Swal.fire({
        title: type !== "file" ? this.linkLabel : this.files.name,
        text: "Are you sure you want to upload this file?",

        showCancelButton: true,
        confirmButtonText: "Upload",
        preConfirm: (_) => {
          Swal.showLoading();
          return this.$store
            .dispatch("alltickets/uploadFile", {
              file: this.files,
              ticketId: this.$props.ticket.id,
              uploader: this.$props.ticket.devObjectId,
              type: type,
              url: url,
              label: label,
            })
            .then(function (response) {
              console.log("done: ", response);
              return response;
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
        backdrop: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire("Uploaded!", "", "success");
          this.$props.getFiles();
        }
      });
    },
    removeFile(objectId, name) {
      Swal.fire({
        title: name,
        text: "Remove this file?",

        showCancelButton: true,
        confirmButtonText: "Remove",
        preConfirm: (_) => {
          Swal.showLoading();
          return this.$store.dispatch("alltickets/removeFile", {
            objectId: objectId,
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
        backdrop: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire("Removed!", "", "success");
          this.$props.getFiles();
        }
      });
    },
    submitFile() {
      const formData = new FormData();
      formData.append("file", this.Images);
    },
  },
};
</script>

<style>
</style>