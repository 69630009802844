import Parse from "parse";
const global = {
    namespaced: true,
    state: {
        production: true,
        endpoint: "http://172.28.1.2",
        home: "/", // "/" if local, then "/taskboard" if production
    },
    getters: {},
    mutations: {},
    actions: {
        setActiveTheme: async ({ commit }, payload) => {
            const user = Parse.User.current();
            user.set('activeTheme', payload.name);
            user.save();
        },
        getAvatar: async ({},payload) => {
            const User = Parse.Object.extend("_User");
            const query = new Parse.Query(User);

            query.equalTo('username',payload.empId);

            const response = await query.find();
          
            return response[0].get('avatar');
        },
    }
}

export default global;