<template>
  <!-- Confirm Modal -->
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    :aria-labelledby="modalId + 'Label'"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="modalId + 'Label'">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ body }}
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            data-bs-dismiss="modal"
            @click="$emit('on-modal-confirm')"
            class="btn btn-primary"
          >
            {{ confirmLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--END CONFIRM MODAL-->
</template>

<script>
export default {
  name: "ModalConfirm",
  props: {
    title: String,
    body: String,
    confirmLabel: String,
    modalId: String,
  },
};
</script>

<style>
</style>