<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- FORM -->

        <form @submit="saveSprint">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Create New Sprint
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="from" class="form-label">From</label>
              <input
                type="date"
                v-model="from"
                class="form-control"
                id="from"
                required
              />
            </div>
            <div class="mb-3">
              <label for="from" class="form-label">To</label>
              <input
                type="date"
                v-model="to"
                class="form-control"
                id="from"
                required
              />
            </div>
            <div class="mb-3">
              <label for="goal" class="form-label">Goal</label>
              <textarea
                required
                name="goal"
                id="goal"
                v-model="goal"
                cols="30"
                rows="5"
                class="form-control"
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="method" class="form-label">Method</label>
              <textarea
                name="method"
                id="method"
                v-model="method"
                cols="30"
                rows="4"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              :data-bs-dismiss="goal !== '' ? 'modal' : ''"
              class="btn btn-primary"
            >
              Save Sprint
            </button>
          </div>
        </form>
        <!-- END FORM -->
      </div>
    </div>
  </div>
  <!-- end modal -->
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "CreateSprintModal",
  data() {
    return {
      from: "",
      to: "",
      goal: "",
      method: "",
    };
  },
  created() {
    this.populateDates();
  },
  methods: {
    async saveSprint(e) {
      e.preventDefault();

      const response = await this.$store.dispatch("sprints/saveSprint", {
        from: this.from,
        to: this.to,
        method: this.method,
        goal: this.goal,
      });

      if (response) {
        Swal.fire("Success!", "Sprint has been created.", "success");
        this.goal = "";
        this.method = "";
        this.$router.go();
      } else {
        Swal.fire("Failed!", "Failed in creating sprint", "error");

        console.log("failed sprint creation: ", response);
      }
    },
    populateDates() {
      let date = new Date();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let year = date.getFullYear();

      this.from = `${year}-${month}-${day}`;
      this.to = `${year}-${month}-${day + 4}`;
    },
  },
};
</script>

<style>
</style>