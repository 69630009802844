<template>
  <!-- Include Confirm Modal in card -->
  <ModalConfirm
    :modalId="'confirmModal' + index"
    title="Confirm Delete"
    body="Are you sure you want to delete this story?"
    confirmLabel="Delete"
    @on-modal-confirm="$emit('on-modal-confirm', story.objectId)"
  />
  <!-- END MODAL CONFIRM -->

  <!-- Main Card -->
  <div class="card bg-white shadow my-2 p-2">
    <div class="row d-flex align-items-center">
      <div class="col-lg-1">
        <h5 class="text-center align-middle text-grey">#{{ index + 1 }}</h5>
      </div>
      <div class="col pe-5">
        <div class="row">
          <div class="col-lg-6 pl-5 text-start">
            <i class="bx align-middle bx-calendar text-muted"></i
            ><small class="text-muted">{{
              format_date(story.createdAt, "MMM DD, Y hh:mm A")
            }}</small>
          </div>
          <div class="col-lg-6 text-end">
            <button
              v-if="story.status == 0"
              data-bs-toggle="modal"
              :data-bs-target="'#confirmModal' + index"
              class="btn btn-sm"
            >
              <i class="bx bx-sm text-danger bx-trash"></i>
            </button>
            <router-link
              v-else
              :to="{ name: 'UserStoryDetail', params: { id: story.objectId } }"
              class="btn btn-sm"
            >
              <i class="bx text-primary bx-sm bx-link-external"></i>
            </router-link>
          </div>
        </div>
        <p class="pt-3 lead pb-4">{{ story.story }}</p>
        <hr style="color: #ccc" />
        <div class="row">
          <div class="col">
            <small
              :class="
                story.status == 0
                  ? 'story-status-pending'
                  : 'story-status-active'
              "
              >{{ story.status == 0 ? " PENDING" : " ACTIVE" }}</small
            >
          </div>
          <div class="col-lg-2" v-if="story.attachments.length > 0">
            <i class="text-muted pb-1 bx bx-link-alt align-middle"></i>
            <small class="text-muted"
              >{{ story.attachments.length }} Attachment{{
                story.attachments.length > 1 ? "s" : ""
              }}</small
            >
          </div>
          <div class="col-lg-7 text-end" style="color: #aaa">
            Approver:
            <span class="fw-light text-secondary">{{
              story.approver["Lname"] + ", " + story.approver["Fname"]
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END MAIN STORY CARD -->
</template>

<script>
import ModalConfirm from "../components/ModalConfirm.vue";
export default {
  name: "StoryCard",
  components: { ModalConfirm },
  props: {
    story: Object,
    index: Number,
  },
  emits: ["on-modal-confirm"],
};
</script>

<style scoped>
.text-grey {
  color: #777;
}
.story-status-pending {
  font-size: 12px;
  padding: 5px;
  font-weight: 500;
  color: var(--bs-warning) !important;
  background-color: #fff2cc;
  border-radius: 5px;
}
.story-status-active {
  font-size: 12px;
  padding: 5px;
  font-weight: 500;
  color: var(--bs-success) !important;
  background-color: #d9ead3;
  border-radius: 5px;
}
</style>