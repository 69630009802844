<template>
  <!-- New Story Modal -->
  <NewStory
    title="Create New Story"
    description="Describe what do you want to achieve."
  />
  <!-- End New Story Modal -->
  <div class="row py-3" v-if="!this.$store.state.story.userStories.length">
    <div class="row mt-3">
      <h2 class="text-center pb-3 page_title adapt__theme_lighter">
        No Stories Found.
      </h2>
    </div>

    <div
      class="
        d-flex
        justify-content-center
        h-100
        align-items-center
        flex-column
        bd-highlight
        mb-
      "
    >
      <img
        width="300"
        src="theme/img/undraw_Add_notes_re_ln36.svg"
        alt=""
        class="my-5"
      />
      <h5 class="adapt__theme_lighter">What are user stories?</h5>
      <p class="adapt__theme_light py-2 pb-3">
        In software development and product management, a user story is an
        informal, natural language description of features of a software system.
      </p>
      <button
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        class="btn rounded-pill adapt__theme_bg_dark p-3 text-light w-25"
      >
        <i class="bx bx-sm align-middle bx-message-alt-edit"></i>Create Story
      </button>
    </div>
  </div>
  <!-- Display User Stories  -->
  <div v-else>
    <div class="row py-3">
      <div class="row mt-3">
        <div class="col-lg-6"><h2 class="pb-5">My Stories</h2></div>
        <div class="col-lg-6 text-end">
          <button
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            class="btn btn-primary p-2 text-light w-25 rounded-pill"
          >
            <i class="bx bx-sm align-middle bx-plus"></i> New Story
          </button>
        </div>
      </div>

      <div
        class="col-lg-12"
        v-for="(story, index) in this.$store.state.story.userStories"
        :key="story.toJSON().id"
      >
        <StoryCard
          @on-modal-confirm="deleteStory"
          :story="story.toJSON()"
          :index="index"
        />
      </div>
    </div>
  </div>
</template>




<script>
import NewStory from "./NewStory.vue";
import StoryCard from "../components/StoryCard.vue";

export default {
  name: "MyStories",
  components: { StoryCard, NewStory },
  async created() {
    await this.getMyStories();
  },

  methods: {
    async getMyStories() {
      // fetch user stories
      await this.$store.dispatch("story/userStories");
    },

    async deleteStory(storyId) {
      await this.$store.dispatch("story/deleteStory", { storyId: storyId });
    },
  },
};
</script>

<style scoped>
</style>