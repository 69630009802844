import store from '../store/'

export function ProcessGuard() {
    if (store.state.auth.currentUser == null || ['none', '', null].indexOf(store.state.board.active) > -1) {
        return;
    }
    const name = store.state.auth.currentUser.get('activeTheme');


    if (!name.includes("-image-")) {

        document
            .querySelectorAll(".l-navbar")[0]
            .classList.remove("force__header_lnavbar_dark");

        document
            .querySelectorAll(".l-navbar")[0]
            .classList.remove("force__header_lnavbar_light");

        document
            .querySelectorAll("header.header")[0]
            .classList.remove("force__header_lnavbar_light");

        document
            .querySelectorAll("header.header")[0]
            .classList.remove("force__header_lnavbar_dark");

        // nav logo
        document
            .querySelectorAll("span.nav_logo-name")[0]
            .classList.remove("nav-logo-default");

        // add new header style
        document
            .querySelectorAll("header.header")[0]
            .classList.remove("force__header_default");

        document
            .querySelectorAll(".l-navbar")[0]
            .classList.remove("force__lnavbar_default");

        document
            .querySelectorAll("span.nav_logo-name")[0]
            .classList.add("adapt__theme_lighter");

        if (document
            .querySelectorAll("h2.page_title")[0] !== undefined) {
            document
                .querySelectorAll("h2.page_title")[0].classList.remove("new__page_title");
        }


        document
            .querySelectorAll("#lnavbar-roles")[0]
            .classList.remove("lnavbar-roles");
        document
            .querySelectorAll("#lnavbar-roles")[0]
            .classList.add("adapt__theme_light");

        document
            .querySelectorAll("#lnavbar-username")[0]
            .classList.remove("lnavbar-username");

        document
            .querySelectorAll("a.nav_link")[0]
            .classList.remove("nav_link_default");
        if (document.querySelectorAll("a.nav_link")) {
            var links = document.querySelectorAll("a.nav_link");
            for (var i = 0; i < links.length; i++) {
                document
                    .querySelectorAll("a.nav_link")
                [i].classList.remove("nav_link_default");
            }
            //
        }

        // special class for default theme
        if (name == "__theme-light") {
            // TODO:: remove old

            document
                .querySelectorAll("span.nav_logo-name")[0]
                .classList.remove("adapt__theme_lighter");

            // add new header style
            document
                .querySelectorAll("header.header")[0]
                .classList.add("force__header_default");
            // add new l-navbar style
            document
                .querySelectorAll(".l-navbar")[0]
                .classList.add("force__lnavbar_default");

            document
                .querySelectorAll("#lnavbar-roles")[0]
                .classList.add("lnavbar-roles");

            document
                .querySelectorAll("#lnavbar-roles")[0]
                .classList.remove("adapt__theme_light");
            // page title
            if (document
                .querySelectorAll("h2.page_title")[0] !== undefined) {
                document
                    .querySelectorAll("h2.page_title")[0]
                    .classList.add("new__page_title");
            }

            // nav logo
            document
                .querySelectorAll("span.nav_logo-name")[0]
                .classList.add("nav-logo-default");
            document
                .querySelectorAll("#lnavbar-username")[0]
                .classList.add("lnavbar-username");

            if (document.querySelectorAll("a.nav_link")) {
                var links = document.querySelectorAll("a.nav_link");
                for (var i = 0; i < links.length; i++) {
                    document
                        .querySelectorAll("a.nav_link")
                    [i].classList.add("nav_link_default");
                }
                //
            }

            if (document.querySelectorAll(".adapt__theme_light")[0] !== undefined) {
                var links = document.querySelectorAll(".adapt__theme_light");
                for (var i = 0; i < links.length; i++) {


                    links[i].classList.add("adapt__theme_light_default");

                    links[i].classList.remove("adapt__theme_light");
                }
            }

            if (document.querySelectorAll(".adapt__theme_lighter")[0] !== undefined) {
                var links = document.querySelectorAll(".adapt__theme_lighter");
                for (var i = 0; i < links.length; i++) {

                    links[i].classList.add("adapt__theme_lighter_default");

                    links[i].classList.remove("adapt__theme_lighter");
                }


            }

            if (document.querySelector("#status-0") !== null) {
                document.querySelector("#status-0").classList.add("bg__default");
                document.querySelector("#status-1").classList.add("bg__default");
                document.querySelector("#status-2").classList.add("bg__default");
                document.querySelector("#status-3").classList.add("bg__default");
            }
        }
    }

    if (name == "__theme-image-1" || name == "__theme-image-1") {
        document
            .querySelectorAll(".l-navbar")[0]
            .classList.add("force__header_lnavbar_dark");

        document
            .querySelectorAll("header.header")[0]
            .classList.add("force__header_lnavbar_dark");
    }

    if (name == "__theme-image-2") {
        document
            .querySelectorAll(".l-navbar")[0]
            .classList.add("force__header_lnavbar_light");
        document
            .querySelectorAll("header.header")[0]
            .classList.add("force__header_lnavbar_light");
    }

    return true;
}