<template>
  <div class="row py-3" v-if="currentSprint">
    <div class="row mt-3 mb-5">
      <div class="col">
        <h2 class="text-capitalize adapt__theme_lighter">
          <button class="btn" @click="$router.go(-1)">
            <i class="bx bx-arrow-back"></i>
          </button>
          Sprint #{{ currentSprint.get("number") }}
        </h2>
        <p class="ps-5 ms-1 adapt__theme_light">
          {{ formatDate(currentSprint.get("from"), currentSprint.get("to")) }}
        </p>
      </div>
      <div
        class="col text-end"
        v-if="$store.state.sprints.currentSprintTickets.length <= 0"
      >
        <button class="btn adapt__theme_bg_dark" @click="deleteSprint">
          <i class="bx bx-trash"></i> Delete Sprint
        </button>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-lg-5">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button adapt__theme_bg_darker collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                <h6 class="m-0">Goal</h6>
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body adapt__theme_bg_dark">
                <div class="row">
                  <div class="col-lg-11">
                    <p class="bg-light p-2 m-0 rounded-pill">
                      {{ currentSprint.get("goal") }}
                    </p>
                  </div>
                  <div class="col-lg-1">
                    <button
                      class="btn btn-sm"
                      @click="edit(currentSprint.get('goal'), 'goal')"
                    >
                      <i class="bx bx-edit text-primary"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                <h6 class="m-0">Method</h6>
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <div class="row">
                  <div class="col-lg-11">
                    <p class="bg-light p-2 m-0 rounded-pill">
                      {{ currentSprint.get("method") }}
                    </p>
                  </div>
                  <div class="col-lg-1">
                    <button
                      class="btn btn-sm"
                      @click="edit(currentSprint.get('method'), 'method')"
                    >
                      <i class="bx bx-edit text-primary"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                <h6 class="m-0">Remarks</h6>
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <div class="row">
                  <div class="col-lg-11">
                    <p class="bg-light p-2 m-0 rounded-pill">
                      {{ currentSprint.get("remarks") }}
                    </p>
                  </div>
                  <div class="col-lg-1">
                    <button
                      class="btn btn-sm"
                      @click="edit(currentSprint.get('remarks'), 'remarks')"
                    >
                      <i class="bx bx-edit text-primary"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- BOARD -->
    <div class="col pt-3 border-end" id="status-0">
      <h5 class="pb-4 adapt__theme_lighter">Backlog</h5>

      <div
        v-for="ticket in $store.state.alltickets.allTickets.filter(
          (ticket) => ticket.get('status') == 0
        )"
        :key="ticket.id"
      >
        <MyTicket
          :status="0"
          :sprint="ticket.get('sprint')"
          :roles="$store.getters['auth/roles']"
          :storyId="ticket.get('story').id"
          :isMasterDev="$store.getters['auth/roles'].length > 0"
          :id="ticket.id"
          className="ticket-bl"
          :title="ticket.get('recommendation')"
          :dev="
            ticket.get('developer') !== undefined
              ? ticket.get('developer').get('firstname')
              : ''
          "
          :score="ticket.get('score')"
          :devAvatar="
            ticket.get('developer') !== undefined
              ? ticket.get('developer').get('username')
              : ''
          "
          
               :avatar="ticket.get('developer') !== undefined ? ticket.get('developer').get('avatar'):''"
        />
      </div>
    </div>

    <!-- WORK IN PROGRESS -->
    <div
      class="col pt-3 border-end"
      :ondrop="drop"
      :ondragover="allowDrop"
      id="status-1"
    >
      <h5 class="pb-4 adapt__theme_lighter">In Progress</h5>
      <div
        v-for="ticket in $store.state.sprints.currentSprintTickets.filter(
          (ticket) => ticket.get('status') == 1
        )"
        :key="ticket.id"
      >
        <MyTicket
          :status="1"
          :sprint="ticket.get('sprint')"
          :roles="$store.getters['auth/roles']"
          :storyId="ticket.get('story').id"
          :isMasterDev="$store.getters['auth/roles'].length > 0"
          :id="ticket.id"
          className="ticket-wip"
          :title="ticket.get('recommendation')"
          :dev="ticket.get('developer').get('firstname')"
          :score="ticket.get('score')"
          :devAvatar="ticket.get('developer').get('username')"
           :avatar="ticket.get('developer').get('avatar')"
        />
      </div>
    </div>

    <!-- QUALITY ASSURANCE -->
    <div
      class="col pt-3 border-end"
      :ondrop="drop"
      :ondragover="allowDrop"
      id="status-2"
    >
      <h5 class="pb-4 adapt__theme_lighter">Quality Assurance</h5>
      <div
        v-for="ticket in $store.state.sprints.currentSprintTickets.filter(
          (ticket) => ticket.get('status') == 2
        )"
        :key="ticket.id"
      >
        <MyTicket
          :status="2"
          :sprint="ticket.get('sprint')"
          :storyId="ticket.get('story').id"
          :roles="$store.getters['auth/roles']"
          :isMasterDev="$store.getters['auth/roles'].length > 0"
          :id="ticket.id"
          className="ticket-qa"
          :title="ticket.get('recommendation')"
          :dev="ticket.get('developer').get('firstname')"
          :score="ticket.get('score')"
          :devAvatar="ticket.get('developer').get('username')"
           :avatar="ticket.get('developer').get('avatar')"
        />
      </div>
    </div>

    <!-- USER ACCEPTANCE TESTING -->
    <div
      class="col pt-3 border-end"
      :ondrop="drop"
      :ondragover="allowDrop"
      id="status-3"
    >
      <h5 class="pb-4 adapt__theme_lighter" title="User Acceptance Testing">
        UAT
      </h5>
      <div
        v-for="ticket in $store.state.sprints.currentSprintTickets.filter(
          (ticket) => ticket.get('status') == 3
        )"
        :key="ticket.id"
      >
        <MyTicket
          :status="3"
          :sprint="ticket.get('sprint')"
          :storyId="ticket.get('story').id"
          :roles="$store.getters['auth/roles']"
          :isMasterDev="$store.getters['auth/roles'].length > 0"
          :id="ticket.id"
          className="ticket-uat"
          :title="ticket.get('recommendation')"
          :dev="ticket.get('developer').get('firstname')"
          :score="ticket.get('score')"
          :devAvatar="ticket.get('developer').get('username')"
           :avatar="ticket.get('developer').get('avatar')"
        />
      </div>
    </div>

    <!-- RELEASE -->
    <div class="col pt-3" :ondrop="drop" :ondragover="allowDrop" id="status-4">
      <h5 class="pb-4 adapt__theme_lighter">Release</h5>
      <div
        v-for="ticket in $store.state.sprints.currentSprintTickets.filter(
          (ticket) => ticket.get('status') == 4
        )"
        :key="ticket.id"
      >
        <MyTicket
          :status="4"
          :sprint="ticket.get('sprint')"
          :storyId="ticket.get('story').id"
          :roles="$store.getters['auth/roles']"
          :isMasterDev="$store.getters['auth/roles'].length > 0"
          :id="ticket.id"
          className="ticket-release"
          :title="ticket.get('recommendation')"
          :dev="ticket.get('developer').get('firstname')"
          :score="ticket.get('score')"
          :devAvatar="ticket.get('developer').get('username')"
           :avatar="ticket.get('developer').get('avatar')"
        />
      </div>
    </div>
    <!-- END BOARD -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import MyTicket from "../user-stories/MyTicket.vue";
import { TicketStatusLabel } from "../../helpers/TicketStatusLabel";
export default {
  name: "SprintTickets",
  components: { MyTicket },
  data() {
    return {
      currentSprint: null,
    };
  },
  methods: {
    edit(current, field) {
      Swal.fire({
        title: `Edit sprint ${field}`,
        input: "text",
        inputValue: current,
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Look up",
        showLoaderOnConfirm: true,
        preConfirm: (value) => {
          return this.$store
            .dispatch("sprints/edit", {
              sprintId: this.currentSprint.id,
              column: field,
              value: value,
            })
            .then((_) => {
              return true;
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Success!", "", "success");
          this.currentSprint = null;
          this.getcurrentSprint();
        }
      });
    },
    deleteSprint() {
      if (this.currentSprint) {
        Swal.fire({
          title: "Delete Sprint?",
          text: "Are you sure you want to delete this sprint?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "blue",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("sprints/delete", { sprint: this.currentSprint })
              .then(() => {
                this.$router.go(-1); // back to perv page
                Swal.fire("Success!", "Sprint has been deleted", "success");
              });
          }
        });
      }
    },
    allowDrop(ev) {
      ev.preventDefault();
    },

    async drop(ev) {
      ev.preventDefault();

      let targetTicketId = this.$store.state.story.ticketToDrop;

      let targetTicket = this.$store.state.sprints.currentSprintTickets.filter(
        (el) => el.id == targetTicketId
      );

      if (targetTicket) {
        const targetTicketStatus = targetTicket[0].get("status");
        const targetColumnId = ev.target.id;
        const targetColumnStatus = targetColumnId.split("-")[1];

        if (
          parseInt(targetTicketStatus) < targetColumnStatus ||
          this.$store.getters["auth/roles"].indexOf("master") > -1
        ) {
          await this.$store.dispatch("sprints/promoteTicket", {
            storyId: targetTicket[0].get("story").id,
            status: ev.target.id,
            currentSprintId: this.currentSprint.id,
          });

          // send notification to ticket's story owner
          this.$store.dispatch("story/notifyOwner", {
            storyId: targetTicket[0].get("story").id,
            status: targetColumnStatus - 1,
            title: targetTicket[0].get("recommendation"),
          });
        }
      }
    },
    getcurrentSprint() {
      this.currentSprint = this.$store.getters["sprints/sprints"].filter(
        (element) => element.get("number") == this.$route.params.number
      )[0];
    },
    formatDate(from, to) {
      const _from = new Date(from);
      const _to = new Date(to);
      const months = [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      let _month = "";
      let days = "";

      let full = "";
      if (_from.getMonth() == _to.getMonth()) {
        _month = months[_from.getMonth()];
        days = _from.getDate() + " - " + _to.getDate();
        full = _month + " " + days + ", " + _from.getFullYear();
      } else {
        if (_from.getFullYear() == _to.getFullYear()) {
          full =
            months[_from.getMonth()] +
            " " +
            _from.getDate() +
            " - " +
            months[_to.getMonth()] +
            " " +
            _to.getDate() +
            ", " +
            _from.getFullYear();
        } else {
          full =
            months[_from.getMonth()] +
            " " +
            _from.getDate() +
            ", " +
            _from.getFullYear() +
            " - " +
            months[_to.getMonth()] +
            " " +
            _to.getDate() +
            ", " +
            _to.getFullYear();
        }
      }

      return full;
    },
  },
  async created() {
    await this.$store.dispatch("sprints/getSprints", { allSprint: false });
    this.getcurrentSprint();
    await this.$store.dispatch("sprints/getTicket", {
      sprintId: this.currentSprint.id,
    });
    await this.$store.dispatch("story/getDevs");
    await this.$store.dispatch("alltickets/getAllTickets");
  },
};
</script>

<style scoped>
div.col.pt-3 {
  background-color: hsla(0, 0%, 0%, 0.1);
}
</style>
