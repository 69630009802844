
import Vuex from 'vuex';
import auth from './modules/auth';
import board from './modules/board';
import notifications from './modules/notifications';
import story from './modules/story';
import sprints from './modules/sprint';
import alltickets from './modules/alltickets';
import global from './modules/global';


export default new Vuex.Store({
    modules: {
        auth,
        board,
        story,
        global,
        sprints,
        alltickets,
        notifications,
    }
});
