<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <form type="POST" @submit="submitTicket">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Create Ticket for:
              <span class="text-muted"
                >#{{ $store.state.story.selectedStoryId }}</span
              >
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="alert alert-success" v-if="message !== ''">
              <p>{{ message }}</p>
            </div>
            <div class="mb-3">
              <label for="recipient-name" class="col-form-label"
                ><span class="text-danger" title="This field is required"
                  >*</span
                >
                Recommendation / Ticket Description:
              </label>

              <textarea
                v-model="recommendation"
                class="form-control"
                rows="3"
                id="message-text"
                required
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="data-structure" class="col-form-label">
                Data Structure:
              </label>

              <textarea
                v-model="data"
                class="form-control"
                rows="3"
                id="data-structure"
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="file-structure" class="col-form-label">
                File Structure:
              </label>

              <textarea
                v-model="file"
                class="form-control"
                rows="3"
                id="file-structure"
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="file-structure" class="col-form-label">
                Remarks:
              </label>

              <textarea
                v-model="remarks"
                class="form-control"
                rows="3"
                id="remarks"
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="score" class="col-form-label">Score:</label>

              <div class="d-flex align-items-center justify-content-between">
                <div class="col">1d</div>
                <div class="col"><span class="ps-4">2d</span></div>
                <div class="col text-center">3d</div>
                <div class="col text-end">
                  <span class="pe-4">4d</span>
                </div>
                <div class="col text-end">5d</div>
              </div>
              <input
                v-model="score"
                type="range"
                class="form-range"
                min="1"
                max="5"
                id="score"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              :disabled="isButtonLoading"
              type="submit"
              class="btn btn-primary"
            >
              <i
                v-if="isButtonLoading"
                class="bx bx-spin bx-loader-alt align-middle text-light"
              ></i>
              Save Ticket
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "AddTicketModal",
  data() {
    return {
      recommendation: "",
      data: "",
      file: "",
      remarks: "",
      score: 1,
      isButtonLoading: false,
      message: "",
    };
  },

  async created() {
    // Get Developers
    await this.$store.dispatch("story/getDevs");
  },

  methods: {
    async submitTicket(e) {
      e.preventDefault();
      this.isButtonLoading = true;
      try {
        await this.$store.dispatch("story/createTicket", {
          score: this.score,
          dataStructure: this.data,
          fileStructure: this.file,
          remarks: this.remarks,
          recommendation: this.recommendation,
          storyId: this.$store.state.story.selectedStoryId,
        });
        this.isButtonLoading = false;
        this.recommendation = "";
        this.data = "";
        this.file = "";
        this.remarks = "";
        this.developer = "";

        this.message = "New Ticket has been created!";
        setTimeout(() => {
          this.message = "";
        }, 3000);
      } catch (error) {
        alert("An error occured, please try again later.\n\n " + error);
        Swal.fire("An error occured", error, "error");
      }
    },
  },
};
</script>

<style scoped>
</style>