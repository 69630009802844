<template>
  <div class="row py-3">
    <div class="row mt-3">
      <div class="col-lg-6"><h2 class="pb-5 page_title">User Stories</h2></div>
    </div>

    <div class="col-lg-12 pt-5" v-if="$store.state.story.allStories.length < 1">
      <div class="d-flex justify-content-center align-items-center">
        <h4 class="text-center adapt__theme_lighter mt-5">
          <i class="bx bxs-error align-middle"></i> No Stories Found
        </h4>
      </div>
    </div>

    <!-- start here -->
    <AddTicketModal />
    <div
      class="col-lg-4"
      v-for="story in $store.state.story.allStories"
      :key="story.id"
    >
      <AllStoryCard
        :story="story.get('story')"
        :storyId="story.id"
        :owner="
          story.get('creator').get('firstname') +
          ' ' +
          story.get('creator').get('lastname')
        "
        :ownerDept="story.get('creator').get('department')"
        :ownerId="story.get('creator').get('username')"
        :status="story.get('status')"
        :createdAt="story.get('createdAt')"
        :attachments="story.get('attachments')"
      />
    </div>
  </div>
</template>

<script>
import AllStoryCard from "../all-stories/Card.vue";
import AddTicketModal from "./AddTicketModal.vue";
export default {
  name: "AllStories",
  components: { AllStoryCard, AddTicketModal },
  data() {
    return {
      selectedStoryId: "",
    };
  },

  methods: {
    async getAllStories() {
      await this.$store.dispatch("story/allStories");
    },
  },
  async created() {
    await this.getAllStories();
    // Get Developers
    await this.$store.dispatch("story/getDevs");
  },
};
</script>

