<template>
  <div class="row py-3">
    <div class="row mt-3">
      <h2 class="text-capitalize page_title">All Tickets</h2>
    </div>
    <!-- content -->
    <div class="adapt__theme_bg_darker table-responsive shadow-sm p-3">
      <table class="table" id="allTickets">
        <thead>
          <tr>
            <th scope="col" style="width: 70px">#</th>
            <th scope="col">Recommendation</th>
            <th scope="col" style="width: 100px">Status</th>
            <th scope="col" style="width: 50px">Developer</th>
            <th scope="col">Approver</th>
            <th scope="col" style="width: 50px">Sprint</th>
            <th scope="col" style="width: 70px">Activity</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr>
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr> -->
          <tr
            class="adapt__theme_bg_darker"
            v-for="ticket in $store.state.alltickets.allTickets"
            :key="ticket.id"
            :set="(developer = ticket.get('developer'))"
          >
            <td>
              <small class="text-secondary">{{ ticket.id }}</small>
            </td>
            <td class="clickable">
              <router-link
                :to="{ name: 'TicketDetails', params: { id: ticket.id } }"
                >{{ ticket.get("recommendation") }}</router-link
              >
            </td>
            <td :set="(status = ticket.get('status'))">
              <small
                class="status"
                :class="
                  status == 0
                    ? 'status-0'
                    : status == 1
                    ? 'status-1'
                    : status == 2
                    ? 'status-2'
                    : status == 3
                    ? 'status-3'
                    : 'status-4'
                "
              >
                {{
                  status == 0
                    ? "Pending"
                    : status == 1
                    ? "In Progress"
                    : status == 2
                    ? "Quality Assurance"
                    : status == 3
                    ? "User Acceptance"
                    : "Released"
                }}
              </small>
            </td>
            <td class="text-capitalize">
              <span
                v-if="developer"
                class="d-flex align-items-center justify-content-center"
              >
                <img
                  width="40"
                  class="me-2 img-fluid rounded-pill border"
                  :src="developer.get('avatar')
                  "
                  alt="Dev"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="developer.get('firstname')"
                />
                <small class="d-none">{{
                  developer.get("firstname").toLowerCase()
                }}</small>
              </span>
              <small v-else class="text-info ps-4">-</small>
            </td>
            <td class="text-capitalize">
              <small>{{
                ticket.get("story").get("approver")["Fname"].toLowerCase() +
                " " +
                ticket.get("story").get("approver")["Lname"].toLowerCase()
              }}</small>
            </td>
            <td>
              <router-link
                v-if="ticket.get('sprint') !== undefined"
                :to="{
                  name: 'SprintTickets',
                  params: { number: ticket.get('sprint').get('number') },
                }"
                >#{{ ticket.get("sprint").get("number") }}</router-link
              >
              <small v-else class="text-info ps-3">-</small>
            </td>
            <td>
              <small class="text-muted">{{
                formatDate(ticket.get("updatedAt"))
              }}</small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- end content -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  methods: {
    formatDate(date) {
      const format = moment(new Date(date));
      const now = new Date();
      //this.formattedDate = format.format("MMMM d, YYYY h:mma");
      const duration = moment.duration(format.diff(now));
      return duration.humanize() + " ago";
    },
  },

  async created() {
    await this.$store.dispatch("alltickets/getAllTickets");
    let name = this.$store.state.auth.currentUser.get("firstname");
    $("#allTickets").DataTable({
      paging: true,
      pageLength: 10,
      sorting: false,
      bLengthChange: false,
      bFilter: true,
      dom: "Bfrtip",
      buttons: [
        {
          text: "All Tickets",
          action: function () {
            this.columns("").search("").draw();
          },
        },

        {
          text: "My Tickets",
          action: function (e, dt, node, config) {
            this.columns(3).search(name).draw();
          },
        },
      ],
    });

    $(".dt-button").addClass("btn btn-secondary me-2").removeClass("dt-button");
    $("#allTickets_filter label input").addClass("form-control");

    //dataTable.search("Kean Allen").draw();
  },
};
</script>

<style scoped>
.clickable > a {
  background-color: #c7e5fc;
  color: #0c4a79 !important;
  display: block;
  padding: 5px;
  box-shadow: 1px 2px 1px #bcdef8;
  border-bottom: 2px solid #bcdef8;
}
.clickable > a:hover {
  background-color: #bcdef8;
  box-shadow: 1px 1px 1px #7abdf0;
  border-bottom-color: #7abdf0 !important;
}
table {
  border-color: #fff !important;
  margin-bottom: 20px;
}
table th {
  border-bottom: 2px solid #00aced !important;
}
tbody tr {
  border-color: #ddd !important;
}
.status {
  background-color: grey;
  padding: 2px;
  border-radius: 5px;
  font-size: 0.8em;
}
.status-0 {
  background-color: rgb(252, 243, 207);
  color: rgb(241, 196, 15);
}
.status-1 {
  background-color: rgb(250, 219, 216);
  color: rgb(203, 67, 53);
}
.status-2 {
  background-color: rgb(250, 229, 211);
  color: rgb(186, 74, 0);
}
.status-3 {
  background-color: rgb(214, 234, 248);
  color: rgb(40, 116, 166);
}
.status-4 {
  background-color: rgb(212, 239, 223);
  color: rgb(30, 132, 73);
}
</style>