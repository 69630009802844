import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/home/Home.vue";
import NotFound from "../views/NotFound.vue";
import Login from "../views/login/Login.vue";
import Boards from "../views/boards/Boards.vue";
import BoardInvitations from "../views/boards/Invitations.vue";
import Register from "../views/register/Register.vue";
import AllStories from '../views/all-stories/AllStories.vue';
import MyStories from "../views/user-stories/MyStories.vue";
import StoryDetail from "../views/user-stories/StoryDetail.vue";
import Sprints from "../views/sprint/Sprints.vue";
import SprintTickets from "../views/sprint/SprintTickets.vue";
import Tickets from "../views/tickets/Tickets.vue";
import TicketDetails from "../views/tickets/TicketDetails.vue";
import { AuthGuard } from "../helpers/AuthGuard";


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: AuthGuard,

  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/boards",
    name: "Boards",
    component: Boards,
  },
  {
    path: "/login/:id",
    name: "LoginParam",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/sprints",
    name: "Sprints",
    component: Sprints,
    beforeEnter: AuthGuard
  },
  {
    path: "/sprints/:number",
    name: "SprintTickets",
    component: SprintTickets,
    beforeEnter: AuthGuard
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: Tickets,
    beforeEnter: AuthGuard
  },
  {
    path: "/tickets/:id",
    name: "TicketDetails",
    component: TicketDetails,
    beforeEnter: AuthGuard,
  },
  {
    path: "/register/:id",
    name: "RegisterParam",
    component: Register,
  },
  {
    path: "/about",
    name: "About",
    beforeEnter: AuthGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/user-stories",
    name: "AllStories",
    component: AllStories,
    beforeEnter: AuthGuard
  },
  {
    path: "/user-stories/:id",
    name: "AllStoriesDetail",
    component: StoryDetail,
    beforeEnter: AuthGuard
  },
  {
    path: "/my-stories",
    name: "UserStories",
    component: MyStories,
    beforeEnter: AuthGuard
  },
  {
    path: "/invitations",
    name: "BoardInvitations",
    component: BoardInvitations,
    beforeEnter: AuthGuard
  },
  {
    path: "/story/:id",
    name: "UserStoryDetail",
    component: StoryDetail,
    beforeEnter: AuthGuard
  },
  // catch 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
