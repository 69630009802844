<template>
  <div
    class="box p-3 d-flex align-items-center flex-column justify-content-center"
  >
    <h1>It’s more than work. It’s a way of working together.</h1>
    <p>
      Start with a "Task" board, stories and tickets. Customize and expand with
      more features as your teamwork grows. Manage projects, organize tasks, and
      build team spirit &mdash; all in one place.
    </p>
    <h3 class="py-5 text-muted">Your Boards</h3>
    <div class="w-100">
      <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
          <div class="col-lg-3">
            <button
              @click="create_board"
              class="
                btn btn-lg btn-outline-secondary
                border-2 border-dashed
                w-75
                p-5
              "
            >
              <i class="bx bx-plus align-middle bx-sm"></i> Create
            </button>
          </div>
          <div
            class="col-lg-3"
            v-for="board in $store.state.board.boards"
            :key="board.id"
          >
            <div
              class="card shadow-sm myboard"
              @click="activateBoard(board.id)"
            >
              <div class="card-header">
                <div
                  class="
                    myboard-avatar
                    bg-success
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <h4 class="text-light pt-2">
                    {{ board.get("name").substr(0, 1) }}
                  </h4>
                </div>
              </div>
              <div class="card-body">
                {{ board.get("name") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- invitations board-->
    <div
      class="d-flex align-items-center flex-column w-100"
      v-if="$store.state.board.invites.length > 0"
    >
      <h3 class="mt-5 text-muted">Invitations</h3>

      <div class="w-100 row d-flex align-items-center justify-content-center">
        <div
          class="col-lg-3"
          v-for="board in $store.state.board.invites"
          :key="board.id"
        >
          <div
            class="card shadow-sm myboard"
            @click="activateBoard(board.get('board').id, board.id)"
          >
            <div class="card-header">
              <div
                class="
                  myboard-avatar
                  bg-success
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <h4 class="text-light pt-2">
                  {{ board.get("board").get("name").substr(0, 1) }}
                </h4>
              </div>
            </div>
            <div class="card-body">
              {{ board.get("board").get("name") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->
  </div>
</template>

<script>
export default {
  async created() {
    await this.getBoards();
    await this.getInvitations();
  },
  methods: {
    async activateBoard(objectId, inviteId = "") {
      await this.$store.dispatch("board/setActive", { objectId: objectId });
      this.$router.push({ path: "/" });
      setTimeout(() => {
        if (inviteId.trim() !== "") {
          this.$store
            .dispatch("board/removeInvite", {
              inviteId: inviteId,
            })
            .then((_) => this.$router.go());
        } else {
          this.$router.go();
        }
      }, 200);
    },
    async getBoards() {
      await this.$store.dispatch("board/getAll", {
        objectId: this.$store.state.auth.currentUser.id,
        department: this.$store.state.auth.currentUser.get("department"),
      });
    },
    async getInvitations() {
      await this.$store.dispatch("board/getInvites", {
        userId: this.$store.state.auth.currentUser.get("username"),
      });
    },
  },
};
</script>

<style scoped>
.box {
  min-width: 100vw;
  min-height: 100vh;
}
.border-dashed {
  border-style: dashed;
}
div.myboard-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
div.myboard {
  cursor: pointer;
}
</style>