
import store from '../store/'
import router from "../router";


export function BoardGuard(boardId) {
    const activeBoard = store.state.auth.currentUser.get('activeBoard');

    if (boardId !== activeBoard) {
        router.push({ path: '/notfound' })
    }

}