import Parse from 'parse';

const notifications = {
    namespaced: true,
    state: {
        notifications: [],
    },
    mutations: {
        setNotifications: (state, payload) => state.notifications = payload.notifications,
        appendNotifications: (state, payload) => state.notifications.push(payload.notification),
    },
    getters: {},
    actions: {
        realtimeNotifications: async ({ commit }) => {
            const Notification = Parse.Object.extend('Notifications');
            let query = new Parse.Query(Notification);
            query.equalTo('viewed', false);
            query.equalTo('receiver', Parse.User.current());
            let subscription = await query.subscribe();

            subscription.on('open', () => {
                console.log('[NOTIFICATION RT]');
            });

            subscription.on('create', (object) => {
                commit('appendNotifications', { notification: object })
            });

            subscription.on('enter', (object) => {
                console.log('enter notification: ', object);
            });
        },
        fcm: async ({ _, rootState }, payload) => {

            let endpoint = rootState.global.endpoint
            let isProduction = rootState.global.production

            const _options = {
                method: "POST",
                headers: { "Access-Control-Allow-Origin": "*" },
                body: JSON.stringify({
                    userId: payload.userId,
                    title: payload.title,
                    body: payload.body,
                    type: 'taskboard',
                    url: payload.url,
                }),
            };
            fetch(isProduction ? "https://online.eternalgardens.ph/push-permission/send.php" : `${endpoint}/push-permission/send.php`,
                _options
            );
        },
        send: async ({ dispatch }, payload) => {
            // User Pointer
            let userPointer;
            let _userObjectId;
            let _userId = 0;
            if (payload.userObjectId !== null) {


                _userObjectId = payload.userObjectId;
            }


            if (payload.userId !== null && payload.userId !== undefined) {
                _userId = payload.userId;
                const User = Parse.Object.extend("_User");
                const uQuery = new Parse.Query(User);

                uQuery.equalTo('username', payload.userId);

                const res = await uQuery.find();
                _userObjectId = res[0].id;



            } else {
                const User = Parse.Object.extend("_User");
                const uQuery = new Parse.Query(User);

                uQuery.equalTo('objectId', _userObjectId);
                const res = await uQuery.find();
                _userId = res[0].get('username');
            }

            userPointer = { __type: 'Pointer', className: '_User', objectId: _userObjectId };



            const Notification = Parse.Object.extend("Notifications");
            const notification = new Notification();

            let acl = new Parse.ACL();

            acl.setPublicReadAccess(true);
            acl.setReadAccess(_userObjectId, true);
            acl.setWriteAccess(_userObjectId, true);
            acl.setRoleReadAccess('developer', true);


            notification.set('receiver', userPointer)
            notification.set('type', 'ticket-assignment')
            notification.set('message', payload.message)
            notification.set('data', payload.route)
            notification.set('viewed', false)
            notification.setACL(acl);

            await notification.save()

            await dispatch('fcm', { userId: _userId, title: 'Taskboard Notification', body: payload.message, url: payload.url ?? `${endpoint}/taskboard/#/tickets` });
            return true;

        },
        getAll: async ({ commit, dispatch }) => {
            const Notifications = Parse.Object.extend("Notifications");
            const query = new Parse.Query(Notifications);

            query.equalTo('viewed', false)
            query.equalTo('receiver', Parse.User.current())

            const result = await query.find();
            commit('setNotifications', { notifications: result })
            dispatch('realtimeNotifications')



        },
        seenNotification: async ({ dispatch }, payload) => {
            const Notifications = Parse.Object.extend("Notifications");
            const query = new Parse.Query(Notifications);

            query.equalTo('objectId', payload.notifId)

            const result = await query.find();
            if (result) {
                for (let i = 0; i < result.length; i++) {
                    result[i].set('viewed', true);
                    await result[i].save();
                    await dispatch('getAll');
                }
            }
        },
    },
};

export default notifications;