export function TicketStatusLabel(status) {
    let statusLabel = "";


    switch (parseInt(status)) {
        case 1:
            statusLabel = "Quality Assurance";
            break;
        case 2:
            statusLabel = "UAT";
            break;
        case 3:
            statusLabel = "Release";

            break;
    }
    return statusLabel;

}