<template>
  <img
    :title="$props.name"
    :src="$props.avatar"
   
    alt=""
    type="button"
    data-bs-toggle="dropdown"
    class="img-fluid rounded-circle dropdown-toggle"
  />
  <ul class="dropdown-menu p-0 m-0">
    <div class="p-2 p-0 d-flex align-items-end dropdown__header">
      <img :src="avatar" class="rounded-circle pop-over" alt="" />
      <h6 class="text-light pt-2 ps-1">{{ $props.name }}</h6>
    </div>
    <div class="card">
      <div class="card-body pt-4">
        <p>Hello</p>
      </div>
    </div>
  </ul>
</template>

<script>
export default {
  props: {
    userId: String,
    name: String,
    avatar: String,
  },


};
</script>

<style scoped>
img.dropdown-toggle {
  border: 1px solid hsla(0, 0%, 80%, 0.8);
}
img.pop-over {
  margin-bottom: -30px;
  z-index: 100;
  width: 70px;
}
.dropdown__header {
  background-color: hsla(0, 0%, 15%, 0.849);
}
</style>