<template>
  <div class="pt-2">
    <div class="row mt-3">
      <h2
        class="text-capitalize page_title"
        v-if="$store.state.auth.currentUser"
      >
        Board Invitations
      </h2>
    </div>

    <div class="row mt-5" v-if="$store.state.board.invites.length > 0">
      <div
        class="col-lg-3"
        v-for="invite in $store.state.board.invites"
        :key="invite.id"
      >
        <div class="card adapt__theme_bg_darker">
          <div class="card-body">
            <h5 class="card-title adapt__theme_darker">
              {{ invite.get("board").get("name") }}
            </h5>
            <p class="card-text adapt__theme_darker">
              <span class="text-capitalize">{{
                invite.get("invitedBy").get("firstname").toLowerCase()
              }}</span>
              invited you to join in this board.
            </p>
            <p class="card-text mb-2 my-1">
              <small class="adapt__theme_darker"
                ><i class="bx bx-time-five align-middle"></i>
                {{ formatDate(invite.get("createdAt")) }}</small
              >
            </p>

            <button
              class="btn btn-primary"
              @click="activateBoard(invite.get('board').id, invite.id)"
            >
              <span class="text-light"
                ><i class="bx bx-check-circle align-middle"></i> Accept</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
export default {
  async created() {
    await this.getInvitations();
  },
  methods: {
    async getInvitations() {
      await this.$store.dispatch("board/getInvites", {
        userId: this.$store.state.auth.currentUser.get("username"),
      });
    },
    formatDate(date) {
      const format = moment(new Date(date));
      const now = new Date();
      //this.formattedDate = format.format("MMMM d, YYYY h:mma");
      const duration = moment.duration(format.diff(now));
      return duration.humanize() + " ago";
    },
    async activateBoard(objectId, inviteId) {
      Swal.fire({
        title: "Join Board",
        text: "You are about to join in this board. Continue?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, join board!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("board/setActive", { objectId: objectId })
            .then((_) => {
              // remove from invitation
              this.$store
                .dispatch("board/removeInvite", {
                  inviteId: inviteId,
                })
                .then((_) => this.$router.go());
            });
        }
      });
    },
  },
};
</script>

<style>
</style>