<template>
  <!-- devAvatar is basically the employeeid -->
  <div
    :class="'card ticket border-bottom-2 p-2 mb-2 ' + className"
    :draggable="
      $store.getters['auth/roles'].indexOf('master') > -1 && $props.dev !== ''
    "
    :ondragstart="drag"
    :id="$props.id"
    @dblclick="$router.push(`/tickets/${$props.id}`)"
  >
    <!-- ONLY SHOW TICKET OPTIONS TO :
    1. If Developer or Master And score <= 0
    2. If Master
    -->

    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex w-100 align-items-center justify-content-between">
        <h6 class="text-info">
          {{
            $props.sprint ? "Sprint #" + $props.sprint.get("number") : "Not Set"
          }}
        </h6>
        <span>
          <router-link :to="'/tickets/' + $props.id"
            ><button class="btn text-primary">
              <i class="bx bx-link-external"></i></button
          ></router-link>
        </span>
      </div>

      <div
        class="text-end"
        v-if="
          $props.isMasterDev == true ||
          $store.getters['auth/roles'].indexOf('master') > -1 ||
          ($props.status == 3 &&
            $store.state.auth.currentUser.get('username') == $props.creatorId)
        "
      >
        <div class="btn-group dropdown">
          <button
            class="btn btn-sm dropdown-toggle dropdown-toggle-split"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bx bx-dots-vertical-rounded"></i>
          </button>
          <ul class="dropdown-menu shadow-lg">
            <li>
              <span class="dropdown-header">Actions</span>
              <div class="divider border-bottom"></div>
            </li>

            <!-- sprint -->
            <li
              class="withsubmenu"
              v-if="
                $store.getters['auth/roles'].length > 0 ||
                devAvatar == $store.state.auth.currentUser.get('username')
              "
            >
              <button
                class="
                  dropdown-item
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <span
                  ><i class="bx bx-pulse text-primary"></i>
                  {{
                    $props.sprint !== undefined &&
                    $props.sprint.get("number") > 0
                      ? "Move Sprint"
                      : "Set Sprint"
                  }}</span
                >
                <i
                  style="font-size: 10px"
                  class="bx text-muted bxs-right-arrow"
                ></i>
              </button>
              <ul v-if="$props.sprint == undefined">
                <li
                  v-for="sprint in $store.getters['sprints/sprints']"
                  :key="sprint.id"
                  @click="
                    setSprint(
                      sprint.id,
                      false,
                      $store.state.auth.roles.length == 1 &&
                        $store.state.auth.roles.indexOf('developer') > -1
                      /**
                       * 3rd param returns true or false
                       * true if current user is only developer, else false
                       */
                    )
                  "
                >
                  Sprint #{{ sprint.get("number") }}
                  <small class="text-muted align-middle"
                    >( {{ format_date(sprint.get("to"), "MMM DD, Y") }} )</small
                  >
                </li>
              </ul>
              <ul
                v-else-if="$store.getters['auth/roles'].indexOf('master') > -1"
              >
                <li
                  v-for="sprint in $store.getters['sprints/sprints'].filter(
                    (el) => el.get('number') !== $props.sprint.get('number')
                  )"
                  :key="sprint.id"
                  @click="setSprint(sprint.id, true)"
                >
                  Sprint #{{ sprint.get("number") }}
                  <small class="text-muted align-middle"
                    >( {{ format_date(sprint.get("to"), "MMM DD, Y") }} )</small
                  >
                </li>
              </ul>
              <ul v-else>
                <li
                  v-for="sprint in $store.getters['sprints/sprints'].filter(
                    (el) => el.get('number') > $props.sprint.get('number')
                  )"
                  :key="sprint.id"
                  @click="setSprint(sprint.id, true)"
                >
                  Sprint #{{ sprint.get("number") }}
                  <small class="text-muted align-middle"
                    >( {{ format_date(sprint.get("to"), "MMM DD, Y") }} )</small
                  >
                </li>
              </ul>
            </li>
            <!-- end sprint -->

            <li
              class="withsubmenu"
              v-if="
                $store.getters['auth/roles'].indexOf('master') > -1 &&
                $props.sprint != null
              "
            >
              <button
                class="
                  dropdown-item
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <span
                  ><i class="bx bx-user-plus text-primary"></i> Assign to</span
                >
                <i
                  style="font-size: 10px"
                  class="bx text-muted bxs-right-arrow"
                ></i>
              </button>
              <ul>
                <li
                  class="d-flex align-items-center"
                  @click="
                    ownTicket(
                      $store.state.auth.currentUser.id,
                      $props.id,
                      $props.storyId
                    )
                  "
                >
                  <img
                    :src="
                      $store.state.auth.currentUser.get('avatar')
                    "
                    width="25"
                    class="rounded-pill img-fluid border"
                    alt=""
                  />
                  <span class="ps-2 d-block">My Self</span>
                </li>
                <li
                  class="d-flex align-items-center"
                  v-for="dev in $store.getters['story/developers'].filter(
                    (el) =>
                      el.get('username') !==
                      $store.state.auth.currentUser.get('username')
                  )"
                  :key="dev.id"
                  @click="ownTicket(dev.id, $props.id, $props.storyId)"
                >
                  <img
                    :src="dev.get('avatar')"
                    width="25"
                    class="rounded-pill img-fluid border"
                    alt=""
                  />
                  <span class="ps-2 d-block">{{ dev.get("firstname") }}</span>
                </li>
              </ul>
            </li>
            <li v-else-if="$props.sprint != null && !dev">
              <button
                class="dropdown-item"
                @click="
                  ownTicket(
                    $store.state.auth.currentUser.id,
                    $props.id,
                    $props.storyId
                  )
                "
              >
                <i class="text-info bx fs-5 align-middle bxs-badge-check"></i>
                Own Ticket
              </button>
            </li>

            <!-- promote release -->
            <li
              v-if="
                (dev !== '' &&
                  $props.sprint != null &&
                  $props.score > 0 &&
                  devAvatar == $store.state.auth.currentUser.get('username') &&
                  parseInt($props.status) <= 1) ||
                ($store.getters['auth/roles'].indexOf('master') > -1 &&
                  $props.status !== 4 &&
                  dev !== '') ||
                ($store.getters['auth/roles'].indexOf('developer') > -1 &&
                  devAvatar !== $store.state.auth.currentUser.get('username') &&
                  parseInt($props.status) == 2) ||
                ($props.status == 3 &&
                  $store.state.auth.currentUser.get('username') ==
                    $props.creatorId)
              "
            >
              <button
                v-if="
                  (parseInt($props.status) <= 1 &&
                    devAvatar ==
                      $store.state.auth.currentUser.get('username')) ||
                  ($store.getters['auth/roles'].indexOf('developer') > -1 &&
                    devAvatar !==
                      $store.state.auth.currentUser.get('username')) ||
                  ($props.status == 3 &&
                    $store.state.auth.currentUser.get('username') ==
                      $props.creatorId)
                "
                class="dropdown-item"
                @click="promoteTicket('status-' + parseInt($props.status + 1))"
              >
                <i class="bx bx-trending-up fs-5 align-middle text-info"></i>
                Promote to
                <span class="text-primary">{{
                  $props.status == 0
                    ? "In Progress"
                    : getStatusLabel($props.status)
                }}</span>
              </button>
            </li>
            <!-- end promote release -->
            <li
              v-if="
                (dev && $props.sprint != null && $props.score <= 0) ||
                $store.getters['auth/roles'].indexOf('master') > -1
              "
            >
              <button
                class="dropdown-item"
                @click="setScore($props.id, $props.storyId)"
              >
                <i class="bx bx-timer fs-5 align-middle text-secondary"></i> Set
                Score
              </button>
            </li>

            <li v-if="$store.getters['auth/roles'].indexOf('master') > -1">
              <button
                @click="deleteTicket($props.id)"
                class="dropdown-item text-danger"
              >
                <i class="bx bx-trash-alt fs-5 align-middle text-danger"></i>
                Delete
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="ticket-cover"
      v-if="cover.trim() !== ''"
      :style="`background-image: url(${cover})`"
    ></div>
    <span class="ticket-recommendation"> {{ title }}</span>

    <div class="pt-2 d-flex justify-content-between">
      <small class="d-flex text-muted" :title="'Assigned to ' + dev">
        <img
          v-if="$props.dev !== ''"
          :src="$props.avatar"
          
          alt=""
          width="30"
          class="img rounded-pill border-1 border-light"
        />
        <span class="text-muted" v-else>Not Assigned</span>
        <span class="ps-2 d-none">{{ dev }}</span>
      </small>
      <!-- right bottom icons-->
      <div class="ticket-card-bottom-icons">
        <small class="text-muted pe-3">
          <i class="bx bx-list-check align-middle"></i>
          {{
            checklist.filter((el) => el.get("checked") == true).length +
            "/" +
            checklist.length
          }}</small
        >
        <small
          v-bind:title="
            score < 0
              ? 'This ticket was moved to current sprint'
              : 'Estimated Days of Development'
          "
          v-bind:class="score < 0 ? 'text-danger fw-bold' : 'text-muted'"
          ><i v-if="score >= 0" class="bx bx-time-five align-middle"></i
          >{{ score > 0 ? score + "d" : score + "" }}</small
        >
      </div>
      <!-- end right icons -->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { TicketStatusLabel } from "../../helpers/TicketStatusLabel";
export default {
  name: "MyTicket",
  props: {
    className: String,
    sprint: Object,
    dev: String,
    avatar: String,
    devAvatar: String,
    score: Number,
    title: String,
    id: String, // ticket id
    isMasterDev: Boolean,
    roles: Array,
    storyId: String,
    creatorId: String,
    status: Number,
  },
  data() {
    return {
      avatar: "",
      checklist: [],
      cover: "",
    };
  },
  methods: {
    async getCover() {
      this.cover = await this.$store.dispatch("alltickets/getTicketCover", {
        ticketId: this.$props.id,
      });
    },
    getStatusLabel(status) {
      return TicketStatusLabel(status);
    },
    async getChecklist() {
      this.checklist = await this.$store.dispatch("alltickets/checklist", {
        ticketId: this.$props.id,
      });
    },
    async promoteTicket(toStatus) {
      this.$store.commit("story/ticketToDrop", { value: this.$props.id });
      const payload = {
        storyId: this.$props.storyId,
        status: toStatus,
        currentSprintId: this.$props.sprint.id,
      };

      const status = parseInt(toStatus.split("-")[1]);

      // Get story details

      await this.$store.dispatch("story/allStories");
      const story = this.$store.state.story.allStories.filter(
        (element) => element.id == this.$props.storyId
      );

      const creatorId = story[0].get("creator").get("username");

      Swal.fire({
        title: TicketStatusLabel(status - 1),
        text: "Are you sure you want to promote this ticket? This action is irreversible.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#00aced",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("sprints/promoteTicket", payload)
            .then((_) => {
              Swal.fire(
                "Success!",
                "Ticket sprint has been moved to " +
                  TicketStatusLabel(status - 1),
                "success"
              );

              // send fcm notification to developer
              this.$store.dispatch("notifications/fcm", {
                userId: this.$props.devAvatar,
                title: "Ticket Promoted!",
                url: `${this.$store.state.global.endpoint}/taskboard/`,
                body: `Your ticket has been moved to ${TicketStatusLabel(
                  status - 1
                )}`,
              });
              // send fcm notification to story owner
              this.$store.dispatch("notifications/fcm", {
                userId: creatorId,
                title: `Ticket moved to ${TicketStatusLabel(status - 1)}`,
                url: `${this.$store.state.global.endpoint}/taskboard/`,
                body: this.$props.title,
              });
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        }
      });
    },

    async setSprint(sprintId, moveSprint, ownTicket = false) {
      Swal.fire({
        title: moveSprint ? "Move Sprint?" : "Set Sprint?",
        text: moveSprint
          ? "Are you sure you want to move this sprint?"
          : "Are you sure you want to set this sprint?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#00aced",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          if (moveSprint) {
            // enter reason before save
            Swal.fire({
              title: "Enter your reason",
              input: "text",
              inputAttributes: {
                autocapitalize: "off",
              },
              showCancelButton: true,
              confirmButtonText: "Move Sprint",
              showLoaderOnConfirm: true,
              preConfirm: (reason) => {
                return this.$store
                  .dispatch("story/setSprint", {
                    ticketId: this.$props.id,
                    sprintId: sprintId,
                    devId: this.$props.devAvatar,
                    storyId: this.$props.storyId,
                    moveSprint: moveSprint,
                    reason: reason, // for moving of sprint
                  })
                  .then((response) => true)
                  .catch((error) => {
                    Swal.showValidationMessage(`Request failed: ${error}`);
                  });
              },
              allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire(
                  "Success!",
                  "Ticket sprint has been moved",
                  "success"
                );
              }
            });
          } else {
            // just save
            this.$store
              .dispatch("story/setSprint", {
                ticketId: this.$props.id,
                sprintId: sprintId,
                devId: this.$props.devAvatar,
                storyId: this.$props.storyId,
                moveSprint: false,
                ownTicket: ownTicket, // to automatically own ticket when dev set sprint of ticket
              })
              .then(function () {
                Swal.fire(
                  "Success!",
                  "Sprint has been assigned to ticket",
                  "success"
                );
              });
          }
        }
      });
    },
    drag(ev) {
      //ev.dataTransfer.setData("Text", ev.target.id);

      console.log("drag id: ", ev.target.id);
      this.$store.commit("story/ticketToDrop", { value: ev.target.id });
      console.log("dragging");
      ev.target.classList.remove("bg-secondary");
    },
    async deleteTicket(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("story/deleteTicket", { ticketId: id });
          Swal.fire("Deleted!", "Ticket has been deleted.", "success");
        }
      });
    },
    async setScore(ticketId, storyId) {
      Swal.fire({
        title: "Set Score for this ticket",
        input: "number",
        inputAttributes: {
          min: "1",
          max: "5",
        },
        showCancelButton: true,
        confirmButtonText: "Set Score",
        showLoaderOnConfirm: true,
        preConfirm: async (score) => {
          return await this.$store
            .dispatch("story/scoreTicket", {
              score: score,
              ticketId: ticketId,
              storyId: storyId,
            })
            .catch((error) =>
              Swal.showValidationMessage(`Request failed: ${error}`)
            );
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Success!", "Ticket has been scored.", "success");
        }
      });
    },
    ownTicket(devId, ticketId, storyId) {
      const ownTicket = devId == this.$store.state.auth.currentUser.id;
      Swal.fire({
        title: ownTicket ? "Own This Ticket " : "Assign ticket",
        text: ownTicket
          ? "You are about to own this ticket. This will also move the ticket into In-Progress. "
          : "You are about to assign this ticket." +
            " Please confirm to continue.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#999",
        confirmButtonText: "I confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          if (
            this.$store.dispatch("story/ownTicket", {
              devId: devId,
              ticketId: ticketId,
              storyId: storyId,
            })
          ) {
            Swal.fire("Success!", "Ticket has been assigned", "success");
            // insert notification
            if (!ownTicket) {
              this.$store.dispatch("notifications/send", {
                userObjectId: devId,
                message: `Ticket #${ticketId} has been assigned to you.`,
                route: { route: `/tickets/${ticketId}` },
                url:
                  this.$store.state.global.endpoint +
                  "/taskboard/#/tickets/" +
                  ticketId,
              });
            }
          }
        }
      });
    },
  },
  async created() {
    await this.$store.dispatch("auth/getRole");
    await this.$store.dispatch("sprints/getSprints");
    await this.getChecklist();
    await this.getCover();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,400;1,500&display=swap");
.dropdown-toggle::after {
  display: none;
}
.withsubmenu ul {
  display: none;
}
.withsubmenu:hover ul {
  display: block !important;
}
.withsubmenu ul {
  list-style: none;

  background-color: white;
  position: absolute;
  left: 158px;
  top: 45px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 5px 5px #ddd;
  text-align: left;
  margin: 0 !important;
  padding: 0;
}
.withsubmenu ul li {
  padding: 5px;
  cursor: pointer;
  text-align: left;
  margin: 0 !important;
  min-width: 200px;
  max-width: auto !important;
}
.withsubmenu ul li:hover {
  background-color: #eee;
}
div.main__board ul.dropdown-menu {
  position: fixed !important;
  z-index: 10000;
}
.ticket-card-bottom-icons small {
  color: #888686 !important;
  font-weight: 300;
}
.ticket-card-bottom-icons small i.bx {
  vertical-align: middle;
  font-size: 14px;
}
.ticket-recommendation {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #222 !important;
  padding-bottom: 5px;
}
.ticket-cover {
  width: 100%;
  height: 120px;
  background-size: cover;
  background-position: center;
  margin-bottom: 5px;
  border: 1px solid #eee;
  border-radius: 2px;
}
</style>