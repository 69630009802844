<template>
  <div
    class="card shadow-sm border-0 my-4"
    v-if="$store.state.auth.roles.indexOf('developer') > -1"
  >
    <div class="card-body">
      <h5>
        <i class="bx bxs-folder-open text-success align-middle"></i>
        File Structure
        <!-- <hr class="dropdown-divider text-light" /> -->
      </h5>
      <pre class="mt-3">{{ $props.text }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileStructureCard",
  props: {
    text: String,
  },
};
</script>

<style>
</style>