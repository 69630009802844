import Parse from 'parse';
import router from '../../router';


const appId = "dBCLuWowbo";
const javascriptKey = "grR9McwAlMHxZtY5ZL1pj00D5eatNpp1";

Parse.initialize(appId, javascriptKey);
Parse.serverURL = "https://parse.eternalgardens.ph/parse";

const auth = {
    namespaced: true,

    state: {
        isLoggedIn: Parse.User.current() != null,
        loginError: "",
        currentUser: Parse.User.current(),
        preUser: {}, // use in registation data
        roles: [],
    },
    mutations: {
        setIsLoggedIn(state, payload) {
            state.isLoggedIn = payload.value;
            state.loginError = payload.error;
        },
        setCurrentUser(state, payload) {
            state.currentUser = payload.value;
        },
        preUser(state, payload) {
            state.preUser.firstname = payload.firstname;
            state.preUser.lastname = payload.lastname;
            state.preUser.department = payload.department;
            state.preUser.id = payload.id;
            state.preUser.sex = payload.sex;
        },
        getRole(state, payload) {
            state.roles = payload.roles;
        },
        loginError(state, payload) {
            state.loginError = payload.error;
        }

    },
    getters: {
        loginError: (state) => state.loginError,
        isLoggedIn: (state) => state.isLoggedIn,
        preUser: (state) => state.preUser,
        roles: (state) => state.roles,
    },
    actions: {
        currentUser: async ({ commit, state }) => {
            let user = Parse.User.current();
            state.currentUser = user;

            commit('setCurrentUser', { value: user });
        },

        getRole: async ({ commit, state }) => {
            if (state.isLoggedIn) {
                const query = await new Parse.Query(Parse.Role).equalTo('users', Parse.User.current()).find();
                let _roles = [];
                if (query) {
                    if (query.length > 0) {

                        for (let i = 0; i < query.length; i++) {
                            _roles.push(query[i].get('name'));

                        }
                        commit('getRole', { roles: _roles });
                    } else {
                        commit('getRole', { roles: [] });
                    }

                }
            }

        },
        handleParseError: ({ dispatch }, err) => {
            console.log('handleParseError', err);
            switch (err.err.code) {
                case Parse.Error.INVALID_SESSION_TOKEN || 209:
                    dispatch('signOut');
                    break;

                default:
                    break;
            }
        },
        signIn: async ({ commit, dispatch, rootState }, payload) => {
            try {
                const response = await Parse.User.logIn(
                    payload.userid,
                    payload.password
                );



                if (response != null && response.id != null) {
                    console.log('somehow success');
                    await dispatch('currentUser');
                    commit('setIsLoggedIn', { value: true, error: "" });
                    // Redirect to home
                    window.location.href = rootState.global.home;
                }
            } catch (error) {
                commit('setIsLoggedIn', { value: false, error: error.message.replace('username', 'employee id') });
            }
        },

        signOut: async ({ commit, rootState }, payload) => {
            await Parse.User.logOut();

            // close live queries
            Parse.LiveQuery.close();

            // Check if `stay_current_page` is set to true,
            // then do not redirect
            if (payload != null && payload.stay_current_page) {
                commit('setIsLoggedIn', { value: false, error: "Please try again." });
            }
            // Redirect to login page with no errors.
            else {
                commit('setIsLoggedIn', { value: false, error: "" });
                commit('setCurrentUser', { value: null });
                //router.push({ name: "Login" });
                window.location.href = rootState.global.home;
            }

        },
        signUp: async ({ commit, state, dispatch, rootState }, payload) => {
            const user = new Parse.User();
            const fname = state.preUser.firstname;
            const lname = state.preUser.lastname;
            user.set("firstname", fname);
            user.set("lastname", lname);
            user.set("fullname", `${fname} ${lname}`);
            user.set("department", state.preUser.department);
            user.set("username", state.preUser.id.toString());
            user.set("password", payload.password);
            user.set("gender", state.preUser.sex.substring(0, 1).toString());

            try {
                const response = await user.signUp();
                console.log("REGISTRATION: ", response);
                dispatch('currentUser');

                commit('setIsLoggedIn', { value: true, error: "" });
                window.location.href = rootState.global.home;
            } catch (error) {

                commit('setIsLoggedIn', { value: false, error: error.message.replace('username', 'employee id') });
                dispatch("handleParseError", { err: error.code });

            }
        },
        employeeDetails: async ({ commit, rootState }, payload) => {
            try {
                let endpoint = rootState.global.endpoint;
                const response = await fetch(
                    `${endpoint}/vue-storyboard-api/api?route=employee&id=${payload.id}`
                );
                const data = await response.json();
                if (response.status == 200) {
                    const fname = data.Fname.trim();
                    const lname = data.Lname.trim();
                    const department = data.Department.trim();
                    const sex = data.Sex.trim();

                    const obj = { firstname: fname, lastname: lname, department: department, id: payload.id, sex: sex };
                    console.log('data', obj);
                    commit('preUser', obj);

                }

            } catch (error) {
                console.log("Employee Fetch Error: ", error.message);
                commit('loginError', { error: error.message });
            }
        }
    }
}

export default auth;