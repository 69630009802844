<template>
  <div
    class="card shadow-sm border-0 my-4"
    v-if="$store.state.auth.roles.indexOf('developer') > -1"
  >
    <div class="card-body">
      <h5>
        <i class="bx bxs-data text-success align-middle"></i>
        Data Structure
        <!-- <hr class="dropdown-divider text-light" /> -->
      </h5>
      <pre class="mt-3">{{ $props.text }} </pre>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataStructureCard",
  props: {
    text: String,
  },
};
</script>

<style scoped>
pre::-webkit-scrollbar {
  height: 10px;
}

pre::-webkit-scrollbar-track {
  background: #091e4214;
  border-radius: 5px;
}

pre::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 5px;
}
</style>