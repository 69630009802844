<template>
  <div class="row py-3">
    <!-- main -->
    <div
      class="row mt-3"
      v-if="ticket !== null"
      :set="
        (myTicket =
          $store.state.auth.currentUser.get('username') == ticket.devId)
      "
    >
      <div class="col-lg-7 main__content pt-3 pe-4">
        <h2 class="text-capitalize text-light d-flex align-items-start">
          <button class="btn" @click="$router.go(-1)">
            <i class="bx bx-arrow-back"></i>
          </button>
          <span
            >{{ ticket.recommendation
            }}<small
              class="adapt__theme_light ps-2 text-muted fw-light"
              style="font-size: 15px"
              >({{
                ticket.status == 0
                  ? "Backlog"
                  : ticket.status == 1
                  ? "In Progress"
                  : ticket.status == 2
                  ? "Quality Assurance"
                  : ticket.status == 3
                  ? "User Acceptance Testing"
                  : "Released"
              }})</small
            ></span
          >
        </h2>
        <div class="col-lg-12">
          <div class="d-flex align-items-center">
            <img
              width="50"
              class="rounded-pill img-fluid me-3 align-middle border"
              :src="creatorAvatar"
              alt="creatorAvatar"
              
            />
            <div class="mt-3">
              <h6 class="adapt__theme_lighter">
                {{ ticket.storyCreatorName }}
              </h6>
              <div class="d-flex align-items-top justify-content-between">
                <p class="w-75 fw-light adapt__theme_lighter">
                  {{ ticket.story }}
                </p>
                <router-link
                  class="adapt__theme_lighter"
                  v-if="$store.state.auth.roles.indexOf('developer') > -1"
                  title="View Story"
                  :to="'/story/' + ticket.storyId"
                  ><i class="bx bx-link-external align-middle"></i>
                  <small>View Story</small>
                </router-link>
                <p></p>
                <p></p>
              </div>
            </div>
          </div>
        </div>

        <!-- Checlists-->
        <div class="col-lg-12 mt-5">
          <h5 class="adapt__theme_light">
            <i class="bx bx-list-check bx-sm align-middle"></i>
            Checklist
          </h5>

          <div
            v-if="$store.state.alltickets.checklist.length > 0"
            class="d-flex align-items-center mb-2"
            :set="
              (progressValue =
                (100 / $store.state.alltickets.checklist.length) *
                $store.state.alltickets.checklist.filter(
                  (el) => el.get('checked') == true
                ).length)
            "
          >
            <div class="d-block adapt__theme_lighter pe-2 small">
              {{ Math.round(progressValue) }}%
            </div>
            <div style="height: 10px" class="progress w-100">
              <div
                class="progress-bar"
                role="progressbar"
                :style="`width: ${progressValue}%;${
                  progressValue >= 100 ? 'background-color:#61bd4f' : ''
                }`"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <div
            class="
              form-check
              ms-2
              border-0
              list-group-item
              adapt__theme_bg_dark adapt__theme_lighter
              list-group-item-action
            "
            v-for="checklist in $store.state.alltickets.checklist"
            :key="checklist.id"
          >
            <input
              v-if="myTicket"
              class="form-check-input shadow"
              type="checkbox"
              :checked="checklist.get('checked')"
              value=""
              :id="checklist.id"
              @click="updateChecklist(checklist.id, !checklist.get('checked'))"
            />
            <label
              :class="
                checklist.get('checked')
                  ? 'text-decoration-line-through adapt__theme_dark'
                  : ''
              "
              class="
                form-check-label
                ps-2
                fw-light
                d-flex
                align-items-center
                justify-content-between
              "
              :for="checklist.id"
            >
              <span>{{ checklist.get("description") }}</span>
              <div class="dropdown" v-if="myTicket">
                <button
                  class="btn btn-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bx bx-dots-horizontal-rounded"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <button
                      class="dropdown-item"
                      @click="deleteChecklist(checklist.id)"
                    >
                      Delete
                    </button>
                  </li>
                </ul>
              </div>
            </label>
          </div>

          <textarea
            ref="checklistItem"
            v-model="checklistItem"
            v-if="showChecklistInput"
            placeholder="Add an item"
            rows="2"
            class="form-control w-75 mt-2"
          ></textarea>
          <button
            v-if="myTicket"
            @click="addChecklist()"
            class="btn btn-sm btn-outline-light mt-3"
          >
            {{ !showChecklistInput ? "Add an item" : "Add" }}
          </button>
          <button
            v-if="showChecklistInput"
            @click="showChecklistInput = false"
            class="mt-3 btn btn-sm text-danger"
          >
            <i class="bx bx-x bx-md align-middle"></i>
          </button>
        </div>
        <!-- End Checlists-->

        <!-- conversation -->
        <div class="col-lg-9 mt-3">
          <form
            @submit="postComment"
            v-if="
              members.some(
                (member) =>
                  member['_id'] ===
                  $store.state.auth.currentUser.get('username')
              )
            "
          >
            <!--
            <div class="mb-3 form-floating">
              <textarea
                ref="comment"
                placeholder="Write your comment..."
                name="compose"
                id="compose"
                rows="2"
                class="form-control"
                v-model="comment"
                @keyup="onChange"
              ></textarea>
              <label for="compose">Write your comment...</label>
            </div>
            -->
            <div class="mt-5 pt-5">
              <DiscordPicker
                @paste="getPastedImage"
                ref="comment"
                @keyup="onChange"
                :input="true"
                apiKey="undefined"
                :value="comment"
                @update:value="comment = $event"
                @emoji="setEmoji"
                placeholder="Type your comment..."
              />
            </div>

            <div
              class="col-lg-12 text-end"
              v-if="comment.trim() !== '' || pastedBlob !== null"
            >
              <button type="submit" class="btn btn-primary text-light d-none">
                Post Comment
              </button>
            </div>
          </form>

          <div class="w-100 mentions" v-if="mentions.length > 0">
            <ul>
              <li v-for="person in mentions" :key="person._id">
                <button
                  @click="mention(person._id, person.name)"
                  class="btn btn-block d-flex mb-1 w-100 text-start btn-light"
                >
                  <img
                    :src="person.avatar"
                    width="30"
                    class="rounded-pill"
                   
                  />
                  <span class="text-capitalize ps-2">{{
                    person.name.toLowerCase()
                  }}</span>
                </button>
              </li>
            </ul>
          </div>

          <canvas id="mycanvas" v-show="showCanvas" />
          <div v-if="pastedBlob !== null">
            <button class="btn btn-sm" @click="removeCanvas">
              <small>Remove</small>
            </button>
          </div>
          <!-- replies -->
          <h6 class="my-4 adapt__theme_lighter">
            Comments
            <span class="adapt__theme_light"
              >({{ $store.state.alltickets.ticketConversation.length }})</span
            >
          </h6>
          <div v-if="$store.state.alltickets.ticketConversation">
            <div
              v-for="(reply, index) in $store.state.alltickets
                .ticketConversation"
              :key="reply.id"
            >
              <Comment
                :index="index"
              
                :image="reply.get('image')"
                :date="reply.get('createdAt')"
                :message="textToLink(reply.get('message'))"
                :sender="reply.get('sender')"
                :commentId="reply.id"
              />
            </div>
          </div>
          <h6 v-else>No conversations found</h6>

          <!-- end replies -->
        </div>
        <!-- end conversation -->
      </div>

      <div class="col-lg-4 offset-lg-1">
        <div class="row">
          <div class="col" v-if="ticket && members.length > 0">
            <h6 class="adapt__theme_lighter">Members</h6>
            <div class="d-flex flex-wrap w-50">
              <div v-for="(member, index) in members" :key="index">
                <div class="d-flex">
                  <img
                    :src="member.avatar"
                    alt=""
                    :class="'rounded-pill shadow-sm m-2'"
                    width="40"
                   
                  />
                  <small
                    v-show="$store.state.auth.roles.indexOf('master') > -1"
                    @click="unInvite(member['_id'])"
                    v-if="member.invited"
                    class="close"
                    ><i
                      class="
                        bx bx-x
                        bg-light
                        shadow-sm
                        border-1
                        text-danger
                        rounded-pill
                      "
                    ></i
                  ></small>
                </div>
              </div>

              <button
                v-if="
                  $store.state.auth.roles.indexOf('master') > -1 ||
                  (members.some(
                    (member) =>
                      member['_id'] ==
                      $store.state.auth.currentUser.get('username')
                  ) &&
                    $store.state.auth.roles.indexOf('developer') > -1)
                "
                class="btn btn-lg"
                @click="showSearch = !showSearch"
              >
                +
              </button>

              <SimpleTypeahead
                id="newMember"
                v-show="showSearch"
                v-model="newMember"
                class="mt-3"
                placeholder="Search User..."
                :items="$store.getters['alltickets/memberSearchResult']"
                :minInputLength="1"
                @selectItem="selectItemEventHandler"
                @onInput="onInputEventHandler"
              />
            </div>
          </div>
        </div>

        <!-- aa-->

        <TicketDetailsAttachments
          :ticket="ticket"
          :devId="ticket.devId"
          :getFiles="getFiles"
          :formatDate="formatDate"
        />

        <!-- Other Story Details --->
        <div class="row">
          <div class="col">
            <!-- Acceptance Criteria -->
            <AcceptanceCriteriaCard :ticket="ticket" />
            <!-- Data Structure -->
            <DataStructureCard :text="ticket?.dataStructure" />
            <!-- File Structure -->
            <FileStructureCard :text="ticket?.fileStructure" />
          </div>
        </div>
        <!-- End Other Story Details --->
      </div>
    </div>
    <!-- end main -->
    <h4 v-else>Please wait...</h4>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import SimpleTypeahead from "vue3-simple-typeahead";
import DiscordPicker from "vue3-discordpicker";
import moment from "moment";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
import Comment from "./Comment.vue";
import FileStructureCard from "../components/FileStructureCard.vue";
import DataStructureCard from "../components/DataStructureCard.vue";
import AcceptanceCriteriaCard from "../components/AcceptanceCriteriaCard.vue";
import linkifyStr from "linkify-string";
import TicketDetailsAttachments from "../components/TicketDetailsAttachments.vue";
import { BoardGuard } from "../../helpers/BoardGuard";
export default {
  components: {
    Comment,
    SimpleTypeahead,
    FileStructureCard,
    DataStructureCard,
    DiscordPicker,
    AcceptanceCriteriaCard,
    TicketDetailsAttachments,
  },
  data() {
    return {
      ticket: null,
      creatorAvatar: "",
      comment: "",
      attachments: [],
      members: [],
      mentions: [],
      mentioned: [], // list of members who were mentioned
      finalMentioned: [], // this is the final/filtered people in mentioned
      showSearch: false,
      newMember: "",
      checklistItem: "",
      showChecklistInput: false,
      linkLabel: "",
      linkURL: "",
      pastedBlob: null,
      showCanvas: false,
    };
  },
  methods: {
    removeCanvas() {
      var canvas = document.getElementById("mycanvas");
      const context = canvas.getContext("2d");
      this.showCanvas = false;
      this.pastedBlob = null;
      context.clearRect(0, 0, canvas.width, canvas.height);
    },
    getPastedImage(e) {
      // Handle the event
      this.retrieveImageFromClipboardAsBlob(e, function (imageBlob) {
        // If there's an image, display it in the canvas
        if (imageBlob) {
          var canvas = document.getElementById("mycanvas");
          var ctx = canvas.getContext("2d");

          // Create an image to render the blob on the canvas
          var img = new Image();

          // Once the image loads, render the img on the canvas
          img.onload = function () {
            // Update dimensions of the canvas with the dimensions of the image
            canvas.width = this.width;
            canvas.height = this.height;

            // Draw the image
            ctx.drawImage(img, 0, 0);
          };

          // Crossbrowser support for URL
          var URLObj = window.URL || window.webkitURL;

          // Creates a DOMString containing a URL representing the object given in the parameter
          // namely the original Blob
          img.src = URLObj.createObjectURL(imageBlob);
        }
      });
    },
    retrieveImageFromClipboardAsBlob(pasteEvent, callback) {
      if (pasteEvent.clipboardData == false) {
        this.showCanvas = false;
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      this.showCanvas = true;

      var items = pasteEvent.clipboardData.items;

      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();
        this.pastedBlob = blob;

        if (typeof callback == "function") {
          callback(blob);
        }
      }
    },
    formatDate(date) {
      const format = moment(new Date(date));
      const now = new Date();
      //this.formattedDate = format.format("MMMM d, YYYY h:mma");
      const duration = moment.duration(format.diff(now));
      return duration.humanize() + " ago";
    },

    async getFiles() {
      await this.$store.dispatch("alltickets/getFiles", {
        ticketId: this.ticket.id,
      });
    },

    async updateChecklist(objectId, value) {
      await this.$store.dispatch("alltickets/triggerChecklist", {
        id: objectId,
        value: value,
      });
      // get checklist
      await this.$store.dispatch("alltickets/getChecklist", {
        ticketId: this.ticket.id,
      });
    },
    async deleteChecklist(objectId) {
      await this.$store.dispatch("alltickets/deleteChecklist", {
        id: objectId,
      });
    },
    async addChecklist() {
      if (this.showChecklistInput == true) {
        if (this.checklistItem.trim() == "") {
          this.$refs.checklistItem.focus();
        } else {
          const payload = {
            description: this.checklistItem,
            ticketId: this.ticket.id,
            userId: this.$store.state.auth.currentUser.id,
          };

          await this.$store.dispatch("alltickets/addChecklist", payload);
          await this.$store.dispatch("alltickets/getChecklist", {
            ticketId: this.ticket.id,
          });
          this.checklistItem = "";
        }
      }
      this.showChecklistInput = true;
    },
    setEmoji(emoji) {
      $(".vue3-discord-emojipicker__input").focus();
      $(".vue3-discord-emojipicker__input").prop(
        "placeholder",
        "Type your comment..."
      );
    },
    async unInvite(id) {
      Swal.fire({
        title: "Remove member?",
        text: `You are about to remove this member. Continue?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("alltickets/unInviteMember", {
              objectId: this.ticket.id,
              userId: id,
            })
            .then((_) => {
              Swal.fire("Removed!", "Suucessfully removed member", "success");
              this.$router.go();
            });
        }
      });
    },
    selectItemEventHandler(value) {
      let _targetUserId = value.split(":")[0].trim();
      let _targetUserName = value.split(":")[1].trim();

      // only members can add user
      if (
        this.$store.state.auth.roles.indexOf("master") < 0 &&
        this.$store.state.auth.roles.indexOf("developer") < 0
      ) {
        alert("You can not add use. Only devs can!");
      } else {
        // this person is a dev.
        // let's check if this person is invited

        if (
          !this.members.some(
            (member) =>
              member["_id"] ==
              this.$store.state.auth.currentUser.get("username")
          ) &&
          this.$store.state.auth.roles.indexOf("master") < 0
        ) {
          Swal.fire(
            "Not allowed!",
            `Only developer in this ticket can add user`,
            "warning"
          );

          return;
        }

        if (this.members.some((member) => member["_id"] == _targetUserId)) {
          Swal.fire(
            "User already exists",
            `${_targetUserName} is already a member in this ticket!`,
            "warning"
          );
        } else {
          Swal.fire({
            title: "Add new member?",
            text: `You are about to add "${_targetUserName}" is this ticket. Continue?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, add it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$store
                .dispatch("alltickets/addTicketMember", {
                  userId: _targetUserId,
                  objectId: this.ticket.id,
                })
                .then((_) => {
                  this.$store
                    .dispatch("notifications/send", {
                      userId: _targetUserId,
                      message: "You have been added to a ticket discussion",
                      route: { route: `/tickets/${this.ticket.id}` },
                      url:
                        this.$store.state.global.endpoint +
                        "/taskboard/#/tickets/" +
                        this.ticket.id,
                    })
                    .then((_) => {
                      Swal.fire(
                        "Added!",
                        "New member has been added",
                        "success"
                      );
                      this.$router.go();
                    });
                });
            }
          });
        }
      }
    },

    async onInputEventHandler(val) {
      await this.$store.dispatch("alltickets/memberSearchResult", {
        value: val.input,
      });
    },

    mention(userId, newName) {
      if (this.mentioned.indexOf(userId) < 0) {
        this.mentioned.push(userId);
        this.mentions = [];
      }

      // get mention
      let indexOfMention = this.comment.indexOf("@");

      let tperson = this.comment.substring(indexOfMention).split(" ")[0];

      this.comment = this.comment.replace(
        tperson,
        "[" + newName.split(" ")[0].toLowerCase() + "]"
      );
      this.mentions = [];

      this.checkMatches();
      $(".vue3-discord-emojipicker__input").focus();
    },
    onChange(e) {
      if (this.comment.trim() == "") {
        this.mentioned = [];
      }
      // get mention
      let indexOfMention = this.comment.indexOf("@");
      if (indexOfMention > -1) {
        let tperson = this.comment.substring(indexOfMention).split(" ")[0];
        let person = tperson.replaceAll("@", "").trim();

        let result = this.members.filter(
          (member) =>
            member.name.toLowerCase().includes(person.toLowerCase()) &&
            this.mentioned.indexOf(member._id) < 0 &&
            parseInt(member._id) !==
              parseInt(this.$store.state.auth.currentUser.get("username"))
        );

        this.mentions = result;
      } else {
        this.mentions = [];
      }
      this.checkMatches();
    },
    checkMatches() {
      // check for mentioned name and filter it!
      let matches = this.comment.match(/(?<=\[)[^\][]*(?=])/g);
      if (matches) {
        var filtered = this.members.filter(
          (member) =>
            matches.indexOf(member.name.split(" ")[0].toLowerCase()) > -1
        );

        this.finalMentioned = filtered;
      }
    },
    async populateMembers() {
      let creator = {
        _id: this.ticket.storyCreatorId,
        name: this.ticket.storyCreatorName,
        avatar: this.creatorAvatar,
        userObjectId: this.ticket.storyCreatorObjectId,
        invited: false,
      };

  const devatar = await this.$store.dispatch('global/getAvatar', {empId: this.ticket.devId});

      let developer = {
        _id: this.ticket.devId,
        name: this.ticket.devFname,
        avatar: devatar,
        userObjectId: this.ticket.devObjectId,
        invited: false,
      };

      let _members = await this.$store.dispatch("alltickets/invitedMembers", {
        objectId: this.ticket.id,
      });

      for (let i = 0; i < _members.length; i++) {

        const memvatar = await this.$store.dispatch('global/getAvatar', {empId: _members[i].get("username")});

        let _invited = {
          _id: _members[i].get("username"),
          name: _members[i].get("fullname"),
          avatar: memvatar,
          userObjectId: _members[i].id,
          invited: true,
        };
        this.members.push(_invited);
      }

      // let approver = {
      //   _id: this.ticket.storyApprover.EmpID,
      //   name: this.ticket.storyApprover.Fname,
      //   avatar:
      //     "//172.28.1.2/photo/" + this.ticket.storyApprover.EmpID + ".png",
      // };

      this.members.push(creator);
      if (developer["_id"] !== null && creator._id !== developer._id ) {
        this.members.push(developer);
      }
      //this.members.push(approver);
      this.members.filter((a,b)=>a._id!==b._id);
      console.log(this.members);
    },
    async postComment(e) {
      e.preventDefault();

      var base64 = "";
      if (this.pastedBlob !== null) {
        var canvas = document.getElementById("mycanvas");

        var url = canvas.toDataURL("image/png");
        base64 = url.split("base64,")[1];
      }

      if (
        (this.comment.length > 0 || this.pastedBlob !== null) &&
        this.members.some(
          (member) =>
            member["_id"] === this.$store.state.auth.currentUser.get("username")
        )
      ) {
        const response = await this.$store.dispatch("alltickets/postComment", {
          message: this.comment.trim(),
          ticketId: this.ticket.id,
          attachments: this.attachments,
          mentions: this.finalMentioned,
          imageB64: base64,
        });

        this.removeCanvas();

        if (response) {
          this.comment = "";
        }
      }
    },
    textToLink(text) {
      const options = {
        target: "_blank",
        format: (value, type) => {
          var output = value;
          if (type == "url" && value.includes("/#/")) {
            output = value.split("/#/")[1];
          } else {
            output = value.slice(0, 30) + "...";
          }
          return output;
        },
      };
      return linkifyStr(text, options);
    },
  },
  async created() {
    await this.$store.dispatch("alltickets/getSingleTicket", {
      ticketId: this.$route.params.id,
    });

    this.ticket = await this.$store.state.alltickets.singleTicket;
    const grabatar = await this.$store.dispatch('global/getAvatar', {empId: this.ticket.storyCreatorId});
    
    this.creatorAvatar = grabatar;
    await this.$store.dispatch("alltickets/getAllTicketConversation", {
      ticketId: this.ticket.id,
    });

    await this.populateMembers();

    // realtime convo
    await this.$store.dispatch("alltickets/realtimeTicketConversation", {
      ticketId: this.$route.params.id,
    });

    // get checklist
    await this.$store.dispatch("alltickets/getChecklist", {
      ticketId: this.ticket.id,
    });
    // get attachments
    await this.getFiles();

    // board BoardGuard
    BoardGuard(this.ticket.boardId);

    /**
     * Adjust emoji picker styles using jquery
     */
    $(".vue3-discord-emojipicker__gifbutton").addClass("d-none");
    $(".emojibutton__active .vue3-discord-emojipicker__emojibutton").css(
      "width",
      "22px"
    );
    $(".vue3-emojipicker > div > div.bg-grey-400").css(
      "background-color",
      "hsla(0, 0%, 100%, 0.2)"
    );
    $(".vue3-discord-emojipicker__input").removeClass("text-white");
    $(".vue3-discord-emojipicker__input").removeClass("placeholder-grey-200");
  },
  async beforeRouteLeave(to, from, next) {
    await this.$store.dispatch("alltickets/unsubscribeLiveQuery");
    next();
  },
};
</script>

<style scoped>
.dropdown-toggle:after {
  content: none;
}
.mentions {
  background-color: #ffffff;
  box-shadow: 0px 2px 2px #ddd;
  position: relative;
  top: -5px !important;
}
.mentions ul {
  list-style-type: none;
  margin: 0;
  padding: 4px;
}
#newMember {
  border: 1px solid #ddd !important;
}
small.close {
  margin-left: -15px;
  margin-top: 5px;
  cursor: pointer;
}
.hehe {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
.vue3-emojipicker > div > div.bg-grey-400 {
  background-color: red !important;
}
canvas {
  width: 100px;
  margin-top: 10px;
  box-shadow: 0px 1px 10px rgba(161, 156, 156, 0.16);
}
</style>