<template>
  <div class="pt-2">
    <div class="row mt-3">
      <h2
        class="text-capitalize page_title"
        v-if="$store.state.auth.currentUser"
      >
        Taskboard
      </h2>
    </div>

    <!-- task board -->
    <div class="main__board d-flex align-items-start justify-content-between">
      <!-- BOARD -->
      <div class="card card-bg m-3 ms-0 me-0" id="status-0">
        <h5 class="p-2 m-0">Backlog</h5>

        <div
          class="taskcard-container d-flex flex-column align-items-center p-2"
        >
          <MyTicket
            v-for="ticket in $store.state.alltickets.allTickets.filter(
              (ticket) => ticket.get('status') == 0
            )"
            class="border-bottom border-2 border-top-0 border-end-0 mb-2"
            :key="ticket.id"
            :status="0"
            :sprint="ticket.get('sprint')"
            :roles="$store.getters['auth/roles']"
            :storyId="ticket.get('story').id"
            :isMasterDev="$store.getters['auth/roles'].length > 0"
            :id="ticket.id"
            className="ticket-bl"
            :title="ticket.get('recommendation')"
            :dev="
              ticket.get('developer') !== undefined
                ? ticket.get('developer').get('firstname')
                : ''
            "
            :score="ticket.get('score')"
            
            :devAvatar="
              ticket.get('developer') !== undefined
                ? ticket.get('developer').get('username')
                : ''
            "
               :avatar="ticket.get('developer') !== undefined ? ticket.get('developer').get('avatar'):''"
          />
        </div>
      </div>

      <!-- WORK IN PROGRESS -->
      <div
        class="card m-3 card-bg"
        data-unique="1"
        :ondrop="drop"
        :ondragover="allowDrop"
        id="status-1"
        :set="
          (storeTicket =
            $store.state.auth.roles.indexOf('master') > -1
              ? $store.state.alltickets.allTickets.filter(
                  (ticket) => ticket.get('status') == 1
                )
              : $store.state.sprints.currentSprintTickets.filter(
                  (ticket) =>
                    ticket.get('status') == 1 &&
                    isBeetweenDate(
                      ticket.get('sprint').get('from'),
                      ticket.get('sprint').get('to')
                    )
                ))
        "
      >
        <h5 class="p-2 m-0">In Progress</h5>
        <div
          class="taskcard-container d-flex flex-column align-items-center p-2"
        >
          <div
            id="status-1"
            class="bg-light p-5 w-100"
            v-if="
              $store.state.alltickets.allTickets.filter(
                (ticket) => ticket.get('status') == 1
              ).length <= 0
            "
          ></div>

          <MyTicket
            class="border-bottom border-2 border-top-0 border-end-0 mb-2"
            v-for="ticket in storeTicket"
            :key="ticket.id"
            :status="1"
            :sprint="ticket.get('sprint')"
            :roles="$store.getters['auth/roles']"
            :storyId="ticket.get('story').id"
            :isMasterDev="$store.getters['auth/roles'].length > 0"
            :id="ticket.id"
            className="ticket-wip"
            :title="ticket.get('recommendation')"
            :dev="ticket.get('developer').get('firstname')"
            :score="ticket.get('score')"
            :devAvatar="ticket.get('developer').get('username')"
             :avatar="ticket.get('developer').get('avatar')"
          />
        </div>
      </div>

      <!-- QUALITY ASSURANCE -->
      <div
        class="card m-3 card-bg"
        data-unique="2"
        :ondrop="drop"
        :ondragover="allowDrop"
        id="status-2"
      >
        <h5 class="p-2 m-0">Quality Assurance</h5>
        <div
          class="taskcard-container d-flex flex-column align-items-center p-2"
        >
          <div
            id="status-2"
            class="bg-light p-5 w-100"
            v-if="
              $store.state.alltickets.allTickets.filter(
                (ticket) => ticket.get('status') == 2
              ).length <= 0
            "
          ></div>
          <MyTicket
            v-for="ticket in $store.state.alltickets.allTickets.filter(
              (ticket) => ticket.get('status') == 2
            )"
            class="border-bottom border-2 border-top-0 border-end-0 mb-2"
            :key="ticket.id"
            :status="2"
            :sprint="ticket.get('sprint')"
            :storyId="ticket.get('story').id"
            :roles="$store.getters['auth/roles']"
            :isMasterDev="$store.getters['auth/roles'].length > 0"
            :id="ticket.id"
            className="ticket-qa"
            :title="ticket.get('recommendation')"
            :dev="ticket.get('developer').get('firstname')"
            :score="ticket.get('score')"
            :devAvatar="ticket.get('developer').get('username')"
             :avatar="ticket.get('developer').get('avatar')"
          />
        </div>
      </div>

      <!-- USER ACCEPTANCE TESTING -->
      <div
        class="card m-3 card-bg"
        data-unique="3"
        :ondrop="drop"
        :ondragover="allowDrop"
        id="status-3"
      >
        <h5 class="p-2 m-0" title="User Acceptance Testing">UAT</h5>
        <div
          class="taskcard-container d-flex flex-column align-items-center p-2"
        >
          <div
            id="status-3"
            class="bg-light p-5 w-100"
            v-if="
              $store.state.alltickets.allTickets.filter(
                (ticket) => ticket.get('status') == 3
              ).length <= 0
            "
          ></div>
          <MyTicket
            v-for="ticket in $store.state.alltickets.allTickets.filter(
              (ticket) => ticket.get('status') == 3
            )"
            class="border-bottom border-2 border-top-0 border-end-0 mb-2"
            :key="ticket.id"
            :status="3"
            :sprint="ticket.get('sprint')"
            :storyId="ticket.get('story').id"
            :roles="$store.getters['auth/roles']"
            :isMasterDev="$store.getters['auth/roles'].length > 0"
            :id="ticket.id"
            className="ticket-uat"
            :title="ticket.get('recommendation')"
            :dev="ticket.get('developer').get('firstname')"
            :score="ticket.get('score')"
            :devAvatar="ticket.get('developer').get('username')"
             :avatar="ticket.get('developer').get('avatar')"
          />
        </div>
      </div>

      <!-- RELEASE -->
      <div
        class="card m-3 card-bg"
        data-unique="4"
        :ondrop="drop"
        :ondragover="allowDrop"
        id="status-4"
      >
        <h5 class="p-2 m-0">Release</h5>
        <div
          class="taskcard-container d-flex flex-column align-items-center p-2"
        >
          <div
            id="status-4"
            class="bg-light p-5 w-100"
            v-if="
              $store.state.alltickets.allTickets.filter(
                (ticket) => ticket.get('status') == 4
              ).length <= 0
            "
          ></div>
          <MyTicket
            v-for="ticket in $store.state.alltickets.allTickets.filter(
              (ticket) => ticket.get('status') == 4
            )"
            class="border-bottom border-2 border-top-0 border-end-0 mb-2"
            :key="ticket.id"
            :status="4"
            :sprint="ticket.get('sprint')"
            :storyId="ticket.get('story').id"
            :roles="$store.getters['auth/roles']"
            :isMasterDev="$store.getters['auth/roles'].length > 0"
            :id="ticket.id"
            className="ticket-release"
            :title="ticket.get('recommendation')"
            :dev="ticket.get('developer').get('firstname')"
            :score="ticket.get('score')"
            :devAvatar="ticket.get('developer').get('username')"
             :avatar="ticket.get('developer').get('avatar')"
          />
        </div>
      </div>

      <!-- END BOARD -->
    </div>

    <!-- end taskboard -->
  </div>
</template>

<script>
import MyTicket from "../user-stories/MyTicket.vue";
import moment from "moment";
export default {
  name: "Home",
  components: { MyTicket },
  data() {
    return {
      isLoggedIn: false,
      user: Object,
    };
  },
  methods: {
    isBeetweenDate(from, to) {
      return (
        moment(moment(new Date()).format("MM/DD/YYYY")).isBetween(
          moment(from).format("MM/DD/YYYY"),
          moment(to).format("MM/DD/YYYY")
        ) ||
        moment(moment(new Date()).format("MM/DD/YYYY")).isSameOrAfter(
          moment(from).format("MM/DD/YYYY")
        )
      );
    },
    allowDrop(ev) {
      ev.preventDefault();

      const paths = ev.path;
      let index = -1;

      for (let i = 0; i < paths.length - 2; i++) {
        let nodes =
          paths[i].classList.length > 0 ? Array.from(paths[i].classList) : [];
        if (nodes.length > 0) {
          if (nodes.indexOf("main__board") > -1) {
            index = i;
          }
        }
      }

      let targetStatus = paths[index - 1].id;
      console.log(targetStatus);
      const status = parseInt(targetStatus.split("-")[1]);

      $(`div#status-${status - 1} .__faker`).remove();
      //$(`.__faker`).remove();

      if ($(`div#${targetStatus} .__faker`).length <= 0) {
        $(`div#${targetStatus} .__faker`).remove();

        if ($("#" + paths[index - 3].id) !== undefined) {
          // only show element below when theres already ticket in the target status
          if (
            $(`div.card#status-${status} div.taskcard-container div.ticket`)
              .length > 0
          ) {
            $("#" + paths[index - 3].id).after(
              `<div id="status-${status}" class='p-5 __faker w-100 bg-light d-block' style='height:100px;margin-bottom:6px'> <small class='text-muted'>Drop here</small> </div>`
            );
          }
        } else {
          $("#" + paths[index]).after(
            `<div id="status-${status}"  class='p-5  __faker w-100' style='height:100px;margin-bottom:6px'>&nbsp;</div>`
          );
        }
      }
    },

    async drop(ev) {
      $(".__faker").remove();
      ev.preventDefault();

      let targetTicketId = this.$store.state.story.ticketToDrop;

      let targetTicket = this.$store.state.alltickets.allTickets.filter(
        (el) => el.id == targetTicketId
      );

      if (targetTicket.length > 0) {
        const targetTicketStatus = targetTicket[0].get("status");
        const targetColumnId = ev.target.id;
        const targetColumnStatus = targetColumnId.split("-")[1];

        if (
          parseInt(targetTicketStatus) < targetColumnStatus ||
          this.$store.getters["auth/roles"].indexOf("master") > -1
        ) {
          const data = {
            storyId: this.$route.params.id || targetTicket[0].get("story").id,
            status: ev.target.id,
            currentSprintId: targetTicket[0].get("sprint").id,
          };

          await this.$store.dispatch("sprints/promoteTicket", data);

          // send notification to ticket's story owner
          this.$store.dispatch("story/notifyOwner", {
            storyId: targetTicket[0].get("story").id,
            status: targetColumnStatus - 1,
            title: targetTicket[0].get("recommendation"),
          });
        }
      }
    },
  },
  async created() {
    await this.$store.dispatch("alltickets/getAllTickets");
    await this.$store.dispatch("sprints/getSprints");

    // redirect to my-stories if user is not a dev
    if (this.$store.state.auth.roles.length <= 0) {
      this.$router.push("/my-stories");
    }

    let _sprints = this.$store.state.sprints.sprints;
    let _currentSprint = _sprints.at(-1);

    if (_sprints.length > 0) {
      await this.$store.dispatch("sprints/getTicket", {
        sprintId: _currentSprint.id,
      });

      await this.$store.dispatch("story/getDevs");
    }

    await this.$store.dispatch("board/members", {
      boardId: this.$store.state.board.active,
    });
  },
};
</script>
<style scoped>
.taskcard-container {
  min-height: 50px !important;
  max-height: 73vh;
  overflow: auto;
  overflow-x: hidden;
  background-color: #ebecf0;
}
.card-bg {
  background-color: #ebecf0;
}
.taskcard-container::-webkit-scrollbar {
  width: 0.5em;
}

.taskcard-container::-webkit-scrollbar-track {
  background: #091e4214;
  border-radius: 5px;
}

.taskcard-container::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 70%, 0.8);
  border-radius: 5px;
}
.main__board {
  overflow: auto;
}
.main__board::-webkit-scrollbar {
  height: 10px;
}

.main__board::-webkit-scrollbar-track {
  background-color: hsla(0, 0%, 0%, 0.2);
  border-radius: 5px;
}

.main__board::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 5px;
}
</style>