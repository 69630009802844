<template>
  <div
    v-if="$route.name"
    :class="
      ['login', 'boards', 'loginparam'].indexOf($route.name.toLowerCase()) < 0
        ? themeActiveBodyBG
        : ''
    "
    class="__appTheme"
  >
    <div
      id="body-pd"
      v-if="
        hideNavbarUrls.indexOf($route.name) < 0 &&
        $store.getters['auth/isLoggedIn'] &&
        $store.state.auth.currentUser.get('activeBoard') !== 'none'
      "
    >
      <header class="header" id="header">
        <div class="header_toggle text-light">
          <i class="bx bx-menu" id="header-toggle"></i>
        </div>

        <div>
          <div
            v-if="$store.state.board.members.length > 0"
            class="d-flex align-items-center me-4 float-start"
          >
            <!-- HEREE -->
            <div
              v-for="(member, index) in $store.state.board.members"
              :key="member.id"
            >
              <div
                v-if="index + 1 <= 6"
                class="header_img"
                :style="`z-index:${$store.state.board.members.length - index}`"
              >
                <BoardMembersAvatar
                  :userId="member.get('username')"
                  :name="member.get('fullname')"
                  :avatar="member.get('avatar')"
                />
              </div>
            </div>
            <BoardMembersAll />
          </div>

          <div class="btn-group float-start me-4">
            <div class="dropdown">
              <button
                class="btn adapt__theme_bg_dark dropdown-togglesss"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i
                  class="bx bxs-dashboard align-middle adapt__theme_lighter"
                ></i>
                <span
                  class="adapt__theme_lighter small ps-1"
                  v-if="$store.state.board.boards.length > 0"
                >
                  {{
                    $store.state.board.boards
                      .filter(
                        (el) =>
                          el.id ==
                          $store.state.auth.currentUser.get("activeBoard")
                      )[0]
                      .get("name")
                  }}
                </span>
              </button>
              <ul
                class="dropdown-menu shadow-lg options-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a class="dropdown-item small fw-bold text-muted" href="#"
                    >Solid colors</a
                  >
                </li>
                <li>
                  <div class="p-2">
                    <!-- colors -->
                    <div class="row m-0 p-0">
                      <div class="col">
                        <button
                          @click="applyTheme('__theme-blue')"
                          class="btn btn-sm __theme-blue"
                        >
                          <i
                            class="bx bx-check p-0 m-0 text-light"
                            v-if="themeActiveBodyBG == '__theme-blue'"
                          ></i>
                          <span v-else class="p-2 d-block"></span>
                        </button>
                      </div>
                      <div class="col">
                        <button
                          @click="applyTheme('__theme-green')"
                          class="btn btn-sm __theme-green"
                        >
                          <i
                            class="bx bx-check p-0 m-0 text-light"
                            v-if="themeActiveBodyBG == '__theme-green'"
                          ></i>
                          <span v-else class="p-2 d-block"></span>
                        </button>
                      </div>
                      <div class="col">
                        <button
                          @click="applyTheme('__theme-purple')"
                          class="btn btn-sm __theme-purple"
                        >
                          <i
                            class="bx bx-check p-0 m-0 text-light"
                            v-if="themeActiveBodyBG == '__theme-purple'"
                          ></i>
                          <span v-else class="p-2 d-block"></span>
                        </button>
                      </div>
                    </div>

                    <div class="row m-0 mt-2 p-0">
                      <div class="col">
                        <button
                          @click="applyTheme('__theme-pink')"
                          class="btn btn-sm __theme-pink"
                        >
                          <i
                            class="bx bx-check p-0 m-0 text-light"
                            v-if="themeActiveBodyBG == '__theme-pink'"
                          ></i>
                          <span v-else class="p-2 d-block"></span>
                        </button>
                      </div>
                      <div class="col">
                        <button
                          @click="applyTheme('__theme-red')"
                          class="btn btn-sm __theme-red"
                        >
                          <i
                            class="bx bx-check p-0 m-0 text-light"
                            v-if="themeActiveBodyBG == '__theme-red'"
                          ></i>
                          <span v-else class="p-2 d-block"></span>
                        </button>
                      </div>
                      <div class="col">
                        <button
                          @click="applyTheme('__theme-orange')"
                          class="btn btn-sm __theme-orange"
                        >
                          <i
                            class="bx bx-check p-0 m-0 text-light"
                            v-if="themeActiveBodyBG == '__theme-orange'"
                          ></i>
                          <span v-else class="p-2 d-block"></span>
                        </button>
                      </div>
                    </div>

                    <div class="row m-0 mt-2 p-0">
                      <div class="col">
                        <button
                          @click="applyTheme('__theme-skyblue')"
                          class="btn btn-sm __theme-skyblue"
                        >
                          <i
                            class="bx bx-check p-0 m-0 text-light"
                            v-if="themeActiveBodyBG == '__theme-skyblue'"
                          ></i>
                          <span v-else class="p-2 d-block"></span>
                        </button>
                      </div>
                      <div class="col">
                        <button
                          @click="applyTheme('__theme-lime')"
                          class="btn btn-sm __theme-lime"
                        >
                          <i
                            class="bx bx-check text-light"
                            v-if="themeActiveBodyBG == '__theme-lime'"
                          ></i>
                          <span v-else class="p-2 d-block"></span>
                        </button>
                      </div>
                      <div class="col">
                        <button
                          @click="applyTheme('__theme-grey')"
                          class="btn btn-sm __theme-grey border"
                        >
                          <i
                            class="bx bx-check p-0 m-0 text-primary"
                            v-if="themeActiveBodyBG == '__theme-grey'"
                          ></i>
                          <span v-else class="p-2 d-block"></span>
                        </button>
                      </div>
                    </div>
                    <!-- end colors -->
                  </div>
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <a class="dropdown-item small fw-bold text-muted" href="#"
                    >Gradient colors</a
                  >
                </li>
                <!-- gradient -->
                <div class="row m-0 p-0">
                  <div class="col">
                    <button
                      @click="applyTheme('__theme-gradient-orange')"
                      class="btn btn-sm __theme-gradient-orange"
                    >
                      <i
                        class="bx bx-check p-0 m-0 text-light"
                        v-if="themeActiveBodyBG == '__theme-gradient-orange'"
                      ></i>
                      <span v-else class="p-2 d-block"></span>
                    </button>
                  </div>
                  <div class="col">
                    <button
                      @click="applyTheme('__theme-gradient-purple')"
                      class="btn btn-sm __theme-gradient-purple"
                    >
                      <i
                        class="bx bx-check p-0 m-0 text-light"
                        v-if="themeActiveBodyBG == '__theme-gradient-purple'"
                      ></i>
                      <span v-else class="p-2 d-block"></span>
                    </button>
                  </div>
                  <div class="col">
                    <button
                      @click="applyTheme('__theme-gradient-skyblue')"
                      class="btn btn-sm __theme-gradient-skyblue"
                    >
                      <i
                        class="bx bx-check p-0 m-0 text-light"
                        v-if="themeActiveBodyBG == '__theme-gradient-skyblue'"
                      ></i>
                      <span v-else class="p-2 d-block"></span>
                    </button>
                  </div>
                </div>
                <!-- end gradient -->

                <li><hr class="dropdown-divider" /></li>
                <li>
                  <a class="dropdown-item small fw-bold text-muted" href="#"
                    >Images</a
                  >
                </li>
                <!-- images -->
                <div class="row m-0 p-0">
                  <div class="col">
                    <button
                      @click="applyTheme('__theme-image-1')"
                      class="btn btn-sm __theme-image-1"
                    >
                      <i
                        class="bx bx-check p-0 m-0 text-light"
                        v-if="themeActiveBodyBG == '__theme-image-1'"
                      ></i>
                      <span v-else class="p-2 d-block"></span>
                    </button>
                  </div>
                  <div class="col">
                    <button
                      @click="applyTheme('__theme-image-2')"
                      class="btn btn-sm __theme-image-2"
                    >
                      <i
                        class="bx bx-check p-0 m-0 text-light"
                        v-if="themeActiveBodyBG == '__theme-image-2'"
                      ></i>
                      <span v-else class="p-2 d-block"></span>
                    </button>
                  </div>
                  <div class="col">
                    <button
                      @click="applyTheme('__theme-image-3')"
                      class="btn btn-sm __theme-image-3"
                    >
                      <i
                        class="bx bx-check p-0 m-0 text-light"
                        v-if="themeActiveBodyBG == '__theme-image-3'"
                      ></i>
                      <span v-else class="p-2 d-block"></span>
                    </button>
                  </div>
                </div>

                <li><hr class="dropdown-divider" /></li>
                <li>
                  <span class="p-3 small fw-bold text-muted" href="#"
                    >Your Boards</span
                  >
                </li>
                <li v-for="board in $store.state.board.boards" :key="board.id">
                  <a
                    :set="
                      (isActive =
                        board.id ==
                        $store.state.auth.currentUser.get('activeBoard'))
                    "
                    :class="isActive ? 'active' : ''"
                    @click="activateBoard(board.id)"
                    class="dropdown-item"
                    href="#"
                  >
                    <i class="bx bxs-check-circle" v-if="isActive"></i>
                    <i class="bx bxs-chevrons-right text-muted" v-else></i>

                    {{ board.get("name") }}</a
                  >
                </li>
                <li
                  class="pt-2 d-flex align-items-center justify-content-center"
                >
                  <button
                    class="btn btn-sm btn-outline-info"
                    @click="create_board"
                  >
                    <i class="bx bx-plus"></i> Create new board
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="btn-group float-start me-4"
            :set="(notifications = $store.state.notifications.notifications)"
          >
            <button
              class="btn btn-sm dropdown-toggle nocarret"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="text-light align-middle bx bx-bell bx-sm"></i>
              <span
                v-if="notifications.length"
                class="bg-danger badge rounded-circle d-inline-block text-light"
                >{{
                  [
                    ...new Map(
                      notifications.map((item) => [item["id"], item])
                    ).values(),
                  ].length
                }}</span
              >
            </button>
            <ul class="dropdown-menu p-0">
              <li v-if="!notifications.length">
                <p class="p-2 text-center">
                  <i class="bx text-secondary align-middle bx-error-circle"></i>
                  No notifications.
                </p>
              </li>
              <li v-else class="p-2 m-0 border-bottom bg-light">
                <h6>Notifications</h6>
              </li>
              <li
                v-for="(notif, index) in [
                  ...new Map(
                    notifications.map((item) => [item['id'], item])
                  ).values(),
                ]"
                :key="index"
                :class="index % 2 == 0 ? 'border-bottom' : ''"
              >
                <router-link
                  @click="triggerSeen(notif.id)"
                  class="dropdown-item p-2"
                  :to="(path = notif.get('data').route)"
                  >{{ notif.get("message") }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div class="l-navbar" id="nav-bar">
        <nav class="nav">
          <div>
            <a
              href="#"
              class="nav_logo d-flex align-items-center justify-content-center"
            >
              <h5 class="m-0 d-none d-lg-block">
                <span class="nav_logo-name adapt__theme_lighter"
                  >Task<i class="bx bxl-bootstrap bx-sm align-middle"></i
                  >oard</span
                >
                  <small class="fw-light text-center text-light d-none d-lg-block" style="font-size:10px">v{{ version }}</small>
              </h5>
             
            </a>

            <div id="sidebar-avatar" class="text-center mb-4 d-none">
              <div class="d-flex align-items-center justify-content-center">
                <img
                  v-if="$store.state.auth.currentUser.get('username')"
                  class="rounded-pill"
                  width="60"
                  :src="avatar"
                  @error="
                    avatar =
                      this.$store.state.auth.currentUser.get('avatar')
                  "
                  alt=""
                />
              </div>
              <p id="lnavbar-username" class="pt-2 text-light text-center m-0">
                {{
                  $store.state.auth.currentUser.get("firstname") +
                  " " +
                  $store.state.auth.currentUser.get("lastname")
                }}
              </p>
              <small
                id="lnavbar-roles"
                v-if="$store.getters['auth/roles'].length <= 0"
                class="text-muted"
                >{{ $store.state.auth.currentUser.get("department") }}</small
              >
              <small v-else>
                <span
                style="font-size:10px"
                  id="lnavbar-roles"
                  class="adapt__theme_light text-uppercase"
                  >{{ $store.getters["auth/roles"].join(", ") }}</span
                >
              </small>
            </div>

            <div class="nav_list">
              <router-link
                v-if="$store.state.auth.roles.length > 0"
                to="/"
                :class="$route.name == 'Home' ? 'nav_link active' : 'nav_link'"
              >
                <i class="bx bx-home nav_icon"></i>
                <span class="nav_name">Home</span>
              </router-link>

              <router-link
                v-if="$store.getters['auth/roles'].length > 0"
                :to="{ name: 'Tickets' }"
                :class="
                  $route.name.includes('Ticket') &&
                  !$route.name.includes('Sprint')
                    ? 'nav_link active'
                    : 'nav_link'
                "
              >
                <i class="bx bxs-coupon nav_icon"></i>
                <span class="nav_name">All Tickets</span>
              </router-link>

              <!-- visible only to devs -->
              <router-link
                v-if="$store.getters['auth/roles'].length > 0"
                :to="{ name: 'Sprints' }"
                :class="
                  $route.name !== undefined &&
                  $route.name.indexOf('Sprint') > -1
                    ? 'nav_link active'
                    : 'nav_link'
                "
              >
                <i class="bx bx-pulse bx-sm nav_icon"></i>
                <span class="nav_name">Sprints</span>
              </router-link>

              <router-link
                v-if="$store.getters['auth/roles'].length > 0"
                :to="{ name: 'AllStories' }"
                :class="
                  $route.name !== undefined &&
                  $route.name.indexOf('AllStor') > -1
                    ? 'nav_link active'
                    : 'nav_link'
                "
              >
                <i class="bx bx-user-voice bx-sm nav_icon"></i>
                <span class="nav_name">User Stories</span>
              </router-link>

              <router-link
                :to="{ name: 'UserStories' }"
                :class="
                  $route.name !== undefined && $route.name == 'UserStory'
                    ? 'nav_link active'
                    : 'nav_link'
                "
              >
                <i class="bx bx-message-alt nav_icon"></i>
                <span class="nav_name">My Stories</span>
              </router-link>

              <router-link
                :to="{ name: 'BoardInvitations' }"
                :class="
                  $route.name !== undefined && $route.name == 'BoardInvitations'
                    ? 'nav_link active'
                    : 'nav_link'
                "
              >
                <i class="bx bx-group nav_icon"></i>
                <span class="nav_name"
                  >Invitations
                  <span
                    v-if="$store.state.board.invites.length > 0"
                    class="ms-3 badge bg-danger"
                    >{{ $store.state.board.invites.length }}</span
                  ></span
                >
              </router-link>
            </div>
          </div>
          <a href="#" class="nav_link" @click="logOut">
            <i class="bx bx-log-out nav_icon"></i>
            <span class="nav_name">Sign out</span>
          </a>
          
        </nav>
       
      </div>
      <!--Container Main start-->
      <div>
        <router-view />
      </div>
    </div>
    <div v-else><router-view /></div>
  </div>
</template>



<script>
import { version } from "../package";
import BoardMembersAvatar from "./views/components/BoardMembersAvatar.vue";
import BoardMembersAll from "./views/components/BoardMembersAll.vue";
export default {
  components: { BoardMembersAvatar, BoardMembersAll },
  data() {
    return {
      version: version,
      themeActiveBodyBG: "",
      hideNavbarUrls: [
        "Login",
        "LoginParam",
        "Register",
        "RegisterParam",
        "NotFound",
      ],
      avatar:
        this.$store.state.auth.currentUser == null
          ? ""
          :  this.$store.state.auth.currentUser.get("avatar"),
    };
  },
  async created() {
    await this.$store.dispatch("auth/currentUser");

    if (this.$store.state.auth.currentUser !== null) {
      const currentBoard =
        this.$store.state.auth.currentUser.get("activeBoard");
      if (currentBoard !== "none") {
        await this.$store.dispatch("board/members", {
          boardId: currentBoard,
        });
      }
      await this.$store.dispatch("auth/getRole");
      await this.$store.dispatch("notifications/getAll");
      await this.getBoards();
      this.themeActiveBodyBG =
        this.$store.state.auth.currentUser.get("activeTheme");
      this.getInvitations();
    }
  },

  methods: {
    async getInvitations() {
      await this.$store.dispatch("board/getInvites", {
        userId: this.$store.state.auth.currentUser.get("username"),
      });
    },
    async applyTheme(name) {
      await this.$store.dispatch("global/setActiveTheme", { name: name });

      this.themeActiveBodyBG = name;
    },

    async logOut() {
      this.$store.dispatch("auth/signOut", { root: true });
    },
    async getBoards() {
      await this.$store.dispatch("board/getAll", {
        objectId: this.$store.state.auth.currentUser.id,
        department: this.$store.state.auth.currentUser.get("department"),
      });
    },
    async activateBoard(objectId) {
      console.log(this.$store.state.auth.currentUser.get("activeBoard"));
      this.$store
        .dispatch("board/setActive", { objectId: objectId })
        .then((res) => {
          this.$router.go();
        });
    },
    async triggerSeen(notifId) {
      await this.$store.dispatch("notifications/seenNotification", {
        notifId: notifId,
      });
    },
  },
};
</script>
<style scoped>
/** Default Theme */
.force__header_default {
  background-color: #1d62cb !important;
}
.force__lnavbar_default {
  background-color: #ffffff !important;
}
.nav-logo-default {
  color: #1d62cb !important;
}
.lnavbar-roles {
  color: #00aced !important;
}
.lnavbar-username {
  color: #6c757d !important;
}
.nav_link_default {
  color: #6c757d !important;
}
.nav_link_default.active {
  color: #1d62cb !important;
  background-color: #e8effa !important;
  font-weight: 500;
}
.adapt__theme_lighter_default {
  color: #111 !important;
}
.adapt__theme_light_default {
  color: #6c757d !important;
}
.bg__default {
  background-color: transparent !important;
}
/** Custom Theme */
.__theme-image-1 {
  background-image: url("~@/assets/bg/claudio-testa--SO3JtE3gZo-unsplash.jpg");
  background-position: bottom;
  background-size: cover;
}
.__theme-image-2 {
  background-image: url("~@/assets/bg/daniel-leone-g30P1zcOzXo-unsplash.jpg");
  background-position: center;
  background-size: cover;
}
.__theme-image-3 {
  background-image: url("~@/assets/bg/johann-siemens-EPy0gBJzzZU-unsplash.jpg");
  background-position: center;
  background-size: cover;
}
.__theme-gradient-orange {
  background: rgb(131, 58, 180);
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
}
.__theme-gradient-purple {
  background: rgb(63, 94, 251);
  background: radial-gradient(
    circle,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  );
}
.__theme-gradient-skyblue {
  background: rgb(34, 193, 195);
  background: radial-gradient(
    circle,
    rgba(34, 193, 195, 1) 0%,
    rgba(253, 187, 45, 1) 100%
  );
}
.__theme-purple {
  background-color: rgb(137, 96, 158) !important;
}
.__theme-blue {
  background-color: rgb(0, 121, 191) !important;
}
.__theme-skyblue {
  background-color: rgb(0, 174, 204) !important;
}
.__theme-orange {
  background-color: rgb(210, 144, 52) !important;
}
.__theme-green {
  background-color: rgb(81, 152, 57) !important;
}
.__theme-lime {
  background-color: rgb(75, 191, 107) !important;
}
.__theme-pink {
  background-color: rgb(205, 90, 145) !important;
}
.__theme-red {
  background-color: rgb(176, 70, 50) !important;
}
.__theme-light {
  background-color: #f8f9fa !important;
}
.__theme-grey {
  background-color: rgb(131, 140, 145) !important;
}

.header {
  padding-left: 30px !important;
}
header.header,
.l-navbar {
  background-color: hsla(0, 0%, 0%, 0.16);
}

.force__header_lnavbar_light {
  background-color: hsla(0, 100%, 0%, 0.16) !important;
}
.force__header_lnavbar_dark {
  background-color: hsla(0, 0%, 10%, 0.6) !important;
}

.nocarret::after {
  display: none;
}
.badge {
  margin-left: -10px;
}
.dropdown-menu {
  min-width: 200px !important;
}
.dropdown-menu > li a:hover {
  color: #296bd4;
}
.dropdown-menu li button.btn.btn-sm,
.dropdown-menu div.col > button.btn.btn-sm {
  height: 31px;
}
ul.options-menu li a.active {
  background-color: white !important;
  color: #00aced;
  font-weight: 500;
}
</style>

