<template>
  <div class="box">
    <div class="row">
      <div class="col-lg">
        <div class="card rounded bg-white shadow-lg border-0 p-5">
          <h3 class="fw-bold text-start pb-4">Create Account</h3>

          <div
            class="alert fw-bold alert-danger"
            v-if="$store.state.auth.loginError !== ''"
          >
            {{ $store.state.auth.loginError }}
          </div>

          <form @submit="signUp" v-if="!needsReload">
            <div class="form-floating mb-3">
              <input
                disabled
                type="number"
                class="form-control"
                id="floatingInput1"
                placeholder=" "
                v-model="id"
              />
              <label for="floatingInput1">Employee Number</label>
            </div>

            <div class="form-floating mb-3">
              <input
                disabled
                type="text"
                class="form-control"
                id="floatingInput2"
                placeholder=" "
                v-model="firstName"
              />
              <label for="floatingInput2">First Name</label>
            </div>

            <div class="form-floating mb-3">
              <input
                disabled
                type="text"
                class="form-control"
                id="floatingInput3"
                placeholder=" "
                v-model="lastName"
              />
              <label for="floatingInput3">Last Name</label>
            </div>

            <div class="form-floating mb-3">
              <input
                disabled
                type="text"
                class="form-control"
                id="floatingInput4"
                placeholder=" "
                v-model="department"
              />
              <label for="floatingInput4">Department</label>
            </div>

            <div class="form-floating mb-3">
              <input
                :type="passwordVisible ? 'text' : 'password'"
                class="form-control"
                id="floatingInput5"
                placeholder=" "
                v-model="password"
              />
              <label for="floatingInput5">Password</label>
            </div>

            <div class="form-check text-start mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                v-on:change="togglePasswordVisibility()"
              />
              <label class="form-check-label" for="flexCheckDefault">
                <small class="text-muted">Show Password</small>
              </label>
            </div>

            <button
              v-if="!needsReload"
              :disabled="isLoading"
              class="btn btn-block btn-primary fw-bold p-3"
              type="submit"
              style="width: 100%"
            >
              <span v-if="isLoading"
                ><i class="fa fa-spinner fa-spin"></i
              ></span>
              <span v-else>REGISTER</span>
            </button>
          </form>

          <button
            class="btn p-3 fw-bold btn-danger"
            onclick="javascript:window.location.reload()"
            v-if="needsReload"
          >
            An Error occured: Click to reload
          </button>

          <p class="text-muted text-start pt-3">
            Already have an account?
            <a href="https://taskboard.eternalgardens.ph/#/login">Login here.</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import router from "../../router";
import store from "../../store";

export default {
  name: "Register",
  data() {
    return {
      id: this.$route.params.id
        ? Buffer.from(this.$route.params.id, "base64")
        : null,
      firstName: "",
      lastName: "",
      department: "",
      password: "",
      sex: "",
      passwordVisible: false,
      isLoading: true,
      needsReload: false,
      errorMessage: "",
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    async getEmployee() {
      this.isLoading = true;
      await this.$store.dispatch("auth/employeeDetails", { id: this.id });
      this.isLoading = false;

      if (this.$store.state.auth.preUser.hasOwnProperty("firstname")) {
        const _preUser = this.$store.state.auth.preUser;
        this.firstName = _preUser.firstname;
        this.lastName = _preUser.lastname;
        this.department = _preUser.department;
      }
    },
    resetRegForm() {
      this.firstName = "";
      this.lastName = "";
      this.department = "";
      this.password = "";
      this.errorMessage = "";
      this.isLoading = false;
    },
    async signUp(e) {
      e.preventDefault();
      this.errorMessage = "";

      this.isLoading = true;
      await this.$store.dispatch("auth/signUp", {
        password: this.password,
      });
      this.errorMessage = this.$store.getters["auth/loginError"];
      this.isLoading = false;
    },
  },
  async created() {
    this.getEmployee();
  },
};
</script>
<style scoped>
.box {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
}
.box div.row {
  min-width: 30vw;
  min-height: 100px;
  max-height: auto;
}
</style>
