<template>
  <div
    class="d-flex align-items-start"
    :key="updateKey"
   
  >
    <img
      :src="
          $store.state.alltickets.ticketConversation[$props.index]
            .get('sender')
            .get('avatar')
      "
      class="rounded-pill"
      width="30"
      alt="sender"
    />
    <div class="ms-2 col-lg-12">
      <h6
        class="
          p-0
          m-0
          text-primary
          adapt__theme_lighter
          senderName
          mb-1
          text-capitalize
        "
        :set="(sender = $props.sender)"
      >
        {{
          sender.get("firstname").toLowerCase() +
          " " +
          sender.get("lastname").toLowerCase()
        }}
        <small class="adapt__theme_light fw-normal text-lowercase ms-2">{{
          formatDate($props.date)
        }}</small>
      </h6>

      <!-- Image Viewer Modal -->
      <div
        class="modal fade"
        id="imageModalComment"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-fullscreen border-0">
          <div class="modal-content" style="background-color: transparent">
            <div class="modal-header border-0">
              <h5 class="modal-title"></h5>
              <button
                type="button"
                class="btn-close btn-outline-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" id="modal-body-comment"></div>
            <div class="p-5"></div>
          </div>
        </div>
      </div>

      <div class="adapt__theme_bg_darker card d-inline-block p-2 m-0 comment">
        <span v-html="boldBrackets"></span>

        <span
          :set="(hasMoreLikes = likers.length > 1)"
          v-if="likers.length > 0"
          :class="hasMoreLikes ? 'rounded-pill' : 'rounded-circle'"
          :title="
            'Liked by: ' + likers.map((a) => a.get('fullname')).join('\n')
          "
          class="
            float-end
            likeContainer
            d-block
            bg-light
            p-1
            d-flex
            justify-content-center
            align-items-center
            shadow-sm
          "
        >
          <i class="bx bxs-like text-warning"></i>
          <small class="d-inline-block text-muted likesCount">
            {{ hasMoreLikes > 1 ? likers.length : "" }}
          </small></span
        >

        <a
          :href="null"
          v-if="$props.image !== null"
          data-bs-target="#imageModalComment"
          data-bs-toggle="modal"
          @click="viewImage($props.image)"
        >
          <img :src="$props.image" alt="" width="300" />
        </a>
      </div>
      <div class="mb-3">
        <button
          class="btn btn-sm rounded-pill"
          @click="unlikeComment($props.commentId)"
          v-if="
            likers.filter((el) => el.id == $store.state.auth.currentUser.id)
              .length > 0
          "
        >
          <span><i class="bx bxs-like text-warning"></i></span>
        </button>
        <button
          @click="likeComment($props.commentId)"
          class="btn btn-sm rounded-pill adapt__theme_light"
          v-else
        >
          <span><i class="bx bx-like"></i></span>
        </button>

        <!-- end like -->

        <button
          @click="deleteComment($props.commentId)"
          v-if="sender.id == $store.state.auth.currentUser.id"
          class="btn btn-sm rounded-pill adapt__theme_light"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
export default {
  name: "Comment",
  props: {
    sender: Object,
    date: Date,
    message: String,
    image: String,
    commentId: String,
    index: Number, // comment index
    
  },
  data() {
    return {
      formattedDate: "",
      likers: [],
      updateKey: 1,
    };
  },
  computed: {
    boldBrackets() {
      if (this.$props.message) {
        return this.$props.message
          .replace(
            /(?<=\[)[^\][]*(?=])/g,
            `<span class='text-primary fw-bold' title=$&>$&</span>`
          )
          .replaceAll("[", "")
          .replaceAll("]", "");
      }
    },
  },
  methods: {
    viewImage(url) {
      document
        .querySelectorAll("#modal-body-comment")[0]
        .setAttribute(
          "style",
          "background-image: url(" +
            url +
            ");background-position:center;background-repeat:no-repeat;background-size: contain;background-color:transparent !important"
        );
    },
    formatDate(date) {
      const format = moment(new Date(date));
      const now = new Date();
      //this.formattedDate = format.format("MMMM d, YYYY h:mma");
      const duration = moment.duration(format.diff(now));
      return duration.humanize() + " ago";
    },
    async forceRerender() {
      this.updateKey += 1;
      this.likers = await this.getLikers(this.$props.commentId);
    },
    async likeComment(commentId) {
      await this.$store.dispatch("alltickets/likeComment", {
        commentId: commentId,
      });
      await this.getLikers(commentId);
      this.forceRerender();
    },
    async unlikeComment(commentId) {
      await this.$store.dispatch("alltickets/unlikeComment", {
        commentId: commentId,
      });
      await this.getLikers(commentId);
      this.forceRerender();
    },
    async deleteComment(commentId) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("alltickets/deleteComment", {
            commentId: commentId,
          });
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });

      //  this.forceRerender();
    },
    async getLikers(commentId) {
      return await this.$store.dispatch("alltickets/likedComments", {
        commentId: commentId,
      });
    },
  },
  async created() {
    this.likers = await this.getLikers(this.$props.commentId);
    // this.avatar =
    //   "//172.28.1.2/photo/" +
    //   this.$store.state.alltickets.ticketConversation[this.$props.index]
    //     .get("sender")
    //     .get("username") +
    //   ".jpg";
  },
};
</script>

<style scoped>
.likesCount {
  font-size: 10px;
  font-weight: bold;
}
.likeContainer {
  background-color: #dbf0fa !important;
  margin-top: -5px;
  margin-right: -15px;
  width: 30px;
  height: 30px;
}
</style>