import Parse from "parse";

const alltickets = {
    namespaced: true,
    state: {
        allTickets: [],
        singleTicket: null,
        ticketConversation: [],
        likedComments: [],
        memberSearchResult: [],
        checklist: [],
        files: [], // uploaded attachments per ticket
    },
    mutations: {
        files(state, payload) {
            state.files = payload.response
        },
        checklist(state, payload) {
            state.checklist = payload.checklist
        },
        allTickets(state, payload) {
            state.allTickets = payload.tickets
        },
        singleTicket(state, payload) {
            state.singleTicket = payload.ticket
        },
        ticketConversation(state, payload) {

            state.ticketConversation = payload.conversation
        },
        appendTicketConversation(state, payload) {
            state.ticketConversation.splice(0, 0, payload.conversation)
        },
        memberSearchResult(state, payload) {
            state.memberSearchResult.splice(0, -1, payload.result);
        },
        clearMemberSearchResult(state) {
            state.memberSearchResult = [];
        }
    },
    getters: {
        alltickets: (state) => state.alltickets,
        checklist: (state) => state.checklist,
        ticketConversation: (state) => state.ticketConversation,
        memberSearchResult: (state) => state.memberSearchResult,
    },
    actions: {
        /**
         * Get The image file from attachments if exists.
         * @return String
         */
        getTicketCover: async ({ }, payload) => {
            const Attachment = Parse.Object.extend("TicketAttachments");
            const query = new Parse.Query(Attachment);

            const ticketPointer = { __type: 'Pointer', className: 'Tickets', objectId: payload.ticketId };

            query.equalTo('type', 'file');
            query.equalTo('ticket', ticketPointer);

            return await query.find().then((result) => {
                if (result.length > 0) {
                    let found_ext = result[0].get('name').split('.').at(-1);

                    let images_ext = ['jpg', 'jpeg', 'ico', 'gif', 'png'];

                    if (images_ext.indexOf(found_ext.toLowerCase()) > -1) {
                        return result[0].get('url');
                    }
                }


                return "";
            })
        },
        checklist: async ({ }, payload) => {
            const ticketPointer = { __type: 'Pointer', className: 'Tickets', objectId: payload.ticketId };
            const Checklist = Parse.Object.extend("Checklist");
            const query = new Parse.Query(Checklist);

            query.equalTo('ticket', ticketPointer)

            const result = await query.find();
            return result;
        },
        uploadFile: async ({ dispatch }, payload) => {
            let filename = payload.label;
            const ticketPointer = { __type: 'Pointer', className: 'Tickets', objectId: payload.ticketId };
            const userPointer = { __type: 'Pointer', className: '_User', objectId: payload.uploader };

            const TicketAttachment = Parse.Object.extend("TicketAttachments");
            const attachment = new TicketAttachment();


            const type = payload.type;

            if (type == "file") {
                filename = payload.file.name.replace(/[^A-Za-z;._0-9]/g, "");
                const file = new Parse.File(filename, payload.file);
                return await file.save().then((response) => {
                    attachment.set('name', type == "link" ? payload.label : filename);
                    attachment.set('ticket', ticketPointer);
                    attachment.set('url', type == "link" ? payload.url : response._url);
                    attachment.set('uploader', userPointer);
                    attachment.save();

                    dispatch('getFiles', { ticketId: payload.ticketId })
                    return true;
                });
            }

            if (type == "link") {

                attachment.set('name', payload.label);
                attachment.set('ticket', ticketPointer);
                attachment.set('url', payload.url);
                attachment.set('uploader', userPointer);
                attachment.set('type', type);
                await attachment.save();

                dispatch('getFiles', { ticketId: payload.ticketId })
                return true;
            }





        },
        getFiles: async ({ commit }, payload) => {
            const ticketPointer = { __type: 'Pointer', className: 'Tickets', objectId: payload.ticketId };


            const TicketAttachment = Parse.Object.extend("TicketAttachments");
            const query = new Parse.Query(TicketAttachment);

            query.equalTo('ticket', ticketPointer);
            query.descending('createdAt');
            query.include('uploader');

            return query.find().then((response) => {

                commit('files', { response: response });
            })
        },
        removeFile: async ({ commit }, payload) => {



            const TicketAttachment = Parse.Object.extend("TicketAttachments");
            const query = new Parse.Query(TicketAttachment);

            query.equalTo('objectId', payload.objectId);


            await query.find().then((response) => {
                return response[0].destroy();
            })
        },
        unsubscribeLiveQuery: async ({ }) => {
            // close live queries
            await Parse.LiveQuery.close();

        },
        addChecklist: async ({ }, payload) => {
            const ticketPointer = { __type: 'Pointer', className: 'Tickets', objectId: payload.ticketId };
            const Checklist = Parse.Object.extend("Checklist");
            const checklist = new Checklist();

            const acl = new Parse.ACL();
            acl.setPublicReadAccess(true);
            acl.setPublicWriteAccess(false);

            acl.setReadAccess(payload.userId, true);
            acl.setWriteAccess(payload.userId, true);

            checklist.set('description', payload.description);
            checklist.set('ticket', ticketPointer);
            checklist.setACL(acl);

            return await checklist.save();
        },
        getChecklist: async ({ commit }, payload) => {
            const ticketPointer = { __type: 'Pointer', className: 'Tickets', objectId: payload.ticketId };

            const Checklist = Parse.Object.extend("Checklist");
            const query = new Parse.Query(Checklist);

            query.equalTo('ticket', ticketPointer);
            query.ascending('createdAt');

            const result = await query.find();

            if (result.length > 0) {
                commit('checklist', { checklist: result });
            } else {
                commit('checklist', { checklist: [] });
            }
            return true;
        },
        triggerChecklist: async ({ }, payload) => {


            const Checklist = Parse.Object.extend("Checklist");
            const query = new Parse.Query(Checklist);

            query.equalTo('objectId', payload.id);


            const result = await query.find();
            if (result.length > 0) {
                result[0].set('checked', payload.value);
                return await result[0].save();
            }
        },
        deleteChecklist: async ({ dispatch }, payload) => {


            const Checklist = Parse.Object.extend("Checklist");
            const query = new Parse.Query(Checklist);

            query.equalTo('objectId', payload.id);


            const result = await query.find();

            for (let i = 0; i < result.length; i++) {
                await result[i].destroy();
                dispatch('getChecklist', { ticketId: result[i].get('ticket').id })
            }


        },
        unInviteMember: async ({ commit }, payload) => {
            const Ticket = Parse.Object.extend("Tickets");
            const query = new Parse.Query(Ticket);

            query.equalTo('objectId', payload.objectId);

            return query.find().then(async (result) => {
                const userPointer = Parse.Object.extend("_User");
                const uq = new Parse.Query(userPointer);
                uq.equalTo('username', payload.userId);

                const user = await uq.find();



                const relation = result[0].relation('invitedMembers');
                relation.remove(user[0]);

                return result[0].save();


            });

        },
        invitedMembers: async ({ commit }, payload) => {
            const Ticket = Parse.Object.extend("Tickets");
            const query = new Parse.Query(Ticket);

            query.equalTo('objectId', payload.objectId);

            return query.find().then((result) => {
                const relation = result[0].relation('invitedMembers');

                const mainQuery = relation.query();

                return mainQuery.find().then((members) => members);
            });

        },
        memberSearchResult: async ({ commit }, payload) => {

            // console.log('test: ', payload.value);

            const User = Parse.Object.extend("_User");
            const query = new Parse.Query(User);

            query.fullText('fullname', payload.value.toUpperCase());


            const result = await query.find();
            commit('clearMemberSearchResult');

            if (result)
                for (let i = 0; i < result.length; i++) {
                    commit('memberSearchResult', { result: result[i].get('username') + ' : ' + result[i].get('fullname') });
                }
        },
        addTicketMember: async ({ }, payload) => {

            const Member = Parse.Object.extend("_User");
            const memberQuery = new Parse.Query(Member);
            memberQuery.equalTo('username', payload.userId);

            const member = await memberQuery.find();


            const Ticket = Parse.Object.extend("Tickets");
            const query = new Parse.Query(Ticket);

            query.equalTo('objectId', payload.objectId);

            const result = await query.find();
            if (result) {

                let invited = result[0].relation('invitedMembers');

                invited.add(member);
                return result[0].save();
            }
            return true;

        },
        realtimeTicketConversation: async ({ dispatch, commit }, payload) => {

            const ticketPointer = { __type: 'Pointer', className: 'Tickets', objectId: payload.ticketId };

            const TicketConversation = Parse.Object.extend('TicketConversation');
            let query = new Parse.Query(TicketConversation);

            query.equalTo('ticket', ticketPointer)
            query.include('sender')
            query.include('ticket')
            query.include('likers')


            let subscription = await query.subscribe();

            subscription.on('open', () => {
                console.log('[TICKET CONVO RT]');
            });

            subscription.on('create', (object) => {

                commit('appendTicketConversation', { conversation: object })
            });

            subscription.on('update', (object) => {

                dispatch('getAllTicketConversation', { ticketId: payload.ticketId })
            });

            subscription.on('delete', (object) => {

                dispatch('getAllTicketConversation', { ticketId: payload.ticketId })
            });
        },
        getAllTicketConversation: async ({ commit }, payload) => {
            // Ticket Pointer
            const ticketPointer = { __type: 'Pointer', className: 'Tickets', objectId: payload.ticketId };

            const TicketConversation = Parse.Object.extend('TicketConversation')
            const query = new Parse.Query(TicketConversation)

            query.equalTo('ticket', ticketPointer)
            query.include('sender')
            query.include('ticket')
            query.include('likers')
            query.descending('createdAt')

            const response = await query.find()

            commit('ticketConversation', { conversation: response })
        },
        likeComment: async ({ }, payload) => {

            const TicketConversation = Parse.Object.extend('TicketConversation')
            const query = new Parse.Query(TicketConversation)

            query.equalTo('objectId', payload.commentId)
            const response = await query.find()

            if (response) {
                for (let i = 0; i < response.length; i++) {
                    var relation = response[i].relation('likers')
                    relation.add(Parse.User.current())
                    await response[i].save()
                }
            }
        },
        likedComments: async ({ }, payload) => {
            const TicketConversation = Parse.Object.extend('TicketConversation')
            const query = new Parse.Query(TicketConversation)

            query.equalTo('objectId', payload.commentId)
            const result = await query.find();
            for (var i = 0; i < result.length; i++) {
                var relation = result[i].relation('likers');

                var relationQuery = relation.query();
                var finalResult = await relationQuery.find();

                return finalResult;
            }

        },
        unlikeComment: async ({ }, payload) => {
            const TicketConversation = Parse.Object.extend('TicketConversation')
            const query = new Parse.Query(TicketConversation)

            query.equalTo('objectId', payload.commentId)
            const result = await query.find();
            for (var i = 0; i < result.length; i++) {
                var relation = result[i].relation('likers');
                relation.remove(Parse.User.current());


                return await result[i].save();
            }

        },
        deleteComment: async ({ dispatch }, payload) => {
            const TicketConversation = Parse.Object.extend('TicketConversation')
            const query = new Parse.Query(TicketConversation)

            query.equalTo('objectId', payload.commentId)
            const result = await query.find();
            for (var i = 0; i < result.length; i++) {



                await result[i].destroy();
                return await dispatch('getAllTicketConversation', { ticketId: result[i].get('ticket').id });
            }


        },
        postComment: async ({ dispatch, rootState }, payload) => {

            try {
                // Ticket Pointer
                const ticketPointer = { __type: 'Pointer', className: 'Tickets', objectId: payload.ticketId };

                const TicketConversation = Parse.Object.extend('TicketConversation')
                const comment = new TicketConversation();

                let commentImage = "";
                if (payload.imageB64 !== '') {
                    const file = new Parse.File("clipboard-image.png", { base64: payload.imageB64 });

                    const uploadedFile = await file.save();
                    commentImage = uploadedFile._url;
                }


                comment.set('message', payload.message.trim())
                comment.set('ticket', ticketPointer)
                comment.set('image', commentImage)
                comment.set('sender', Parse.User.current())
                if (payload.attachments !== null && payload.attachments.length > 0) {
                    comment.set('attachments', payload.attachments)
                }
                const response = await comment.save();
                //await dispatch('getAllTicketConversation', { ticketId: payload.ticketId })

                // also insert notifications to those who mentioned in the comment
                if (payload.mentions.length > 0) {


                    for (let i = 0; i < payload.mentions.length; i++) {
                        const Notification = Parse.Object.extend("Notifications")
                        const notification = new Notification();
                        // User Pointer
                        const userPointer = { __type: 'Pointer', className: '_User', objectId: payload.mentions[i].userObjectId };

                        let acl = new Parse.ACL();

                        acl.setPublicReadAccess(true);
                        acl.setReadAccess(payload.mentions[i].userObjectId, true);
                        acl.setWriteAccess(payload.mentions[i].userObjectId, true);
                        acl.setRoleReadAccess('developer', true);

                        notification.set('receiver', userPointer)
                        notification.set('type', 'comment')
                        notification.set('message', 'You have been mentioned in a comment section.')
                        notification.set('data', { route: `/tickets/${payload.ticketId}` })
                        notification.setACL(acl);

                        await notification.save()
                        await dispatch('notifications/fcm', { userId: payload.mentions[i]._id, title: rootState.auth.currentUser.get('firstname') + ' mentioned you in a comment', body: payload.message, url: `${rootState.global.endpoint}/taskboard/#/tickets/${payload.ticketId}` }, { root: true });


                    }
                }
                return response;
            } catch (error) {
                console.error('comment failed: ', error);
            }

        },
        getAllTickets: async ({ commit }) => {
            const Tickets = Parse.Object.extend("Tickets")
            const query = new Parse.Query(Tickets)

            await Parse.User.current().fetch();

            const boardId = Parse.User.current().get('activeBoard');

            query.include('story')
            query.include('sprint')
            query.include('developer')
            query.descending('updatedAt')

            console.log(`[Getting tickets for ${boardId}]`);

            query.equalTo('boardId', boardId);

            const response = await query.find()

            if (response) {
                commit('allTickets', { tickets: response })
            }
        },
        getSingleTicket: async ({ commit }, payload) => {

            let ticketDetails = {};
            const Tickets = Parse.Object.extend("Tickets")
            const query = new Parse.Query(Tickets)

            query.include('story')
            query.include('sprint')
            query.include('developer')
            query.descending('updatedAt')
            query.equalTo('objectId', payload.ticketId)

            const response = await query.find()

            if (response) {
                ticketDetails.id = response[0].id;
                ticketDetails.recommendation = response[0].get('recommendation');
                ticketDetails.remarks = response[0].get('remarks');
                ticketDetails.dataStructure = response[0].get('dataStructure');
                ticketDetails.fileStructure = response[0].get('fileStructure');
                ticketDetails.score = response[0].get('score');
                ticketDetails.status = response[0].get('status');
                ticketDetails.sprintNumber = response[0].get('sprint') ? response[0].get('sprint').get('number') : null;
                ticketDetails.sprintId = response[0].get('sprint') ? response[0].get('sprint').id : null;
                ticketDetails.devId = response[0].get('developer') ? response[0].get('developer').get('username') : null;
                ticketDetails.devObjectId = response[0].get('developer') ? response[0].get('developer').id : null;
                ticketDetails.devFname = response[0].get('developer') ? response[0].get('developer').get('firstname') : null;
                ticketDetails.story = response[0].get('story').get('story');
                ticketDetails.storyId = response[0].get('story').id;
                ticketDetails.storyAttachments = response[0].get('story').get('attachments');
                ticketDetails.storyApprover = response[0].get('story').get('approver');
                ticketDetails.systemtag = response[0].get('story').get('systemtag');
                ticketDetails.subsystem = response[0].get('story').get('subsystem');
                ticketDetails.storyCriteria = response[0].get('story').get('criteria');
                ticketDetails.boardId = response[0].get('story').get('boardId');
                // Get creator of the story

                const Creator = Parse.Object.extend("User");
                const creatorQuery = new Parse.Query(Creator);

                creatorQuery.equalTo('objectId', response[0].get('story').get('creator').id);

                const creator = await creatorQuery.find();

                if (creator) {
                    ticketDetails.storyCreatorId = creator[0].get('username');
                    ticketDetails.storyCreatorObjectId = creator[0].id;
                    ticketDetails.storyCreatorName = creator[0].get('firstname') + " " + creator[0].get('lastname');
                }
                commit('singleTicket', { ticket: ticketDetails });
            }
        },
    },
};

export default alltickets;