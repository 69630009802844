import Parse from 'parse';
import ParseUser from 'parse/lib/browser/ParseUser';

const board = {
    namespaced: true,
    state: {
        active: null,
        boards: [],
        members: [],
        invites: [],
    },
    getters: {
        all: (state) => state.boards,
        invites: (state) => state.invites,
    },
    mutations: {
        setBoards(state, payload) {
            state.boards = payload.boards;
        },
        setMembers(state, payload) {
            state.members = payload.members;
        },
        setActive(state, payload) {
            state.active = payload.boardId;
        },
        setInvites(state, payload) {
            state.invites = payload.invites;
        }
    },
    actions: {
        removeInvite: async ({ }, payload) => {
            const BoardInvitations = Parse.Object.extend("BoardInvitations");
            const query = new Parse.Query(BoardInvitations);

            query.equalTo('objectId', payload.inviteId);
            console.log('id: ', payload.inviteId);
            return query.find().then((res) => {

                if (res.length > 0) {
                    console.log('remove invite: ', res[0]);
                    res[0].destroy();
                }
                return true;
            })
        },
        getInvites: async ({ commit }, payload) => {
            const BoardInvitations = Parse.Object.extend("BoardInvitations");
            const query = new Parse.Query(BoardInvitations);

            query.equalTo('userId', payload.userId);
            query.include('invitedBy');
            query.include('board');

            return query.find().then((response) => {
                commit('setInvites', { invites: response })
                return true;
            })
        },
        invite: async ({ dispatch }, payload) => {
            const url = "https://taskboard.eternalgardens.ph/#/";
            // const url = "http://172.28.1.16:8181/eportal-api-notif/notifications/send";
            // const requestOptions = {
            //     method: "POST",
            //     headers: { "Content-Type": "application/json" },
            //     body: JSON.stringify({ empId: payload.empId, message: "You have been invited to join in Taskboard!", url: `http://172.28.1.2/taskboard/#/login/${window.btoa(payload.empId)}` })
            // };

            // Get Board Object
            const Boards = Parse.Object.extend("Boards");
            const query = new Parse.Query(Boards);

            query.equalTo('objectId', payload.boardId);
            const result = await query.find();

            return await fetch(url).then((response) => {
                console.log(response);
                if (response.status == 200) {

                    if (result.length > 0) {

                        // save invitation to database



                        const BoardInvitation = Parse.Object.extend("BoardInvitations");
                        const invitation = new BoardInvitation();

                        invitation.set('userId', payload.empId);
                        invitation.set('invitedBy', Parse.User.current());
                        invitation.set('board', result[0]);

                        invitation.save().then(function () {
                            dispatch('notifications/fcm', {
                                userId: payload.empId,
                                title: `Invitation to join ${result[0].get('name')}`,
                                body: `You have been invited to join in a board in Taskboard.`,
                                url: 'https://taskboard.eternalgardens.ph/#/login'
                                // url: `http://172.28.1.2/taskboard/#/login/${window.btoa(payload.empId)}`
                            }, { root: true });

                            return 'success';
                        })


                    }
                }
                console.log(response.status);
            }).catch((err) => {
                console.log(err.message);
                return err.message;
            })
        },
        members: async ({ commit }, payload) => {
            const Boards = Parse.Object.extend("Boards");
            const query = new Parse.Query(Boards);

            query.equalTo('objectId', payload.boardId);

            query.find().then((res) => {



                let relation = res[0].relation('users')

                const reQuery = relation.query();
                reQuery.find().then((response) => {
                    commit('setMembers', { members: response });
                })
            })
        },
        setActive: async ({ commit }, payload) => {
            commit('setActive', { boardId: payload.objectId })
            const User = Parse.Object.extend("_User");
            const query = new Parse.Query(User);

            query.equalTo('objectId', Parse.User.current().id);
            return query.find().then((response) => {
                response[0].set('activeBoard', payload.objectId);

                response[0].save();

                // Insert current user to the board
                const Board = Parse.Object.extend("Boards");
                const reQuery = new Parse.Query(Board);

                reQuery.equalTo('objectId', payload.objectId);
                return reQuery.find().then((res) => {
                    const relation = res[0].relation('users');
                    relation.add(Parse.User.current());

                    return res[0].save();

                })


            }).catch((error) => console.log('error: ', error))

        },
        addBoard: async ({ dispatch }, payload) => {


            const Boards = Parse.Object.extend("Boards");
            const board = new Boards();

            board.set('name', payload.name)


            const response = await board.save();

            if (response) {
                const board = Parse.Object.extend("Boards");
                const query = new Parse.Query(board);
                query.equalTo('objectId', response.id)



                query.find().then((res) => {

                    const rel = res[0].relation("users");
                    rel.add(Parse.User.current());
                    res[0].save().then((_) => {
                        dispatch('getAll', { objectId: payload.objectId, department: ParseUser.current().get('department') })
                    })

                    return true;
                })
            }


        },
        getAll: async ({ commit }, payload) => {
            const userPointer = { __type: 'Pointer', className: '_User', objectId: payload.objectId };


            const department = new Parse.Query("Boards");
            department.equalTo('name', payload.department);

            const user = new Parse.Query("Boards");
            user.equalTo('users', userPointer);


            const mainQuery = Parse.Query.or(department, user);

            await mainQuery.find().then((response) => {
                commit('setBoards', { boards: response });

            })
        }
    },
}

export default board;