<template>
  <div class="container-fluid">
    <div class="row py-3" v-if="story !== null">
      <div class="row mt-3">
        <div class="col-lg-12">
          <h2 class="pb-5 adapt__theme_lighter">
            <button class="btn" @click="$router.go(-1)">
              <i class="bx bx-arrow-back"></i>
            </button>
            Story Detail
          </h2>
        </div>
      </div>
      <!-- START PAGE CONTENT -->
      <AddTicketModal />
      <!-- Story Detail Card -->
      <div class="col-lg-12">
        <div class="row">
          <!-- Story detail -->
          <div class="col-lg-8">
            <div
              v-if="$store.getters['auth/roles'].length > 0"
              class="col-lg-12 d-flex align-items-center justify-content-start"
            >
              <img
                width="50"
                class="img-fluid rounded-pill border shadow-sm"
                :src="ownerAvatar"
              
                alt=""
              />
              <h5 class="ms-2 card-title adapt__theme_lighter">
                {{
                  story.get("creator").get("firstname") +
                  " " +
                  story.get("creator").get("lastname")
                }}
              </h5>
            </div>

            <div class="col-lg-10 col-md-5 ps-5 ms-4 mb-3">
              <span class="mb-1 adapt__theme_light d-block =">
                <small>Story ID: {{ story.id }}</small>
              </span>
              <small class="adapt__theme_light">
                Created on:
                {{ format_date(story.get("createdAt"), "MMM DD, Y hh:mm A") }}
              </small>
            </div>

            <div class="col-lg-8 ps-5 ms-4">
              <blockquote class="blockquote">
                <span class="quote adapt__theme_light">"</span>
                {{ story.get("story") }}
              </blockquote>
              <div class="row">
                <div class="col-lg-6 mt-2">
                  <small class="adapt__theme_light text-capitalize"
                    ><span class="fw-normal"
                      ><i class="bx bx-sm align-middle bx-user-check"></i
                    ></span>
                    {{
                      story.get("approver").Fname.toLowerCase() +
                      " " +
                      story.get("approver").Lname.toLowerCase()
                    }}</small
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- End Story detail -->

          <!-- Attachment -->
          <div class="col-lg-3 col">
            <div class="row">
              <div class="col-lg-12">
                <div
                  class="
                    card
                    adapt__theme_bg_dark
                    border-0
                    mb-4
                    shadow-sm
                    rounded
                  "
                >
                  <div class="card-body">
                    <h5><i class="bx bx-link-alt"></i> Attachments</h5>
                    <div class="col border"></div>
                    <div v-if="story.get('attachments').length > 0">
                      <ul
                        v-for="(attachment, index) in story.get('attachments')"
                        :key="index"
                      >
                        <li :set="(ext = attachment.split('.').at(-1))">
                          <a :href="attachment" target="_blank">
                            <img
                              :title="attachment.split('_').at(-1)"
                              :src="
                                ['doc', 'docx'].indexOf(ext) > -1
                                  ? 'theme/img/docx-icon.png'
                                  : ext == 'pdf'
                                  ? 'theme/img/pdf-icon.png'
                                  : attachment
                              "
                              :width="
                                ['jpg', 'jpeg', 'png', 'gif'].indexOf(
                                  ext.toLowerCase()
                                ) > -1
                                  ? 70
                                  : 60
                              "
                              alt=""
                              class="align-middle"
                              :class="
                                ['jpg', 'jpeg', 'gif', 'png'].indexOf(
                                  ext.toLowerCase()
                                ) > -1
                                  ? 'border border-2 border-white shadow-sm'
                                  : 'me-2'
                              "
                            />
                            <small
                              :title="attachment.split('_').at(-1)"
                              class="
                                text-muted
                                align-middle
                                ps-3
                                w-50
                                d-inline-block
                                text-truncate
                              "
                            >
                              {{
                                attachment.split("_").at(-1).split(".").at(0)
                              }}
                            </small>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div v-else>
                      <p class="adapt__theme_light pt-3">
                        No attachments found.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End attachment -->
        </div>
      </div>
      <!--  END Story Detail Card -->

      <div class="col-lg-12 mt-5">
        <div
          class="
            col-lg-5 col-mg-4 col-sm-6
            d-flex
            align-items-center
            justify-content-between
            mb-3
          "
        >
          <h5 class="ms-5 adapt__theme_light">Acceptance Criteria</h5>
          <button
            v-if="$store.state.auth.roles.indexOf('master') > -1"
            class="btn adapt__theme_bg_dark"
            @click="showAddCriteria = !showAddCriteria"
          >
            <i class="bx bx-plus bx-sm align-middle adapt__theme_light"></i>
            <span class="adapt__theme_light">New Criteria</span>
          </button>
        </div>
        <p
          class="ps-5 adapt__theme_light"
          v-for="(criteria, index) in story.get('criteria')"
          :key="index"
        >
          <i class="bx bxs-check-circle align-middle adapt__theme_light"></i>
          {{ criteria }}
          <button
            v-if="$store.state.auth.roles.indexOf('master') > -1"
            class="btn btn-sm adapt__theme_light"
            @click="removeCriteria(criteria)"
          >
            <i class="bx bx-x"></i>
          </button>
        </p>

        <div class="col-lg-4" v-if="showAddCriteria">
          <div
            class="form-group"
            v-if="$store.state.auth.roles.indexOf('master') > -1"
          >
            <input
              type="text"
              id="acceptance-criteria"
              v-model="criteria"
              class="form-control"
              placeholder="Add New Criteria"
              v-on:keyup.enter="saveCriteria"
            />
          </div>
        </div>
      </div>

      <hr class="text-muted mt-5" />

      <div class="col-lg-12 py-5 d-flex justify-content-between">
        <h3 class="adapt__theme_lighter m-0">
          <i class="bx bxs-coupon align-middle"></i> Tickets
        </h3>
        <button
          class="btn btn-sm adapt__theme_bg_dark text-light"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          data-bs-whatever="@storyId"
        >
          <i class="bx bx-plus"></i> Create Ticket
        </button>
      </div>

      <!-- BACKLOG -->
      <div class="col pt-3 border-end" id="status-0">
        <h5 class="pb-4 adapt__theme_light">Backlog</h5>

        <div
          v-for="ticket in $store.state.story.storyTickets.filter(
            (story) => story.get('status') == 0
          )"
          :key="ticket.id"
        >
          <MyTicket
            :status="0"
            :sprint="ticket.get('sprint')"
            :storyId="story.id"
            :roles="$store.getters['auth/roles']"
            :isMasterDev="$store.getters['auth/roles'].length > 0"
            :id="ticket.id"
            className="ticket-bl"
            :title="ticket.get('recommendation')"
            :dev="
              ticket.get('developer') !== undefined
                ? ticket.get('developer').get('firstname')
                : ''
            "
            :score="ticket.get('score')"
            :devAvatar="
              ticket.get('developer') !== undefined
                ? ticket.get('developer').get('username')
                : ''
            "
             
              :avatar="ticket.get('developer') !== undefined ? ticket.get('developer').get('avatar'):''"
          />
        </div>
      </div>
      <!-- WORK IN PROGRESS -->
      <div
        class="col pt-3 border-end"
        :ondrop="drop"
        :ondragover="allowDrop"
        id="status-1"
      >
        <h5 class="pb-4 adapt__theme_light">In Progress</h5>
        <div
          v-for="ticket in $store.state.story.storyTickets.filter(
            (story) => story.get('status') == 1
          )"
          :key="ticket.id"
        >
          <MyTicket
            :status="1"
            :sprint="ticket.get('sprint')"
            :storyId="story.id"
            :roles="$store.getters['auth/roles']"
            :isMasterDev="$store.getters['auth/roles'].length > 0"
            :id="ticket.id"
            className="ticket-wip"
            :title="ticket.get('recommendation')"
            :dev="ticket.get('developer').get('firstname')"
            :score="ticket.get('score')"
            :devAvatar="ticket.get('developer').get('username')"
             :avatar="ticket.get('developer').get('avatar')"
          />
        </div>
      </div>
      <!-- QUALITY ASSURANCE -->
      <div
        class="col pt-3 border-end"
        :ondrop="drop"
        :ondragover="allowDrop"
        id="status-2"
      >
        <h5 class="pb-4 adapt__theme_light">Quality Assurance</h5>
        <div
          v-for="ticket in $store.state.story.storyTickets.filter(
            (story) => story.get('status') == 2
          )"
          :key="ticket.id"
        >
          <MyTicket
            :status="2"
            :sprint="ticket.get('sprint')"
            :storyId="story.id"
            :roles="$store.getters['auth/roles']"
            :isMasterDev="$store.getters['auth/roles'].length > 0"
            :id="ticket.id"
            className="ticket-qa"
            :title="ticket.get('recommendation')"
            :dev="ticket.get('developer').get('firstname')"
            :score="ticket.get('score')"
            :devAvatar="ticket.get('developer').get('username')"
             :avatar="ticket.get('developer').get('avatar')"
          />
        </div>
      </div>
      <!-- USER ACCEPTANCE TESTING -->
      <div
        class="col pt-3 border-end"
        :ondrop="drop"
        :ondragover="allowDrop"
        id="status-3"
      >
        <h5 class="pb-4 adapt__theme_light" title="User Acceptance Testing">
          UAT
        </h5>
        <div
          v-for="ticket in $store.state.story.storyTickets.filter(
            (story) => story.get('status') == 3
          )"
          :key="ticket.id"
        >
          <MyTicket
            :status="3"
            :sprint="ticket.get('sprint')"
            :storyId="story.id"
            :roles="$store.getters['auth/roles']"
            :isMasterDev="$store.getters['auth/roles'].length > 0"
            :id="ticket.id"
            :creatorId="story.get('creator').get('username')"
            className="ticket-uat"
            :title="ticket.get('recommendation')"
            :dev="ticket.get('developer').get('firstname')"
            :score="ticket.get('score')"
            :devAvatar="ticket.get('developer').get('username')"
             :avatar="ticket.get('developer').get('avatar')"
          />
        </div>
      </div>
      <!-- RELEASE -->
      <div
        class="col pt-3"
        :ondrop="drop"
        :ondragover="allowDrop"
        id="status-4"
      >
        <h5 class="pb-4 adapt__theme_light">Release</h5>
        <div
          v-for="ticket in $store.state.story.storyTickets.filter(
            (story) => story.get('status') == 4
          )"
          :key="ticket.id"
        >
          <MyTicket
            :status="4"
            :sprint="ticket.get('sprint')"
            :storyId="story.id"
            :roles="$store.getters['auth/roles']"
            :isMasterDev="$store.getters['auth/roles'].length > 0"
            :id="ticket.id"
            className="ticket-release"
            :title="ticket.get('recommendation')"
            :dev="ticket.get('developer').get('firstname')"
            :score="ticket.get('score')"
            :devAvatar="ticket.get('developer').get('username')"
             :avatar="ticket.get('developer').get('avatar')"
          />
        </div>
      </div>

      <!-- END CONTENT-->
    </div>
  </div>
</template>

<script>
import MyTicket from "./MyTicket.vue";
import AddTicketModal from "../all-stories/AddTicketModal.vue";
import { BoardGuard } from "../../helpers/BoardGuard";

export default {
  name: "StoryDetail",
  components: {
    MyTicket,
    AddTicketModal,
  },
  data() {
    return {
      ownerAvatar: "",
      story: null,
      showAddCriteria: false,
      criteria: "",
    };
  },
  async created() {
    await this.$store.dispatch("story/allStories");

    await this.$store.dispatch("story/getDevs");
    await this.$store.dispatch("story/storyTickets", {
      id: this.$route.params.id,
    });
    await this.storyDetail();
    this.$store.dispatch("story/selectedStoryId", {
      storyId: this.$route.params.id,
    });
    await this.$store.dispatch("alltickets/getAllTickets");
  },
  methods: {
    async removeCriteria(criteria) {
      // only remove if user is master
      if (this.$store.state.auth.roles.indexOf("master") < 0) {
        return;
      }
      if (criteria) {
        const deleted = await this.$store.dispatch("story/removeCriteria", {
          storyId: this.$route.params.id,
          criteria: criteria,
        });
        await this.storyDetail();
      }
    },
    async saveCriteria() {
      // only save if user is master
      if (this.$store.state.auth.roles.indexOf("master") < 0) {
        return;
      }
      if (this.criteria !== "") {
        const save = await this.$store.dispatch("story/saveCriteria", {
          storyId: this.$route.params.id,
          newCriteria: this.criteria,
        });
        if (save) {
          this.criteria = "";
          this.showAddCriteria = false;
        }
      }
    },
    allowDrop(ev) {
      ev.preventDefault();
    },

    async drop(ev) {
      ev.preventDefault();

      let targetTicketId = this.$store.state.story.ticketToDrop;

      let targetTicket = this.$store.state.story.storyTickets.filter(
        (el) => el.id == targetTicketId
      );

      if (targetTicket) {
        const targetTicketStatus = targetTicket[0].get("status");
        const targetColumnId = ev.target.id;
        const targetColumnStatus = targetColumnId.split("-")[1];

        if (
          parseInt(targetTicketStatus) < targetColumnStatus ||
          this.$store.getters["auth/roles"].indexOf("master") > -1
        ) {
          await this.$store.dispatch("story/dropTicket", {
            status: ev.target.id,
            storyId: this.$route.params.id,
          });

          // send notification to ticket's story owner
          this.$store.dispatch("story/notifyOwner", {
            storyId: targetTicket[0].get("story").id,
            status: targetColumnStatus - 1,
            title: targetTicket[0].get("recommendation"),
          });
        }
      }
    },
    async storyDetail() {
      const x = await this.$store.state.story.allStories;

      const thisStory = x.filter((data) => data.id == this.$route.params.id);
      this.story = thisStory[0];

      BoardGuard(this.story.get("boardId"));

  const ownervatar = await this.$store.dispatch('global/getAvatar',{empId:this.story.get('creator').get('username')});

      this.ownerAvatar =ownervatar;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");
.text-capitalize {
  text-transform: capitalize !important;
}
.ticket {
  width: 250px;
}
.ticket-wip {
  border-left: 4px solid var(--bs-red);
}
.ticket-qa {
  border-left: 4px solid var(--bs-orange);
}
.ticket-uat {
  border-left: 4px solid var(--bs-blue);
}
.ticket-release {
  border-left: 4px solid var(--bs-green);
}
.ticket-bl {
  border-left: 4px solid var(--bs-gray-600);
}
.quote {
  font-size: 35px !important;
  font-family: "Secular One" !important;
  color: #ccc;
  margin-left: -23px !important;
  line-height: 0.6;
  display: inline-block;
}

#status-0,
#status-1,
#status-2,
#status-3,
#status-4 {
  padding-bottom: 20px !important;
  /* background-color: #ebecf0; */
  min-width: 272px !important ;
  display: inline-block !important;
}
ul {
  list-style: none;
  margin: 15px;
  padding: 0;
}
div.col.pt-3 {
  background-color: hsla(0, 0%, 0%, 0.1);
}
</style>