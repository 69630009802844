<template>
  <div class="row py-3">
    <div class="row mt-3">
      <div class="col-lg-6"><h2 class="pb-5 page_title">Sprints</h2></div>
      <div class="col-lg-6 text-end">
        <button
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          class="btn adapt__theme_bg_dark p-2 text-light w-25 rounded-pill"
        >
          <i class="bx bx-sm align-middle bx-layer-plus"></i> New Sprint
        </button>
      </div>
    </div>

    <CreateSprintModal />

    <!-- Main Content -->

    <div class="row">
      <div class="col d-flex align-items-center mb-2">
        <div class="legend bl"></div>
        <p class="p-0 m-0 ps-2 adapt__theme_lighter">Backlog</p>
      </div>

      <div class="col d-flex align-items-center mb-2">
        <div class="legend wip"></div>
        <p class="p-0 m-0 ps-2 adapt__theme_lighter">In Progress</p>
      </div>

      <div class="col d-flex align-items-center mb-2">
        <div class="legend qa"></div>
        <p class="p-0 m-0 ps-2 adapt__theme_lighter">Quality Assurance</p>
      </div>

      <div class="col d-flex align-items-center mb-2">
        <div class="legend uat"></div>
        <p class="p-0 m-0 ps-2 adapt__theme_lighter">User Acceptance Testing</p>
      </div>

      <div class="col d-flex align-items-center mb-2">
        <div class="legend release"></div>
        <p class="p-0 m-0 ps-2 adapt__theme_lighter">Release</p>
      </div>
    </div>

    <div
      class="col-lg-4 mt-3"
      v-for="(sprint, index) in $store.state.sprints.sprints"
      :key="index"
      @click="
        $router.push({
          name: 'SprintTickets',
          params: { number: sprint.get('number') },
        })
      "
    >
      <div class="card p-2 adapt__theme_bg_darker">
        <div
          class="
            d-flex
            align-items-center
            justify-content-between
            border-bottom
            mb-2
          "
        >
          <h5 class="pb-1 text-primary">Sprint #{{ sprint.get("number") }}</h5>
          <h6 class="pb-1 text-secondary">
            {{ formatDate(sprint.get("from"), sprint.get("to")) }}
          </h6>
        </div>
        <small class="text-muted">Goal:</small>
        <p class="small text-muted text-truncate">{{ sprint.get("goal") }}</p>

        <SprintSection :sprintId="sprint.id" />
      </div>
    </div>

    <!-- END Main Content -->
  </div>
</template>

<script>
import SprintSection from "./SprintSection.vue";
import CreateSprintModal from "./CreateSprintModal.vue";
export default {
  name: "Sprints",
  data() {
    return {
      sprintDate: "",
    };
  },
  methods: {
    formatDate(from, to) {
      const _from = new Date(from);
      const _to = new Date(to);
      const months = [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      let _month = "";
      let days = "";

      let full = "";
      if (_from.getMonth() == _to.getMonth()) {
        _month = months[_from.getMonth()];
        days = _from.getDate() + " - " + _to.getDate();
        full = _month + " " + days + ", " + _from.getFullYear();
      } else {
        if (_from.getFullYear() == _to.getFullYear()) {
          full =
            months[_from.getMonth()] +
            " " +
            _from.getDate() +
            " - " +
            months[_to.getMonth()] +
            " " +
            _to.getDate() +
            ", " +
            _from.getFullYear();
        } else {
          full =
            months[_from.getMonth()] +
            " " +
            _from.getDate() +
            ", " +
            _from.getFullYear() +
            " - " +
            months[_to.getMonth()] +
            " " +
            _to.getDate() +
            ", " +
            _to.getFullYear();
        }
      }

      return full;
    },
  },
  components: { SprintSection, CreateSprintModal },
  async created() {
    await this.$store.dispatch("sprints/getSprints", { allSprint: true });
  },
};
</script>

<style scoped>
div.legend {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
div.bl {
  background-color: var(--bs-gray-500);
}
div.wip {
  background-color: var(--bs-red);
}
div.qa {
  background-color: var(--bs-warning);
}
div.uat {
  background-color: var(--bs-blue);
}
div.release {
  background-color: var(--bs-success);
}
div.card:hover {
  box-shadow: 0px 1px 15px #ddd;
  cursor: pointer;
}
</style>